
const Condition = () => {
  return (
    <div
      style={{
        paddingBottom: "20px",
      }}
    >


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "450px",
          overflow: "auto",
        }}
      >
        <div className="justifywidth">
          <div style={{ marginTop: "30px" }}>
            <h3
              className="PrivacyPolicyTopHeading"
            >
              Interpretation and Definitions
            </h3>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Interpretation
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.{" "}
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Definitions
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              For the purposes of these Terms and Conditions:
            </p>

            <ul className="PrivacyPolicyParam">
              <li>
                <span style={{ fontSize: "20px", fontWeight: 500 }}>
                  <i>Affiliate</i>
                </span>{" "}
                means an entity that controls, is controlled by or is under
                common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing
                authority.{" "}
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Country</i>
                </span>{" "}
                refers to: Netherlands
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Company</i>
                </span>{" "}
                (referred to as either "the Company", "We", "Us" or "Our" in
                this Agreement) refers to Soar Music, Lia Doranastraat 64,
                1031HK Amsterdam. Device means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Service</i>
                </span>{" "}
                refers to the Website.
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Terms and Conditions</i>
                </span>{" "}
                (also referred as "Terms") mean these Terms and Conditions that
                form the entire agreement between You and the Company regarding
                the use of the Service. This Terms and Conditions agreement has
                been created with the help of the TermsFeed Terms and Conditions
                Generator.
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Third-party Social Media</i>
                </span>{" "}
                Service means any services or content (including data,
                information, products or services) provided by a third-party
                that may be displayed, included or made available by the
                Service.
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>Website</i>
                </span>{" "}
                refers to Songplace, accessible from https://songplace.io
              </li>
              <li>
                <span style={{ fontSize: "20px" }}>
                  <i>You</i>
                </span>{" "}
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
            </ul>
          </div>

          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Links to Other Websites
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by the Company.
            </p>
            <p className="PrivacyPolicyParam">
              The Company has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services.
            </p>
            <p className="PrivacyPolicyParam">
              You further acknowledge and agree that the Company shall not be
              responsible or liable, directly or indirectly, for any damage or
              loss caused or alleged to be caused by or in connection with the
              use of or reliance on any such content, goods or services
              available on or through any such web sites or services.
            </p>

            <p className="PrivacyPolicyParam">
              We strongly advise You to read the terms and conditions and
              privacy policies of any third-party web sites or services that You
              visit.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Termination
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              We may terminate or suspend Your access immediately, without prior
              notice or liability, for any reason whatsoever, including without
              limitation if You breach these Terms and Conditions. Upon
              termination, Your right to use the Service will cease immediately.
            </p>{" "}
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Limitation of Liability
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              Notwithstanding any damages that You might incur, the entire
              liability of the Company and any of its suppliers under any
              provision of this Terms and Your exclusive remedy for all of the
              foregoing shall be limited to the amount actually paid by You
              through the Service.
            </p>
            <p className="PrivacyPolicyParam">
              To the maximum extent permitted by applicable law, in no event
              shall the Company or its suppliers be liable for any special,
              incidental, indirect, or consequential damages whatsoever
              (including, but not limited to, damages for loss of profits, loss
              of data or other information, for business interruption, for
              personal injury, loss of privacy arising out of or in any way
              related to the use of or inability to use the Service, third-party
              software and/or third-party hardware used with the Service, or
              otherwise in connection with any provision of this Terms), even if
              the Company or any supplier has been advised of the possibility of
              such damages and even if the remedy fails of its essential
              purpose. Some states do not allow the exclusion of implied
              warranties or limitation of liability for incidental or
              consequential damages, which means that some of the above
              limitations may not apply. In these states, each party's liability
              will be limited to the greatest extent permitted by law.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              "AS IS" and "AS AVAILABLE" Disclaimer
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              The Service is provided to You "AS IS" and "AS AVAILABLE" and with
              all faults and defects without warranty of any kind. To the
              maximum extent permitted under applicable law, the Company, on its
              own behalf and on behalf of its Affiliates and its and their
              respective licensors and service providers, expressly disclaims
              all warranties, whether express, implied, statutory or otherwise,
              with respect to the Service, including all implied warranties of
              merchantability, fitness for a particular purpose, title and
              non-infringement, and warranties that may arise out of course of
              dealing, course of performance, usage or trade practice. Without
              limitation to the foregoing, the Company provides no warranty or
              undertaking, and makes no representation of any kind that the
              Service will meet Your requirements, achieve any intended results,
              be compatible or work with any other software, applications,
              systems or services, operate without interruption, meet any
              performance or reliability standards or be error free or that any
              errors or defects can or will be corrected. Without limiting the
              foregoing, neither the Company nor any of the company's provider
              makes any representation or warranty of any kind, express or
              implied: (i) as to the operation or availability of the Service,
              or the information, content, and materials or products included
              thereon; (ii) that the Service will be uninterrupted or
              error-free; (iii) as to the accuracy, reliability, or currency of
              any information or content provided through the Service; or (iv)
              that the Service, its servers, the content, or e-mails sent from
              or on behalf of the Company are free of viruses, scripts, trojan
              horses, worms, malware, timebombs or other harmful components.
            </p>
            <p className="PrivacyPolicyParam">
              Some jurisdictions do not allow the exclusion of certain types of
              warranties or limitations on applicable statutory rights of a
              consumer, so some or all of the above exclusions and limitations
              may not apply to You. But in such a case the exclusions and
              limitations set forth in this section shall be applied to the
              greatest extent enforceable under applicable law.
            </p>{" "}
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Governing Law
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              The laws of the Country, excluding its conflicts of law rules,
              shall govern this Terms and Your use of the Service. Your use of
              the Application may also be subject to other local, state,
              national, or international laws.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Disputes Resolution
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              If You have any concern or dispute about the Service, You agree to
              first try to resolve the dispute informally by contacting the
              Company.
            </p>{" "}
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              For European Union (EU) Users
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              If You are a European Union consumer, you will benefit from any
              mandatory provisions of the law of the country in which you are
              resident in.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              United States Legal Compliance
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              You represent and warrant that (i) You are not located in a
              country that is subject to the United States government embargo,
              or that has been designated by the United States government as a
              "terrorist supporting" country, and (ii) You are not listed on any
              United States government list of prohibited or restricted parties.
            </p>
          </div>
          <div style={{ marginTop: "30px" }}>
            <h2
              style={{
                color: "black",
                fontWeight: 700,
                fontSize: "35px",
              }}
            >
              Severability and Waiver
            </h2>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Severability
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              If any provision of these Terms is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent
              possible under applicable law and the remaining provisions will
              continue in full force and effect.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Waiver
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              Except as provided herein, the failure to exercise a right or to
              require performance of an obligation under these Terms shall not
              effect a party's ability to exercise such right or require such
              performance at any time thereafter nor shall the waiver of a
              breach constitute a waiver of any subsequent breach.
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Translation Interpretation
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              These Terms and Conditions may have been translated if We have
              made them available to You on our Service. You agree that the
              original English text shall prevail in the case of a dispute
            </p>
          </div>
          <div style={{ marginTop: "50px" }}>
            <h3
              className="PrivacyPolicyHeading"
            >
              Changes to These Terms and Conditions
            </h3>
            <br />
            <p className="PrivacyPolicyParam">
              We reserve the right, at Our sole discretion, to modify or replace
              these Terms at any time. If a revision is material We will make
              reasonable efforts to provide at least 30 days' notice prior to
              any new terms taking effect. What constitutes a material change
              will be determined at Our sole discretion.
            </p>
            <p className="PrivacyPolicyParam">
              By continuing to access or use Our Service after those revisions
              become effective, You agree to be bound by the revised terms. If
              You do not agree to the new terms, in whole or in part, please
              stop using the website and the Service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Condition;
