import { Input, Popover, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BulkModeCTA1, Songplace } from "..";
import {
  BackupIcon,
  DeleteIcon,
  FixIcon,
  PlayListNew,
  RestoreIcon,
  Star,
} from "../../assets";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { FilledButtonStyle } from "../../styles/common.style";
import {
  addItemToStorage,
  addSongToPlaylist,
  deleteAllRefresh,
  getRefreshedToken,
  refreshPlaylist,
} from "../../utils";
import { AddToPlayListModalStyle } from "../AddToPlayListModal/AddToPlayListModal.style";
import PlanToast from "../PlanToast/PlanToast";
import { PlayListBannerStyle } from "./PlayListBanner.style";
import SyncModal from "./SyncModal";
import RestoreModal from "./RestoreModal";
import BulkActionToggle from "../BulkModeCTA/BulkActionToggle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Spinner from "../Spinner/Spinner";
interface PlayListBannerProps {
  title?: string;
  desc?: string;
  totalSongs?: string;
  image?: string;
  token?: string;
  songs?: any[];
  currentPage?: number;
  setAddToPlaylistModal?: any;
  userId?: string;
  handleRefresh?: (
    values: any,
    deleteAllRefresh: any,
    refreshPlaylist: any,
    token: any,
    songs: any,
    handleRefresh: any
  ) => void;
  setIsModalVisible: (boolean) => void;
  isModalVisible?: boolean;
  currentPlaylistData?: any;
  hideFullPlaylistMode?: any;
  fixed?: any;
  refreshPlaylistFunction?: any;
  currentPlaylist?: any;
  isRefreshScheduled?;
  syncdatafromspotify?: any;
  restorePlaylsitSongs?: any;
  PlaylistRefreshRecord?: any;
  bulkAction?: any;
  setBulkAction?: any;
  showModalDeleteSelected?: any;
  selectedSongs?: any;
}

const PlayListBanner = (props: PlayListBannerProps) => {
  const { playlistId } = useParams();
  const { state } = usePlaylistContext();
  const {
    title,
    totalSongs,
    image,
    token,
    songs,
    userId,
    setAddToPlaylistModal,
    desc,
  } = props;
  // const { state } = useLoginContext();
  const { state: playlistState } = usePlaylistContext();
  const selectRefreshFreq = {
    value: "Once(Manually)",
    frequency: "spotifyPlaylistRefreshOperationOnce",
  };
  const { dispatch: newDispatch } = usePlaylistContext();
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();

  const [versionRestore, setVersionRestore] = useState(0);
  const [saveRestore, setSaveRestore] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [name, setname] = useState(title);
  const [syncModal, setsyncModal] = useState(false);
  const [restoreModal, setrestoreModal] = useState(false);
  const [description, setdescription] = useState("");
  const [updatedDesc, setUpdatedDesc] = useState("");
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isRestoreModalVisible, setisRestoreModalVisible] = useState(false);
  const [restoreModalLoader, setRestoreModalLoader] = useState(false)
  const [isEditPlaylistModalVisible, setIsEditPlaylistModalVisible] =
    useState(false);
  useEffect(() => {
    setname(title);
    setdescription(desc);
  }, [title, desc, playlistId, isEditPlaylistModalVisible]);
  useEffect(() => {
    setTheme(playlistState?.isWholeTheme === false ? "dark" : "light");
  }, [playlistState]);

  const handleCancel = () => {
    props.setIsModalVisible(false);
  };

  const handleOk = (values) => {
    addSongToPlaylist(
      Array.isArray(values.playlistId)
        ? values.playlistId
        : [values.playlistId],
      values.frequency,
      token,
      loginState.refreshToken,
      userId,
      0,
      "2023-08-08",
      "2023-08-08",
      null,
      ""
    );
    props.setIsModalVisible(false);
  };

  const array = [];
  playlistState?.playlists &&
    playlistState?.playlists.length > 0 &&
    playlistState?.playlists.map((e, index) => {
      const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
      if (
        e?.owner?.display_name !== spotifyUser?.username &&
        e?.id === playlistId
      ) {
        array.push(e?.owner?.display_name);
      }
    });

  const playListGet = async (call?: any) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=8&offset=3`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then(async (genreResponse) => {
        newDispatch({
          type: "INITIALIZE_PLAYLISTS",
          payload: genreResponse?.data?.items,
        });
        if (call) {
          // await timeout(3000);
          // window.location.reload();
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  };

  const handleEditPlaylistDetails = async (values) => {
    let obj =
      values?.playlistname && values.playlistDescription
        ? JSON.stringify({
          name: values?.playlistname,
          description: values.playlistDescription,
        })
        : values?.playlistname
          ? JSON.stringify({
            name: values?.playlistname,
          })
          : values?.playlistDescription
            ? JSON.stringify({
              description: values.playlistDescription,
            })
            : {};
    try {
      axios(`https://api.spotify.com/v1/playlists/${playlistId}`, {
        headers: {
          Authorization: "Bearer " + loginState.token,
        },
        data: obj,
        method: "PUT",
      }).then(() => {
        setUpdatedDesc(values.playlistDescription);
        setIsEditPlaylistModalVisible(false);
        playListGet(true);
        window.location.reload();
      });
    } catch (error) { }
  };

  // useEffect(() => {
  //   console.log(props, "props.PlaylistRefreshRecord");
  // }, [props.PlaylistRefreshRecord]);

  return (
    <>
      <PlayListBannerStyle>
        <div className={`bannerDiv ${props.fixed ? "hidebanner" : ""}`}>
          <div className="playlistActions">
            <div className="actionDiv" style={{ position: "relative" }}>
              <PlanToast
                available={["free", "pro", "unlimited"]}
                titleHeading={"ADD IN BULK"}
              >
                <Popover
                  content={
                    <p>
                      Enable bulk mode to add songs with the current date and
                      time
                    </p>
                  }
                  placement="bottomLeft"
                >
                  <p>
                    <span className="textAction">Add in bulk:</span>{" "}
                    <span>
                      <BulkModeCTA1 />
                    </span>
                  </p>
                </Popover>
              </PlanToast>
            </div>
            <div className="actionDiv">
              <PlanToast
                available={["pro", "unlimited"]}
                titleHeading={"Songplace only"}
              >
                <Popover
                  content={
                    <p>
                      Enable Songplace only mode to view songs with their
                      scheduled date and time
                    </p>
                  }
                  placement="bottomLeft"
                >
                  <p>
                    <span className="textAction">Songplace only:</span>{" "}
                    <span>
                      <Songplace />
                    </span>
                  </p>
                </Popover>
              </PlanToast>
            </div>
            {/* <div className="actionDiv">
              <PlanToast
                available={["pro", "unlimited"]}
                titleHeading={"Bulk action"}
              >
                <Popover content={<p>Edit or delete your song in bulk</p>} placement="bottomLeft">
                  <p>
                    <span className="textAction">Bulk action:</span>{" "}
                    <span>
                      <BulkActionToggle bulkAction={props.bulkAction}
                        setBulkAction={props.setBulkAction} />
                    </span>
                  </p>
                </Popover>
              </PlanToast>
            </div> */}
          </div>
          <div className="container">
            <div className="text-container">
              <div className="platform">
                <img alt="spotify" src={"./../static/images/spotifyLogo.png"} />{" "}
                Spotify
              </div>
              <div className="row1">
                {theme === "dark" ? (
                  <PlayListNew color={"white"} />
                ) : (
                  <PlayListNew color={"#1D1D1D"} />
                )}
                <p className="p1">Playlist</p>
              </div>
              <div className="row-space" style={{}}>
                <div>
                  <h1 className="title">{title}
                  </h1>
                  <p className="desc">{updatedDesc ? updatedDesc : desc}</p>
                  {/* <p className="desc">
                  {totalSongs} {array.length ? `- ${array.length} Owner` : ""}
                </p> */}
                </div>
              </div>
              <div className="p2">
                <div style={{ display: "flex" }}>
                  <p>Spotify</p>
                  <span className="platForm">-</span>
                  <p className="platForm">
                    {totalSongs} & {props.currentPlaylistData?.followers?.total}{" "}
                    Followers{" "}
                  </p>
                </div>
                <button
                  className="editPlaylistTitle"
                  onClick={() => {
                    setIsEditPlaylistModalVisible(true);
                  }}
                >
                  <Popover
                    content={<p>Edit playlist name or description</p>}
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: '10px',
                        opacity: 0.7
                      }}
                    >
                      <img src={"./static/images/EditIcon.svg"} />
                    </div>
                  </Popover>
                </button>
                <Popover content={<p>Copy playlist URL</p>} placement="top">
                  <img
                    alt="notes.svg"
                    src={"./static/images/copyIcon.svg"}
                    width="20px"
                    height="20px"
                    onClick={() => {
                      // console.log(window.location.pathname,'sadasdsasa')
                      let str = `https://open.spotify.com${window.location.pathname}`
                      navigator.clipboard.writeText(str)
                      message.success('Playlist URL copied')
                    }}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Popover>
               {/* <button 
                     className="editPlaylistTitle"
                     
                   >
                      <Popover
                        content={<p>Edit playlist name or description</p>}
                        placement="topLeft"
                      >
                        <div
                          style={{
                            cursor:"pointer",
                            marginLeft: "5px",
    marginTop: "-3px",
                          }}
                          onClick={() => {
                            setIsEditPlaylistModalVisible(true);
                          }}
                        >
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1955_58825)">
                              <path
                                d="M19.0607 8.20368L16.814 5.95701C16.7143 5.85703 16.5958 5.77771 16.4654 5.72359C16.335 5.66946 16.1952 5.6416 16.054 5.6416C15.9128 5.6416 15.773 5.66946 15.6426 5.72359C15.5122 5.77771 15.3937 5.85703 15.294 5.95701L6.154 15.1503L5.414 18.357C5.38247 18.5125 5.38578 18.6729 5.4237 18.827C5.46162 18.981 5.53319 19.1246 5.63328 19.2477C5.73336 19.3707 5.85948 19.47 6.00254 19.5385C6.1456 19.607 6.30206 19.6429 6.46066 19.6437C6.54264 19.6527 6.62535 19.6527 6.70733 19.6437L9.94066 18.9303L19.0607 9.72368C19.1606 9.62397 19.24 9.50552 19.2941 9.37511C19.3482 9.24469 19.3761 9.10488 19.3761 8.96368C19.3761 8.82248 19.3482 8.68267 19.2941 8.55225C19.24 8.42184 19.1606 8.30339 19.0607 8.20368ZM9.24733 17.697L6.80733 18.237L7.37399 15.817L14.254 8.89035L16.134 10.7703L9.24733 17.697ZM16.8873 10.017L15.0073 8.13701L16.0407 7.08368L17.934 8.97701L16.8873 10.017Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1955_58825">
                                <rect
                                  width="24"
                                  height="24"
                                  fill="white"
                                  transform="translate(0.25 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </Popover>
                    </button> */}
              </div>

              <div className="songPlayListButtons hide-responsive">
                <button
                  className="addSong"
                  onClick={() => {
                    setAddToPlaylistModal(true);
                  }}
                >
                  <PlanToast
                    available={["free", "pro", "unlimited"]}
                    titleHeading={"Add song"}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1955_58822)">
                        <path
                          d="M8.5801 6.46961H13.6001V8.52961H8.5801H8.2801V8.82961V13.8496H6.2201V8.82961V8.52961H5.9201H0.900098V6.46961H5.9201H6.2201V6.16961V1.14961H8.2801V6.16961V6.46961H8.5801Z"
                          fill="white"
                          stroke="#007AFF"
                          stroke-width="0.6"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1955_58822">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="translate(0.25 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Add song
                  </PlanToast>
                </button>
                {/* <button
                  className="editPlaylist"
                  onClick={() => {
                    setIsEditPlaylistModalVisible(true);
                  }}
                >
                  <Popover
                    content={<p>Edit playlist name or description</p>}
                    placement="topLeft"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_1955_58825)">
                          <path
                            d="M19.0607 8.20368L16.814 5.95701C16.7143 5.85703 16.5958 5.77771 16.4654 5.72359C16.335 5.66946 16.1952 5.6416 16.054 5.6416C15.9128 5.6416 15.773 5.66946 15.6426 5.72359C15.5122 5.77771 15.3937 5.85703 15.294 5.95701L6.154 15.1503L5.414 18.357C5.38247 18.5125 5.38578 18.6729 5.4237 18.827C5.46162 18.981 5.53319 19.1246 5.63328 19.2477C5.73336 19.3707 5.85948 19.47 6.00254 19.5385C6.1456 19.607 6.30206 19.6429 6.46066 19.6437C6.54264 19.6527 6.62535 19.6527 6.70733 19.6437L9.94066 18.9303L19.0607 9.72368C19.1606 9.62397 19.24 9.50552 19.2941 9.37511C19.3482 9.24469 19.3761 9.10488 19.3761 8.96368C19.3761 8.82248 19.3482 8.68267 19.2941 8.55225C19.24 8.42184 19.1606 8.30339 19.0607 8.20368ZM9.24733 17.697L6.80733 18.237L7.37399 15.817L14.254 8.89035L16.134 10.7703L9.24733 17.697ZM16.8873 10.017L15.0073 8.13701L16.0407 7.08368L17.934 8.97701L16.8873 10.017Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1955_58825">
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              transform="translate(0.25 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      Edit playlist
                    </div>
                  </Popover>
                </button> */}
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Refresh Playlist"}
                  >
                    <Popover
                      content={
                        <p>
                          Change the start date and time of each song to the
                          current date and time
                        </p>
                      }
                      placement="topLeft"
                    >
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            props.refreshPlaylistFunction();
                          }}
                        >
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.9416 4.20616C13.2075 3.41347 12.3163 2.80738 11.3339 2.43263C10.3516 2.05789 9.30305 1.92407 8.26585 2.04105C5.05132 2.38784 2.40612 5.18093 2.047 8.62074C1.56526 13.1665 4.84987 17 8.99285 17C10.3155 17 11.6111 16.5984 12.729 15.8418C13.8468 15.0853 14.7412 14.0048 15.308 12.726C15.5883 12.098 15.1679 11.3763 14.5197 11.3763C14.1957 11.3763 13.8891 11.5638 13.749 11.8731C13.2404 13.0438 12.3706 13.9913 11.2859 14.5563C10.2011 15.1214 8.96762 15.2694 7.79287 14.9755C5.84839 14.5162 4.28053 12.8197 3.86886 10.739C3.69581 9.9163 3.69782 9.06251 3.87473 8.24076C4.05164 7.41902 4.39892 6.65035 4.8909 5.9916C5.38288 5.33286 6.00696 4.80089 6.71698 4.43506C7.42701 4.06923 8.2048 3.87889 8.99285 3.87811C10.4468 3.87811 11.7432 4.52484 12.6891 5.54647L11.3665 6.96176C10.8147 7.55224 11.2001 8.5645 11.9796 8.5645H15.1241C15.6058 8.5645 16 8.14273 16 7.62722V4.2624C16 3.42822 15.054 3.00645 14.5022 3.59693L13.9416 4.20616Z"
                              fill="white"
                            />
                          </svg>
                          Refresh playlist
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Refresh Playlist"}
                  >
                    <Popover
                      content={<p>Check for backups</p>}
                      placement="topLeft"
                    >
                      {props.PlaylistRefreshRecord &&
                        props.PlaylistRefreshRecord.length &&
                        props.PlaylistRefreshRecord[0]?.uris &&
                        JSON.parse(props.PlaylistRefreshRecord[0].uris).length ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            setisRestoreModalVisible(true);
                          }}
                        >
                          <BackupIcon />
                          Restore backups
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                <div style={{ position: "relative" }}>
                  <PlanToast
                    available={["unlimited"]}
                    titleHeading={"Fix position"}
                  >
                    <Popover
                      content={
                        <p>Bring songs back to their original positions</p>
                      }
                      placement="topLeft"
                    >
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            setisModalVisible(true);
                          }}
                        >
                          <FixIcon />
                          Fix playlist
                        </button>
                      ) : null}
                    </Popover>
                  </PlanToast>
                </div>
                {/* {props.selectedSongs && props.selectedSongs.length ?
                  <div style={{ position: "relative" }}>

                    <Popover content={<p>Deleted deleted songs</p>} placement="topLeft">
                      {props.currentPlaylist?.length > 0 ? (
                        <button
                          className="editPlaylist"
                          onClick={() => {
                            props.showModalDeleteSelected();
                          }}
                        >
                          <DeleteOutlineIcon />
                          Deleted Selected Song
                        </button>
                      ) : null}
                    </Popover>
                  </div>
                  : null} */}
                <AddToPlayListModalStyle
                  visible={props.isModalVisible}
                  onOk={handleOk}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <div>
                    <h1 className="heading">Refresh Playlist</h1>
                  </div>
                  Refreshing playlist will permanently change the start date and
                  time for each song to the current date and time. Are you sure
                  you want to continue?
                  <br />
                  <br />
                  <div className="checkBoxSaveVersion">
                    <div className="note">
                      Note:
                      <br />
                      To help prevent data loss, we recommend creating a backup
                      for this playlist.
                      <br />
                    </div>
                    <div
                      className="checkVersion"
                      onClick={() => {
                        setSaveRestore(!saveRestore);
                      }}
                    >
                      {saveRestore ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                        </svg>
                      ) : null}
                    </div>
                    Create a backup for this playlist.
                  </div>
                  <div className="footer refreshModalFooter">
                    {/* {props.PlaylistRefreshRecord &&
                      props.PlaylistRefreshRecord.length &&
                      props.PlaylistRefreshRecord[0]?.uris &&
                      JSON.parse(props.PlaylistRefreshRecord[0].uris).length ? (
                      <FilledButtonStyle
                        border="1px solid rgb(0, 122, 255)"
                        background="transparent"
                        type="button"
                        onClick={() => {
                          props.setIsModalVisible(false);
                          setisRestoreModalVisible(true);
                          // console.log(props.PlaylistRefreshRecord, 'PlaylistRefreshRecord')
                        }}
                        className="btn1"
                      >
                        Restore playlist songs
                      </FilledButtonStyle>
                    ) : (
                      <div></div>
                    )} */}
                    <div></div>

                    <div className="yesNoButtons">
                      <FilledButtonStyle
                        background="transparent"
                        width="90px"
                        type="button"
                        onClick={handleCancel}
                        className="btn1"
                      >
                        No
                      </FilledButtonStyle>

                      <FilledButtonStyle
                        disabled={!selectRefreshFreq}
                        type="submit"
                        className="btn2"
                        style={{ width: "230px" }}
                        onClick={() => {
                          props.handleRefresh(
                            selectRefreshFreq,
                            deleteAllRefresh,
                            refreshPlaylist,
                            token,
                            songs,
                            saveRestore
                          );
                        }}
                      >
                        Yes, I am sure
                      </FilledButtonStyle>
                    </div>
                  </div>
                </AddToPlayListModalStyle>
              </div>
            </div>
            <div className="playListPic" style={{ width: image ? "" : "250px" }}>
              {image ? (
                <img
                  className="image imagecontainerdirection"
                  src={image}
                  alt="playlist thumbnail"
                />
              ) : (
                <svg
                  className="playlistIcon"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M429.46 32.07c-23.6 6.53-205.55 58.81-250.54 71.43a4 4 0 00-2.92 3.83v247a2 2 0 01-1.33 1.89l-27.85 9.55c-19 7.44-66.82 16.68-66.82 59.19 0 35.54 24.63 51.54 45.86 54.28a52.06 52.06 0 007.81.8c7.37 0 36.38-7.08 53.3-18.08C208 448.25 208 448 208 412V202c0-.9.62-.84 1.48-1.07l188-51.92a2 2 0 012.53 2v155.54a2 2 0 01-1.36 1.89c-8.9 3-19.23 6.5-26.48 9.12C341.39 328.68 304 335.65 304 376c0 38.51 28.26 54.58 46.3 55.83a87.37 87.37 0 0021.33-1c9-1.38 24.09-5.9 38.14-14.86C432 401.79 432 401.51 432 360V34a2 2 0 00-2.54-1.93z"></path>
                </svg>
              )}
            </div>
          </div>
          <AddToPlayListModalStyle
            visible={isEditPlaylistModalVisible}
            onOk={handleOk}
            footer={null}
            onCancel={() => {
              setname("");
              setdescription("");
              setIsEditPlaylistModalVisible(false);
            }}
          >
            <div>
              <h1 className="heading">Edit playlist details</h1>
            </div>
            {isEditPlaylistModalVisible ? (
              <>
                <div className="formAddToPlaylist">
                  <div className="formPlaylist">
                    <div className="playlistPosition">
                      <label className="label">
                        <p>Playlist name</p>
                        <Input
                          // type="number"
                          value={name}
                          placeholder="Playlist name"
                          onChange={(value) => {
                            setname(value.target.value);
                          }}
                        />
                      </label>
                    </div>
                    <label className="label">
                      <p>Description</p>
                      <Input.TextArea
                        // type="text"
                        className="addNoteText"
                        value={description}
                        placeholder="Description"
                        rows={4}
                        onChange={(value: any) => {
                          setdescription(value.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div className="buttonDiv">
                  <button
                    className="addSong"
                    onClick={() =>
                      handleEditPlaylistDetails({
                        playlistId: playlistId,
                        playlistname: name,
                        playlistDescription: description,
                      })
                    }
                  >
                    Done
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </AddToPlayListModalStyle>
        </div>

        <AddToPlayListModalStyle
          visible={isModalVisible}
          footer={null}
          onCancel={() => {
            setisModalVisible(false);
          }}
          className="syncModal"
        >
          <div>
            <h1 className="heading">Fixed position</h1>
          </div>
          This will bring songs back to their fixed position. Are you sure you
          want to continue?
          <div className="footer">
            <FilledButtonStyle
              background="transparent"
              width="90px"
              type="button"
              onClick={() => {
                setisModalVisible(false);
              }}
              className="btn1"
            >
              Cancel
            </FilledButtonStyle>
            <FilledButtonStyle
              disabled={!selectRefreshFreq}
              type="submit"
              className="btn2"
              style={{ width: "230px" }}
              onClick={() => {
                setisModalVisible(false);
                setsyncModal(true);
                props.syncdatafromspotify({
                  syncModal,
                  setSyncModal: setsyncModal,
                });
              }}
            >
              Confirm
            </FilledButtonStyle>
          </div>
        </AddToPlayListModalStyle>

        <AddToPlayListModalStyle
          visible={isRestoreModalVisible}
          footer={null}
          onCancel={() => {
            setisRestoreModalVisible(false);
          }}
          className="syncModal"
        >
          <div>
            <h1 className="heading">Restore songs</h1>
          </div>
          You can restore one or more backups by choosing the versions below:
          <div className="verisonsUi">
            <div className="innerVerisonsUi">
              <p>Available backups:</p>
              {props.PlaylistRefreshRecord &&
                props.PlaylistRefreshRecord.length &&
                props.PlaylistRefreshRecord[0]?.uris &&
                JSON.parse(props.PlaylistRefreshRecord[0]?.uris).map((a, i) => (
                  <div
                    className="verisons"
                    onClick={() => {
                      setVersionRestore(a?.version);
                    }}
                  >
                    <div className="checkVersion">
                      {versionRestore === a?.version ? (
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                        </svg>
                      ) : null}
                    </div>
                    Backup {a.date ? new Date(a.date).toDateString() : i + 1}
                    <span className="songsLength">
                      ({a?.uris?.length} Songs)
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="footer">
            <FilledButtonStyle
              background="transparent"
              width="90px"
              type="button"
              onClick={() => {
                setisRestoreModalVisible(false);
              }}
              className="btn1"
            >
              Cancel
            </FilledButtonStyle>
            <FilledButtonStyle
              disabled={!versionRestore || restoreModalLoader}
              type="submit"
              className="btn2 restoreButton"
              style={{ width: "230px" }}
              onClick={() => {
                setRestoreModalLoader(true)
                // setisRestoreModalVisible(false);
                // setrestoreModal(true);
                props.restorePlaylsitSongs(
                  restoreModal,
                  setrestoreModal,
                  versionRestore
                );
              }}
            >
              {restoreModalLoader ? <Spinner /> : "Restore"}
              
            </FilledButtonStyle>
          </div>
        </AddToPlayListModalStyle>

        <SyncModal
          isModalVisible={syncModal}
          setIsModalVisible={setsyncModal}
        />
        <RestoreModal
          isModalVisible={restoreModal}
          setIsModalVisible={setrestoreModal}
        />
      </PlayListBannerStyle>
    </>
  );
};

export default PlayListBanner;
