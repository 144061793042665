import styled from "styled-components";

export const CardsListStyle1 = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
