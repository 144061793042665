import { Select } from "antd";
import styled from "styled-components";

type SelectBoxStyleProps = {
  name: string;
  width?: string;
  error?: boolean;
};

export const SelectBoxStyle = styled(Select)<SelectBoxStyleProps>`
  &.ant-select {
    margin: 5px 0;
    font-size: 13px;
    max-width: 358px;
    width: 100%;

    .ant-select-selection-search {
      padding-left: 30px;
    }
    .ant-select-arrow {
      right: 25px;
    }
    .ant-select-selection-item {
      font-size: 18px;
      color: ${(props) => props.theme.colors.primary};
    }
    .ant-select-selector {
      overflow: auto;
      height: 53px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      grid-gap: 20px;
      width: 100% !important;
      border: 1px solid #5f5e5e;
      font-size: 14px !important;
      background: ${(props) => props.theme.background.inputs};
      border: 1px solid ${(props) => props.theme.background.inputsBorder};
      ${(props) =>
        props.width && props.width === "small" && "height: 26px !important;"}
    }
  }
  .ant-select-selection-item {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 600px) {
    &.ant-select,
    .ant-select-selector {
      font-size: 17px !important;
    }
    .ant-select .ant-select-selector,
    .ant-select .ant-select-selection-search,
    .ant-select-selection-search-input,
    .ant-select-selection-search,
    .ant-select-selection-item,
    .ant-select-selection,
    .ant-select-selection-placeholder,
    .ant-select-item {
      font-size: 17px !important;
    }
  }
`;
export const SelectHoverInputStyle = styled(Select)<any>`
  &.ant-select {
    font-size: 1.3rem;
    color: #3ba0d6;
    max-height: 2.3rem;
    width: 100%;
    margin-top: -2rem;

    .ant-select-arrow {
      color: red;
    }
    div.ant-select-selector {
      border: none;
      background-color: transparent;
      color: #3ba0d6;
      max-height: 2.3rem;
      font-size: 14px !important;
      padding-left: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    &.ant-select,
    .ant-select-selector {
      font-size: 17px !important;
    }
    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }
  }
`;

type SelectInputStyleProps = {
  type?: string;
};

export const SelectInputStyle = styled.div<SelectInputStyleProps>`
  // display: grid;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;

  .select-label {
    font-size: 14px;
    color: #0c0c0c;
    font-weight: 500;
    margin-left: 5px;
    color: ${(props) => props.theme.colors.primary};
  }
`;
