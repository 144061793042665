import styled from "styled-components";

const PricingPlanStyle = styled.div`

    display:flex;
    align-items:center;
    justify-content:center;
    flex-wrap:wrap; 
    z-index: 1;
    position: relative;
  .pricingPlan{
    background-color:#242429;
    border: 0.8px solid #D1D1D1;
    box-shadow: 0px 0px 48px 0px #7987B014 inset;

box-shadow: 8px 8px 29px 0px #50587114;

box-shadow: 2px 2px 14px 0px #50587105;
border-radius:24px;
padding:30px;
max-width: calc(33% - 20px);
width:100%;

transition:0.2s;
  }
  .pricingPlan.proPlan{
    margin:0px 5px;
  }
  .pricingPlan:hover{
    background-color:#007AFF;
    transform: scale(1.05);
  }
  .innerPricingPlan{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    margin-bottom:10px;
  }
  .packageName{
    opacity:0.8;
  }
  .packageName h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    
    
  }
  .packageName p{
    font-family: Poppins;
    font-size: 15px;
    font-weight: bolder;
    line-height: 25px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: left;
    margin-top:8px;
    
    color: #FFFFFF;
  }
  .belowPrice{
    margin-bottom:10px;
    color: rgb(255, 255, 255);
    height: 20px;

  }
  .packagePrice{
    // margin-bottom:10px;
    display: flex;
    align-items: flex-end;
  }
  .packagePrice h1{
    font-family: Poppins;
    font-size: 54px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: -1.7999999523162842px;
    text-align: left;
    color: #FFFFFF;
  }
  .packagePrice p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    
    color: #FFFFFF;
    margin-bottom: 9px;
    margin-left: 9px;
  }
  .priceLine{
    width:100%;
    margin-bottom:10px;
  }
  .features{
    width:100%;
  }
  .features .feat{
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:10px;
  }
  .features .feat p{
    font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 25px;
letter-spacing: 0px;
text-align: left;
color:white;
margin-left:9.25px;
}
.getStarted{
  font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0px;
text-align: center;
width:100%;
padding:9px 0px;;
background:transparent;
border: 2px solid #FFFFFF;
border-radius:8px;


font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 27px;
letter-spacing: 2px;
text-align: center;
color:white;

cursor:pointer;
transition: 0.3s ease-in-out;

}
.getStarted:hover{
  background: white;
  color: #007aff;
}

@media only screen and (max-width: 1024px) {
  .pricingPlan{
    max-width: 100% !important;
    width: 70%;
    margin: 10px 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .pricingPlan,.pricingPlan.proPlan{
    margin: 10px 0px;
    width: 100%;
    transform: scale(1);
  }
}
`
export default PricingPlanStyle