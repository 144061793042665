import axios from "axios";
import { useEffect, useState } from "react";
import { MusicCard } from "..";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import MusicCardRecent from "../MusicCard/MusicCardRecent";
import SectionWrapperNew from "../SectionWrapper/SectionWrapperNew";
import { CardsListStyle1 } from "./CardsList.1style";
import { CardsListStyle } from "./CardsList.style";
type CardsListProps = {
  heading: string;
  subheading: string;
  playlistId: string;
  grid: string;
  gridgap: string;
  gridStyle: string;
  playlistData?: any;
};

const CardsList = (props: CardsListProps) => {
  const { heading, playlistId, grid, gridgap, gridStyle, playlistData } = props;
  const { state } = useLoginContext();

  const [cardsList, setCardsList] = useState([]);

  const { state: playlistState, dispatch } = usePlaylistContext();

  const [CardBolean, setCardBolean] = useState(true);

  const clickHandle = (
    url: any,
    id: any,
    image: any,
    name: any,
    artist: any,
    external: any
  ) => {
    if (url !== null) {
      // console.log(playlistState, url, "check");
      if (playlistState?.previewSong?.url === url) {
        dispatch({
          type: "SET_PREVIEW_SONG",
          payload: {
            url: "",
            id: "",
          },
        });
        dispatch({
          type: "UPDATE_SONGS_SEARCH_LIST",
          payload: "",
        });
      } else {
        if (url === "" || url !== null) {
          dispatch({
            type: "UPDATE_SONGS_SEARCH_LIST",
            payload: id,
          });
          dispatch({
            type: "SET_PREVIEW_SONG",
            payload: {
              url: url,
              id: id,
              name: name,
              artist: artist,
              image: image,
              external: external,
            },
          });
        }
      }
    } else {
      window.open(`${external}`, "_blank");
    }
  };

  useEffect(() => {
    if (playlistId) {
      axios(
        `https://api.spotify.com/v1/playlists/${playlistId}/tracks?limit=4`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + state.token,
          },
        }
      )
        .then((genreResponse) => {
          if (genreResponse?.data?.items?.length) {
            setCardsList([
              ...genreResponse?.data?.items?.map((e) => {
                return {
                  ...e?.track,
                  added_at: e.added_at,
                  resultType: "playlist Data",
                };
              }),
            ]);
          }
        })
        .catch(async (error) => {
          if (
            error.response?.data?.error?.status === 401 ||
            error.response?.status === 400
          ) {
          }
        });
    }
  }, [playlistId, state]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (playlistData && playlistData.length) {
      let arr = [];
      playlistData.map((a: any, i: number) => {
        axios(
          `https://api.spotify.com/v1/playlists/${a.id}/tracks?limit=4`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + state.token,
            },
          }
        )
          .then(async (genreResponse) => {
            if (genreResponse?.data?.items?.length) {
              // console.log(cardsList)

              // setCardsList([
              //   ...cardsList,
              await genreResponse?.data?.items?.map((e, index) => {
                arr.push({
                  ...e?.track,
                  added_at: e.added_at,
                  resultType: "playlist Data",
                });
                if (
                  playlistData.length - 1 === i &&
                  genreResponse?.data?.items.length - 1 === index
                ) {
                  setCardsList(arr);
                  setCardBolean(!CardBolean);
                }
              });

              // ]);
            }
          })
          .catch(async (error) => {
            if (
              error.response?.data?.error?.status === 401 ||
              error.response?.status === 400
            ) {
            }
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistData]);

  if (cardsList?.length === 0) return null;

  return (
    <>
      <CardsListStyle1>
        <SectionWrapperNew heading={heading} number={cardsList.length}>
          <CardsListStyle grid={grid} gridgap={gridgap} gridStyle={gridStyle}>
            {cardsList?.map((data) => {
              // const { album, name, artists, external_urls, preview_url, id } =
              //   data;
              // console.log(data);
              const url = data?.album?.images?.length
                ? data?.album?.images[0].url
                : null;
              const artist = data?.artists?.length
                ? data?.artists[0].name
                : null;
              return (
                <div
                  className="divWidthauto"
                  onClick={() =>
                    clickHandle(
                      data?.preview_url,
                      data?.id,
                      url,
                      data?.name,
                      artist,
                      data?.external_urls?.spotify
                    )
                  }
                >
                  {gridStyle === "box" ? (
                    <MusicCard
                      heading={data?.name}
                      description={
                        data?.artists?.length ? data?.artists[0]?.name : null
                      }
                      image={
                        data?.album?.images?.length
                          ? data?.album?.images[0].url
                          : ""
                      }
                      externalUrl={data?.external_urls?.spotify}
                      previewUrl={data?.preview_url}
                      id={data?.id}
                      popularity={data?.popularity}
                    />
                  ) : gridStyle === "table" ? (
                    <MusicCardRecent
                      heading={data?.name}
                      description={
                        data?.artists?.length ? data?.artists[0]?.name : null
                      }
                      image={
                        data?.album?.images?.length
                          ? data?.album?.images[0].url
                          : ""
                      }
                      externalUrl={data?.external_urls?.spotify}
                      previewUrl={data?.preview_url}
                      id={data?.id}
                      popularity={data?.popularity}
                    />
                  ) : null}
                </div>
              );
            })}
          </CardsListStyle>
        </SectionWrapperNew>
      </CardsListStyle1>
    </>
  );
};

export default CardsList;
