import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import { useEffect, useState } from "react";
import { PlayIconNew } from "../../assets/index";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { MusicCardStyle, MusicCardStyle1 } from "./MusicCard.style";
type MusicCardProps = {
  heading: string;
  description?: string;
  image: string;
  externalUrl: string;
  previewUrl: any;
  id: any;
  popularity: any;
};

const MusicCard = (props: MusicCardProps) => {
  const {
    heading,
    description,
    image,
    externalUrl,
    previewUrl,
    id,
    popularity,
  } = props;

  const { state } = usePlaylistContext();

  const { state: playlistState } =
    usePlaylistContext();
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    setTheme(playlistState?.isWholeTheme === false ? "dark" : "light");
  }, [playlistState]);

  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => {
    setPlaying(true);
  }, [state.previewSong.url]);
  return (
    <>
      {state.previewSong.id === id && isPlaying ? (
        <MusicCardStyle1
      
        >
          <img alt={heading} className="card-thumbnail" src={image} />

          <div className="row">
            <div className="nameDetail">
              <h3 className="card-heading">{heading}</h3>
              {description && <p className="card-description">{description}</p>}
            </div>
            <div className="popularButton">

              <div className="popularity"><svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 9h-1v7h1c0.275 0 0.5-0.225 0.5-0.5v-6c0-0.275-0.225-0.5-0.5-0.5z"></path><path d="M11.5 9c-0.275 0-0.5 0.225-0.5 0.5v6c0 0.275 0.225 0.5 0.5 0.5h1v-7h-1z"></path><path d="M16 8c0-4.418-3.582-8-8-8s-8 3.582-8 8c0 0.96 0.169 1.88 0.479 2.732-0.304 0.519-0.479 1.123-0.479 1.768 0 1.763 1.304 3.222 3 3.464v-6.928c-0.499 0.071-0.963 0.248-1.371 0.506-0.084-0.417-0.129-0.849-0.129-1.292 0-3.59 2.91-6.5 6.5-6.5s6.5 2.91 6.5 6.5c0 0.442-0.044 0.874-0.128 1.292-0.408-0.259-0.873-0.435-1.372-0.507v6.929c1.696-0.243 3-1.701 3-3.464 0-0.645-0.175-1.249-0.479-1.768 0.31-0.853 0.479-1.773 0.479-2.732z"></path></svg> {popularity}</div>
              <button className="playButton">
                <GraphicEqIcon className="GraphicEqIcon" />
              </button>
            </div>
          </div>
        </MusicCardStyle1>
      ) : (
        <MusicCardStyle
        // onClick={() => {
        //   clickHandle1(previewUrl, id, image, heading, description);
        // }}
        >
          <img alt={heading} className="card-thumbnail" src={image} />

          <div className="row">
            <div className="nameDetail">
              <h3 className="card-heading">{heading}</h3>
              {description && <p className="card-description">{description}</p>}
            </div>


            <div className="popularButton">
              <div className="popularity"><svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 9h-1v7h1c0.275 0 0.5-0.225 0.5-0.5v-6c0-0.275-0.225-0.5-0.5-0.5z"></path><path d="M11.5 9c-0.275 0-0.5 0.225-0.5 0.5v6c0 0.275 0.225 0.5 0.5 0.5h1v-7h-1z"></path><path d="M16 8c0-4.418-3.582-8-8-8s-8 3.582-8 8c0 0.96 0.169 1.88 0.479 2.732-0.304 0.519-0.479 1.123-0.479 1.768 0 1.763 1.304 3.222 3 3.464v-6.928c-0.499 0.071-0.963 0.248-1.371 0.506-0.084-0.417-0.129-0.849-0.129-1.292 0-3.59 2.91-6.5 6.5-6.5s6.5 2.91 6.5 6.5c0 0.442-0.044 0.874-0.128 1.292-0.408-0.259-0.873-0.435-1.372-0.507v6.929c1.696-0.243 3-1.701 3-3.464 0-0.645-0.175-1.249-0.479-1.768 0.31-0.853 0.479-1.773 0.479-2.732z"></path></svg> {popularity}</div>
             {previewUrl ? 
              <button className="playButton">
                {theme === "dark" ? (
                  <PlayIconNew color={"white"} />
                ) : (
                  <PlayIconNew color={"#1D1D1D"} />
                )}
              </button>
              :
              <div className="playSpot" onClick={(e) => {
                e.stopPropagation()
                window.open(externalUrl,"_blank");
            }}
            >
                <img alt="play in spotify" src='./static/images/spotifyLogo.png' />
            </div>
              }
            </div>
          </div>
        </MusicCardStyle>
      )}
    </>
  );
};

export default MusicCard;
