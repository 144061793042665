import { Table } from "antd";
import styled, { css } from "styled-components";
type ButtonProps = {
  width?: string;
  height?: string;
  border?: string;
  buttonType?: string;
  background?: string;
};
const FilledButtonStyle = styled.button<ButtonProps>`
  background: ${(props) => (props.background ? props.background : "#007AFF")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  max-width: ${(props) => (props.width ? props.width : "358px")};
  width: 100%;
  height: ${(props) => (props.height ? props.height : "48px")};
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;

  :hover {
    cursor: pointer;
  }
  :disabled {
    background-color: #cccccc;
    color: #666666;
  }
`;

const OutlineButtonStyle = styled.button<ButtonProps>`
  background-color: white;
  color: black;
  max-width: ${(props) => (props.width ? props.width : "358px")};
  width: 100%;
  height: ${(props) => (props.height ? props.height : "48px")};
  border-radius: 5px;
  border: 1px solid black;
  :hover {
    cursor: pointer;
  }
`;

const InputBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 5px;
  width: 50%

  label {
    position: relative;
    font-size: 14px;
  }

  .error {
    color: red;
  }
`;

const LoginLayOutStyle = styled.div`
  // display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  min-height: 100vh;
  justify-content: center;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }
`;
const ContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  background: #2d374880;
  width: 80%;
  padding: 20px;
  border-radius: 8px;

  .user-info {
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
  }

  .title {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  }
  margin-bottom: 30px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const TableStyle = styled(Table)`
  @media (max-width: 600px) {
    overflow: auto;
  }

  .player {
    width: 350px;
  }

  .ant-table {
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    width: 100%;
  }
  .ant-message-notice-content {
    background: ${(props) => props.theme.background.primary4} !important;
    /* Blue */
    border: 2px solid ${(props) => props.theme.background.primary4} !important;
    border-radius: 4px !important;
    color: ${(props) => props.theme.colors.primary} !important;
    margin-top: 20px !important;
    z-index: 1000000000 !important;
    position: relative;
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: ${(props) =>
      props.theme.background.primary4} !important;
  }

  .ant-popover-inner-content {
    width: 100%;
    background: ${(props) => props.theme.background.primary4} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-table-thead > tr > th {
    background-color: transparent !important;
    border: none;
    border-radius: 0px !important;
    color: ${(props) => props.theme.colors.primary};
    font-size: 16px;
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 15px !important;
  }
  .ant-table-tbody > tr:hover {
    background-color: transparent !important;
    transition: all 0;
  }

  .icons {
    display: flex;
    gap: 5px;
    cursor: pointer;
    align-items: center;
  }

  .table-list-image {
    height: 64px;
  }

  .ant-table-tbody > tr > td {
    // border-bottom: 1px solid #333333 !important;
  }

  // .ant-table-cell-row-hover {
  //   background: black !important;
  // }

  // .ant-table-tbody > tr.ant-table-row:hover > td,
  // .ant-table-tbody > tr > .td.ant-table-cell-row-hover {
  //   background: #333333 !important;
  // }

  .artist-row {
    display: flex;
    justify-content: space-between;
  }

  .icons > svg {
    cursor: pointer !important;
  }

  .icons > token {
    cursor: pointer !important;
  }

  .ant-pagination-item-active a {
    color: ${(props) => props.theme.colors.secondary} !important;
    border: none;
  }

  .title {
    display: flex;
    gap: 26px;
    align-items: center;
  }
`;

export {
  ContainerStyle, FilledButtonStyle, InputBoxStyle, LoginLayOutStyle, OutlineButtonStyle, TableStyle
};

export const SlimScrollStyle = css`
  // /* width */
  // &::-webkit-scrollbar {
  //   width: 10px;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: rgba(255, 255, 255, 0.15);
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: rgba(255, 255, 255, 0.1);
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #007aff !important;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #007aff;
  }
`;
