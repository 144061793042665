import { useState } from "react";

import { usePlaylistContext } from "../../context/PlaylistContext";
import { BulkModeCTAStyle, BulkModeWrapperStyle } from "./BulkModeCTA.style1";

const Songplace = () => {
  const { state, dispatch } = usePlaylistContext();
  const [useText, setText] = useState("OFF");

  const handleChange = (e) => {
    if (e === false) {
      setText("OFF");
    } else {
      setText("ON");
    }
    dispatch({
      type: "TOGGLE_SONG_PLACE",
      payload: e,
    });
  };


  return (
    <BulkModeWrapperStyle>
      <div className="SwitchPlayListSpannerRows_text_songplace_only" style={{ display: "flex", alignItems: "center" }}>
        <BulkModeCTAStyle checked={state.isSongPlace} onChange={handleChange} />
        <p className="text_songplace_only">
          {useText}
        </p>
      </div>
    </BulkModeWrapperStyle>
  );
};

export default Songplace;
