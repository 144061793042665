import styled from 'styled-components';

const SignupFormStyle = styled.div`
	font-size: 14px;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;
	gap: 25px;
	width: 100%;
	padding: 0 20px;
    background:#007aff;
	input {
		color: #000;
		background-color: transparent;
	}

	.form-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.or-text {
		font-weight: 700;
	}

	.ant-input {
		width: 100% !important;
	}
    #inputed{
		width:100% !important;
	}
	.input-wrapper {
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// gap: 20px;
		// @media (max-width:767px) {
		// 	display:grid;
		// 	grid-template-columns:1fr;
		// }
	}

	.terms {
		display: flex;
		gap: 10px;
		align-items: center;

		label {
			font-size: 14px;
		}

		input[type=checkbox] {
			width: 20px;
			height: 20px;
		}
	}
`;
export { SignupFormStyle };
