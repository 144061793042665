import styled from "styled-components";
export const PlayListBannerStyle = styled.div`
  display: flex;
  padding: 20px 60px 10px;
  // background: linear-gradient(180deg, #007aff 0%, rgba(0, 122, 255, 0) 100%);
  background: transparent;
  flex-direction: column;

  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  padding-top: 65px;
  .bannerDiv {
    transition: 2s;
    @media screen and (min-width: 768px) {
      min-height: auto;
    }
  }
  .hidebanner {
    opacity: 0;
    overflow: hidden;
    animation: reduceHeight 0.5s;
  }

  @keyframes reduceHeight {
    0% {
      height: 420px !important;
    }

    100% {
      height: 0px !important;
    }
  }

  .platform {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  .platform img {
    width: 25px;
    height: 25px;
    margin-right: 7px;
    object-fit: contain;
  }
  .images1 {
    height: 24px;
    width: 22px;
  }
  .row1 {
    display: flex;
  }
  .p1 {
    margin-left: 10px;
  }
  .row-space {
    display: flex;
    justify-content: space-between;
  }
  #btn12 {
    padding: 10px;
    height: 50px;
    margin-right: 30px;
    border: white;
    background: #181818;
  }

  .container {
    display: flex;
    // align-items: center;
    gap: 10px;
    width: 100%;
    
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      margin: auto;
      width: 100%;
      align-items: center;
      flex-direction: column-reverse;
    }
  }

  .image {
    width: 220px;
    // opacity: 0.6;
    box-shadow: 0px 6px 32px 6px rgba(0, 0, 0, 0.2);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 13px;
    font-size: 20px;
    width: 70%;
    margin-left: 2%;
    margin-bottom: 20px;
  }

  .title {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: 0.04em;
    text-align: left;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: flex;
    align-items: flex-start;

    @media (max-width: 768px) {
      font-size: 16px;
    }
 
  }
       .editPlaylistTitle{
      background:transparent;
      border:none;
    }
  .desc {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-align: left;

   
  }

  .p1 {
    font-size: 20px;
  }

  .p2 {
    display: flex;

    margin-top: 8.5px;
    font-family: Poppins;
    color: ${(props) => props.theme.colors.primary};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
    align-items: center;
  }

  .p2 span {
    margin: 0px 14px 0px 12px;
  }
  .p2 .platForm {
    opacity: 0.7;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .row-space {
    flex-direction: row;
  }



  .pre-release {
    font-size: inherit;
  }
  .songPlayListButtons {
    display: flex;
    align-items: center;

    margin-top: 15px;
    flex-wrap: wrap;
    @media only screen and (max-width: 600px) {
      justify-content: space-between;
      width: 100%;
      margin: 20px 0px;
    }
  }
  .songPlayListButtons .addSong {
     display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: rgb(0, 122, 255);
    padding: 0px 23.25px;
    height: 39px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
    border: none;
    margin-right: 12px;
    cursor: pointer;
    position: relative;
  }
  .songPlayListButtons .addSong svg {
    margin-right: 10px;
    // width:25px;
    // height:25px;
  }
  .songPlayListButtons .editPlaylist {
       display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 23.25px;
    height: 39px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 12px;
    cursor: pointer;
    position: relative;

    background: linear-gradient(0deg, #303036, #303036),
      linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    border-radius: 8px;
    border: none;
    border: 1px solid #ffffff33;
  }
  .songPlayListButtons .editPlaylist svg {
    margin-right: 10px;

    // width:25px;
    // height:25px;
  }
  .playListPic {
    height: 230px;
    // width: 375.64px;
    display: flex;
    background: #242429;
    align-items: center;
    border-radius: 0px;
    overflow: hidden;
    justify-content: center;
  }
  .playlistIcon {
    font-size: 80px;
    color: black;
  }
  .playListPic img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .playListPic .upcoming {
    transform: rotate(-90deg);
    height: 67.71px;
    white-space: nowrap;
    right: -24px;
    position: absolute;
    height: 67.71px;
    display: flex;
    align-items: center;

    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  .playlistActions {
    display: flex;
    margin-bottom: 17px;
  }
  .playlistActions .actionDiv {
    display: flex;
    align-items: center;
    position: relative;
  }
  .playlistActions .actionDiv p {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
  .playlistActions .actionDiv p .textAction {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity: 0.7;
    margin-right: 7px;
  }
  .playlistActions .actionDiv p span {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.04em;
    text-align: left;
  }

  @media only screen and (max-width: 900px) {
    padding: 80px 10px 10px;
    .playlistActions {
      white-space: nowrap;
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 3px;
        height: 2px !important;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888 !important;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .actionDiv {
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .text-container {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
      width: 100%;

      display: flex;
      flex-direction: column;
    }
    .playListPic {
      width: 100%;
    }
    .images1 {
      height: 16px !important;
      width: 16px !important;
    }

    .p1 {
      font-size: 14px;
    }

    .p2 {
      font-size: 14px;
    }

    .row-space {
      flex-direction: column;
    }

    .title {
      // font-size: 14px;
      margin: 10px 0px;
      font-size: 28px !important;
      line-height: initial;
    }

    #btn12 {
      padding: 8px;
      height: 40px;
      margin-right: 90px;
    }

    .pre-release {
      font-size: 12px;
    }

    .spanner {
      padding: 0px 0px !important;
    }

    .icons {
      font-size: 14px;
    }
    .platform {
      margin-bottom: 6px;
    }
  }

  @media only screen and (max-width: 375px) {
    .spanner {
      padding: 0px 0px !important;
    }
  }
  @media only screen and (max-width: 320px) {
    #btn12 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .playListbannerSmall {
    display: none;
    .visible {
      display: block;
    }
    .playListPic {
      width: 100px;
      aspect-ratio: 1;
      height: 100px;
      margin-right: 10px;
    }
    .playListDetail {
      display: flex;
      align-items: center;
      h1 {
        font-size: 16px;
        line-height: unset !important;
      }
      p {
        line-height: unset !important;
        font-size: 14px;
      }
      .textDetail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
      }
    }
  }
`;
