import styled from "styled-components";
const GeneralInformationFormStyle = styled.div`
  label {
    color: #fff;
  }
  .accountLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 50px;
  }
  .div-90 {
    width: 100%;
  }

  .p3 {
    padding: 10px 0px;
    text-align: center;
  }

  .poo1 {
    padding: 10px 0px;
    margin-left: -2px;
  }

  .diver3 {
    background: ${(props) => props.theme.background.boxes};
    border-radius: 7px;
    width: calc(50% - 20px);
    color: white;
    display: flex;
    margin-top: 30px;
    // justifyContent: space-around;
    align-items: center;
    cursor: pointer;
  }

  .diver4 {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .ppp1 {
    padding: 10px 0px;
    margin-left: -2px;
    text-transform: capitalize;
    font-size: 17px;
    color: ${(props) => props.theme.colors.primary};
  }
  .playListNumbers {
    font-family: Poppins !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .playListNumbers svg {
    margin-right: 12px;
  }
  .btnns {
    background: rgb(234, 67, 53, 0.2);
    border: 1px solid #ea4335;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  .btnns1 {
    padding: 10px 15px;
    color: #49982d;
    border: 1.5px solid #56b932;
    border-radius: 5px;
    text-transform: capitalize;
    background: #57bb3436;
  }

  .divers1 {
    // background: #2E2D2D;
    border-radius: 5px;
    width: 48%;
    height: 147px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    border: 1px dashed #2e2d2d;
    backdrop-filter: blur(75px);
    gap: 10px;
    margin-top: 30px;
  }

  .btnns3 {
    background: #007aff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 0px;
    margin-top: 15px;
    cursor: pointer;
  }

  .divider21 {
    width: 100%;
  }

  .diver6 {
    width: 58%;
    margin-left: 3%;
  }

  .images2 {
    width: 100%;
    padding: 0px 2px 2px 2px;
  }

  .spanner3 {
    color: white;
    text-transform: uppercase;
  }
  .avater1 {
    background: #2dce6e;
  }

  .rowing1 {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
  }

  .divider22 {
    background: ${(props) => props.theme.background.boxes};
    border-radius: 7px;
    width: 100%;
    // height: 147px;
    color: white;
    padding: 40px;
    margin-top: 30px;
    border: 0.6px solid #ffffff33;
  }

  .htp5 {
    padding: 10px 0px;
    margin-left: -2px;
    font-size: 17px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  .spaning {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: center;

    svg {
      margin-right: 15px;
    }
    .paymentType {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-align: center;
      padding: 4px 10px;

      background: #2d3748;
      border: 1px solid #2d3748;
      border-radius: 6px;
      margin-left: 32px;
    }
  }

  .spaning1 {
    margin-left: 5px;
  }

  .spanning {
    color: ${(props) => props.theme.colors.primary};
    margin-left: -5px;
  }

  .spanning1 {
    color: ${(props) => props.theme.colors.primary};
    margin-left: -5px;
  }

  .spanner23 {
    background: rgb(0, 122, 255, 0.2);
    color: #007aff;
    border: 1px solid #007aff;
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
  }

  .divider24 {
    background: ${(props) => props.theme.background.boxes};
    border-radius: 7px;
    width: 100%;
    // height: 147px;
    color: ${(props) => props.theme.colors.primary};
    padding: 10px 30px 20px 30px;
    margin-top: 30px;
  }

  .per-month {
    margin-left: 5px;
    font-size: 13px;
    color: ${(props) => props.theme.colors.primary};
  }

  .po5 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    padding: 25px 0px;
  }

  .ho2 {
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    margin-bottom: 20px;
  }
  .hoo2 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    padding-bottom: 30px;
  }
  .tabs {
    color: #6c6c6c;
    padding-bottom: 40px;
  }
  .diver23 {
    width: 100%;
  }
  .htp2 {
    color: ${(props) => props.theme.colors.primary} !important;
    font-weight: bold;
    // padding-bottom: 30px;
  }

  .btnss1 {
    background: rgb(0, 122, 255, 0.2);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #007aff;
    cursor: pointer;
  }

  .divider3 {
    display: flex;
    justify-content: center;
    padding: 46px 0px 46px 0px;
  }
  .divider3 .hr-90 {
    width: 100%;
  }
  .divide {
  }
  .ppo {
    padding: 16px 0px 8px;
  }
  .images45 {
    height: 164px;
    width: 100%;
  }

  .avatar {
    border: 2px solid black;
    margin-top: -30px;
  }

  .divider1 {
    display: flex;
    justify-content: center;
    padding: 0px 0px 52px 0px;
  }

  .hr-90 {
    border: 1px solid ${(props) => props.theme.background.hrBorder};
    background: #262626;
    width: 80%;
  }
  .pop1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    padding: 20px 0px 0px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    position: relative;
    align-items: center;
  }
  .po3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    padding: 20px 0px 0px;
    display: flex;
    // flexDirection: row;
    // flexFlow: wrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .po4 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .spann {
    margin-left: 5px;
  }
  .divs2 {
    display: flex;
    justify-content: center;
    padding: 53px 0px 32px 0px;
  }

  .h5 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.primary};
    text-transform: capitalize;
    font-weight: bold;
  }

  .h2 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    padding-bottom: 30px;
  }

  .general-section {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
  }

  .input-wrapper {
    display: grid;
    gap: 20px;
    padding-top: 30px;
  }

  .ant-tabs-tab {
    font-family: "Outfit", sans-serif !important;
    font-size: 15px;
    padding: 15px 20px;
  }

  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #6c6c6c;
  }

  h2 {
    font-size: 19px;
    letter-spacing: 1px;
  }

  h5 {
    letter-spacing: 1px;
  }

  .profile {
    width: 23%;
    // background-image: url("abstract.png");
    background: ${(props) => props.theme.background.profile};
    margin-top: 2%;
    border-radius: 10px;
    padding-bottom: 32px;

    border: 1px solid #ffffff4d;
    display: table-cell;
    position: relative;
  }
  .logoutButton {
    position: absolute;
    bottom: 32px;
    width: 100%;
  }

  .profile1 {
    width: 75%;
    // background-image: url("abstract.png");
    background: ${(props) => props.theme.background.profile};
    margin-top: 2%;
    border-radius: 10px;
    padding: 40px;
    border: 1px solid #ffffff4d;
    display: table-cell;
  }

  .profile2 {
    width: 100%;
    // background-image: url("abstract.png");
    background: ${(props) => props.theme.background.profile};
    margin-top: 2%;
    border-radius: 20px;
    border: 1px solid #ffffff4d;
    padding: 40px;
  }

  .ant-table-thead {
    display: none;
  }

  .ant-table-tbody,
  .ant-table-cell {
    background: ${(props) => props.theme.background.boxes} !important ;
    color: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-table-cell-row-hover {
    background: ${(props) => props.theme.background.boxes} !important ;
    color: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid #2b2b2b;
  }
  .ant-table-wrapper:after,
  .ant-table-wrapper:before {
    display: none;
  }
  .rowing {
    display: flex;
  }

  .row-space {
    display: flex;
    justify-content: space-between;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .btn1 {
    border: 1px solid ${(props) => props.theme.colors.hrBorder} !important;
    color: ${(props) => props.theme.colors.primary};
    background: transparent;
    padding: 10px 50px;
    width: 80%;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
  }
  .div {
    background: ${(props) => props.theme.background.boxes};
    border-radius: 16px;
    width: 213px;
    height: 147px;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.6px solid #ffffff33;
    margin-right: 29px;
    margin-bottom: 29px !important;
  }
  .svgDiv {
    background: #505050;
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff33;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spanner {
    font-size: 16px;
  }

  .row-space-profile {
    flex-direction: row;
  }

  .row-profile-FormStyle {
    margin-left: 0%;
    padding: 0px 26px;
  }

  .po5 {
    padding: 24px 0px 24px 0px;
    margin-top: 0%;
    margin-left: 0%;
  }

  .fontsizespanpo4 {
    font-size: inherit;
    white-space: nowrap;
  }
  .po4 .spann.fontsizespanpo4,
  .pop1 .spanner.fontsizespanpo4 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
    overflow: hidden;
    width: calc(100% - 118px);
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .div {
    margin-bottom: 0%;
  }

  .div1 {
    margin-bottom: 0%;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: ${(props) => props.theme.background.primary} !important;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-empty-description {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-modal-close {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-table-cell-row-hover,
  .ant-table-cell-row-hover .moreOutline {
    color: white;
  }

  .GeneralInformationFormStyleclassName {
    padding: 40px 10px 0px 10px;
    margin-bottom: 0%;
  }

  .rowing {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .rowing2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .rowing2 .div {
  }

  .account-span {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
  }

  .paid {
    width: 80px;
  }

  .InvoiceHistory {
    margin-left: 0%;
  }

  .BillingInfoFontSize {
    font-size: inherit;
    color: ${(props) => props.theme.colors.primary};
  }

  .Profile-View-Span {
    width: 80px;
    height: 80px;
    line-height: 65px;
    font-size: 32.5px;
  }

  .ant-table-wrapper {
    background: transparent !important;
  }
  .ant-tabs-tab-btn {
    font-family: Outfit !important !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
  .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
    display: table;
  }
  .ant-tabs-tabpane {
    border-spacing: 20px 26px;
    width: 100%;
  }
  .ant-tabs-tabpane .row-space-profile {
    display: table-row;
  }
  .ant-tabs-nav {
    padding: 0px 20px;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .profile2 .diver2 {
    width: 100%;
  }

  .profile2 .accountDetailBox {
    margin: 0px;
    position: relative;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 33px;
    width: calc(50% - 20px);
    margin-bottom:10px
  }
  .profile2 .accountDetailBox {
    background: #303036;
    border: 0.6px solid #ffffff33;
    margin-bottom:10px

  }
  .accountDetailBoxDisabledLayer {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: calc(100% - 0px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    border-radius: 16px;
    color: white;
    font-size: 19px;
    background-color: #00000075;
  }
  .accountDetailBox .nameImage {
    display: flex;
    align-items: center;
  }
  .accountDetailBox .nameImage .diver4 {
    margin-right: 20px;
  }
  .profile2 .accountDetailBoxTransparrent {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile2 .accountDetailBoxTransparrent.blurrr {
    filter: blur(1px);
    cursor: not-allowed;
    button{
    cursor: not-allowed !important; 
    }
  }
  .planMainDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .planDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background: #ffffff33;
    border-radius: 16px;
    overflow: hidden;
    border: 0.6px solid #ffffff33;

    max-width: 677px;
    max-width: 677px;
    width: 100%;
    margin-bottom: 20px;
  }
  .planDiv .planName {
    padding: 16px 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .planDiv .planName .name {
    background: #007aff;
    border: 0.73px solid #d1d1d1;
    padding: 8px 24.86px;
    font-family: Public Sans !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
  }
  .planDiv .planName img {
    width: 60.33px;
  }
  .planDiv .planDetail {
    width: 100%;
    background: #242429;
    padding: 32px;
  }
  .planDiv .planDetail h2 {
    font-family: Public Sans !important;
    font-size: 40px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 24.86px;
  }
  .planDiv .planDetail .perMonth {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }
  .planDiv .planDetail .includes {
    margin-top: 26px;
  }
  .planDiv .includeHeading {
    font-family: Public Sans !important;
    font-size: 17px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 22.71px;
  }
  .planDiv .includesDiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .planDiv .includeDetail {
    width: 50%;
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    display: flex;
    align-items: center;

    margin-bottom: 15px;
  }
  .planDiv .includeDetail svg {
    margin-right: 13.63px;
  }
  .planDiv .includes .seeDetails {
    display: flex;
    color: #007aff;
    font-family: Public Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 13.27px;
  }
  .planExpireDate {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .innerPlanExpireDate {
    width: 405px;
  }
  .planExpireDate p {
    font-family: Public Sans !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 32px;
  }
  .planExpireDate h1 {
    font-family: Public Sans;
    font-size: 60px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.02em;
    text-align: left;
    color: white;
    span {
      font-family: Public Sans;
      font-size: 40px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0.02em;
      text-align: left;
      color: white;
    }
  }
  .planExpireDate hr {
    margin: 32px 0px;
  }
  .expireButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      cursor: pointer;
    }
  }
  .expireButtons .upgradePlans {
    background: #007aff;
    color: white;
    height: 48px;
    width: 168.5px;

    border-radius: 8px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .expireButtons .upgradePlans svg {
    margin-right: 8px;
  }
  .expireButtons .CancelPlans {
    border: 1px solid #ffffff33;
    background-color: #ffffff33;
    color: white;
    height: 48px;
    width: 188.5px;

    border-radius: 8px;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      margin-right:5px;
    }
  }
  @media screen and (max-width: 800px) {
    .row-space-profile {
      display: flex !important;
      flex-direction: column !important;
      width: 100%;
    }
    .profile,
    .profile1 {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    .GeneralInformationFormStyleclassName {
      padding: 100px 0px 0px 0px;
      margin-bottom: 65%;
    }
    .logoutButton {
      position: unset;
    }

    .po5 {
      padding: 10px 0px 15px 0px;
      font-size: 14px;
    }

    .Profile-View-Span {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 26px;
      margin-top: -25px;
    }
    .row-profile-FormStyle {
      justify-content: center;
      margin-left: 5%;
    }

    .btn1 {
      padding: 7px 10px;
      max-width: 170px;
      width: 100%;
      justify-content: center;
    }

    .row-space-profile {
      flex-direction: column;
    }

    .profile {
      width: 100%;
    }

    .profile1 {
      width: 100%;
      padding: 40px 24px;
    }

    .fontsizespanpo4 {
      font-size: 12px;
    }

    .hr-90 {
      width: 90%;
    }

    .po4 {
      padding: 0px 0px 0px 0px;
    }

    .divider1 {
      padding: 0px 0px 10px 0px;
    }

    .divs2 {
      padding: 10px 0px 10px 0px;
    }

    .div {
      width: 45%;
      margin: 0px;
    }

    .div1 {
      width: 45%;
      margin-bottom: 8%;
    }

    .rowing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .rowing2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .diver3 {
      width: 100%;
      height: 120px;
    }

    .diver4 {
    }

    .ppp1 {
      padding: 10px 0px;
      font-size: 14px;
    }

    .btnns1 {
      padding: 8px 15px;
    }

    .btnns1 {
      padding: 8px 14px;
    }

    .account-span {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }

    .btnns {
      padding: 8px 12px;
    }

    .divers1 {
      width: 100%;
      height: 120px;
    }

    .ant-tabs-tab {
      padding: 10px 10px;
    }

    .htp5 {
      font-size: 15px;
    }

    .divider22 {
      padding: 10px 10px 0px 10px;
    }

    .ant-table-cell {
      font-size: 11px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }

    .ant-table-tbody > tr > td {
      padding: 7px;
    }

    .paid {
      width: 70px;
    }

    .InvoiceHistory {
      margin-left: 5%;
    }

    .BillingInfoFontSize {
      font-size: 13px;
    }

    .btnss1 {
      padding: 6px 16px;
      margin-top: 20px;
    }

    .btn1 {
      width: 60% !important;
      justify-content: center;
    }

    .GeneralInformationFormStyleclassName {
      margin-bottom: 80%;
    }

    .ant-tabs-tab {
      padding: 10px 0px;
    }

    .profile2 .accountDetailBox {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .div {
      width: 100%;
      margin: 0px;
    }

    .div1 {
      width: 100%;
      margin-bottom: 8%;
    }

    .rowing {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .rowing2 {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
      display: flex;
    }
    .planDiv .includeDetail {
      width: 100%;
    }
    .profile2 {
      padding: 20px;
    }
    .planDiv .planDetail {
      padding: 22px;
    }
    .planExpireDate {
      margin-top: 20px;
      width: 100%;
    }
    .expireButtons .upgradePlans,
    .expireButtons .CancelPlans {
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 1366px) {
    .spanner {
      font-size: 14px;
    }
    .btn1 {
      color: ${(props) => props.theme.colors.primary};
      background: transparent;
      padding: 10px 25px;
      width: 80%;
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
    }
    .div {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 5px;
      width: 213px;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .div1 {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 5px;
      width: 28%;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5%;
    }
  }
  @media only screen and (min-width: 1440px) {
    .spanner {
      font-size: 16px;
    }
    .btn1 {
      color: ${(props) => props.theme.colors.primary};
      background: transparent;
      padding: 10px 30px;
      width: 80%;
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
    }
    .div {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 16px;
      width: 213px;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .div1 {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 5px;
      width: 27%;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5%;
    }
  }
  @media only screen and (min-width: 1920px) {
    .spanner {
      font-size: 16px;
    }
    .btn1 {
      color: ${(props) => props.theme.colors.primary};
      background: transparent;
      padding: 10px 70px;
      width: 80%;
      border-radius: 5px;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
    }
    .div {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 16px;
      width: 213px;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .div1 {
      background: ${(props) => props.theme.background.boxes};
      border-radius: 5px;
      width: 27%;
      height: 147px;
      color: ${(props) => props.theme.colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5%;
    }
  }
`;
export { GeneralInformationFormStyle };
