import styled from "styled-components";

export const SwitchStyle = styled.div`
.platformSelect{
    display:flex;
    justify-content:space-between;
    align-items:center;

    height: 36px;
    width: 167.33px !important;
    border-radius: 8px;
    background: ${(props) => props.theme.background.primary5};
    border: 0.6px solid #FFFFFF33;
    color: ${(props) => props.theme.colors.primary} !important;
    width: 100%;
    font-size: 12px;
    padding: 9.5px 16px
  }
`