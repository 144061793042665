import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { message } from "antd";
import { Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { InputBox, SelectBox } from "..";
import { PlusIcon } from "../../assets/index";
// import { SPOTIFY_REFRESH_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { FilledButtonStyle } from "../../styles/common.style";
import {
  editSongToPlaylist
} from "../../utils";
import { DatePicker } from "../DatePicker";
import {
  EditToPlayListModalStyle,
  EditToPlayListStyle,
} from "./EditToFuturePlayListModal.style";
interface EditToPlayListModalProps {
  setSongUri?: String;
  setStartTime?: Date;
  setEndTime?: String;
  setPlaylist?: String;
  setPosition?: String;
  setPlayId?: Object;
}

const EditToFuturePlayListModal = (props: EditToPlayListModalProps) => {
  const {
    setSongUri,
    setStartTime,
    setEndTime,
    setPlaylist,
    setPosition,
    setPlayId,
  } = props;
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [setLoading, showLoading] = useState(false);

  const { state } = usePlaylistContext();
  const { state: loginState} = useLoginContext();

  const handleOk = () => {
    setEditModalVisible(false);
  };

  const showModal1 = () => {
    setEditModalVisible(true);
  };


  const handleCancel = (e: any) => {
    e.stopPropagation();
    setEditModalVisible(false);
  };

  const [setDate, showDate] = useState(new Date());
  const setButtom = "";

  const onHandle = (date, dateString) => {
    // console.log(moment(date).format("YYYY-MM-DD"));
    showDate(date);
    // showButtom("");
  };

  const handleFormSubmit = (values: any) => {
    showLoading(true);
    const currentPlaylist = state.playlists.find(
      (item) => item.id === values.playlistId
    );
    if (
      values.playlistId !== "" &&
      values.searchedSongData !== "" &&
      values.searchedSongData !== undefined
    ) {
      editSongToPlaylist(
        values.playlistId,
        values.playlistId,
        values.searchedSongData,
        loginState.token,
        loginState.refreshToken,
        loginState.userId,
        values.position,
        setDate ? setDate : setStartTime,
        values.endTime,
        values.status,
        values.playId
      )
        .then((response) => {
          message.info(
            values.startTime
              ? "Song scheduled successfully"
              : "Song added to playlist"
          );
          showLoading(false);
          setEditModalVisible(false);
          window.location.reload();
        })
        .catch(() => {
          message.error("Error while adding song");
          showLoading(false);
        });
    } else {
      message.error("Please fill all the fields!");
    }
  };

  const getPlaylistItems = () => {
    const playlist = [];

    state.playlists.map((data) => {
      playlist.push({
        key: data.id,
        value: data.name,
      });
    });

    return playlist;
  };


  return (
    <EditToPlayListStyle>
      <span onClick={showModal1} style={{cursor:'pointer'}}>
        <span className="spanner" style={{}}>
          <EditOutlined />
        </span>
      </span>
      <EditToPlayListModalStyle
        visible={isEditModalVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <div>
          <h1 className="heading">Add To Pre-Release</h1>
        </div>
        <Formik
          initialValues={{
            playlistId: `${setPlaylist}`,
            startTime: moment(setStartTime),
            endTime:
              setEndTime === "null"
                ? moment(new Date())
                : moment(`${setEndTime}`),
            position: setPosition !== "0" ? setPosition : "1",
            searchedSongData: setSongUri,
            status: "future",
            playId: setPlayId,
          }}
          onSubmit={(values, { resetForm }) => {
            handleFormSubmit(values);
            resetForm();
          }}
        >
          {() => (
            <Form className="form-wrapper">
              <div className="date">
                <SelectBox
                  name="playlistId"
                  label="Playlists"
                  type="horizontal"
                  options={getPlaylistItems()}
                  placeholder="Select Playlist"
                />

                <InputBox
                  transparent
                  label="Future Song URI"
                  type="text"
                  name="searchedSongData"
                />
              </div>
              <div className="date">
                <span className="DatePickerDatePicker">
                  <DatePicker
                    transparent
                    label="Start Time"
                    name="startTime"
                    defaultValue={moment(setStartTime)}
                    format={"DD-MM-YYYY hh:mm"}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD HH:mm");
                      return (
                        current &&
                        current < moment(customDate, "YYYY-MM-DD HH:mm")
                      );
                    }}
                    onChange={onHandle}
                  />
                </span>
                <span className="DatePickerDatePicker">
                  <DatePicker
                    transparent
                    label="End Time"
                    type="datetime-local"
                    name="endTime"
                    defaultValue={
                      setEndTime === "null" ? "" : moment(`${setEndTime}`)
                    }
                    format={"DD-MM-YYYY hh:mm"}
                    disabledDate={(current) => {
                      let customDate =
                        moment(setDate).format("YYYY-MM-DD hh:mm");
                      // console.log(setDate);
                      return (
                        current &&
                        moment(customDate, "YYYY-MM-DD hh:mm") >
                          moment(current, "YYYY-MM-DD hh:mm")
                      );
                    }}
                  />
                </span>
              </div>

              <InputBox
                required
                type="number"
                name="position"
                label="Position of the song in playlist"
                transparent={true}
                min={1}
              />

              <div className="indexes-status-notes-playId">
                <InputBox type="hidden" name="status" />
                <InputBox type="hidden" name="playId" />
              </div>

              <div className="footer">
                <FilledButtonStyle
                  background="transparent"
                  width="90px"
                  type="button"
                  onClick={handleCancel}
                  className="btn1"
                >
                  Cancel
                </FilledButtonStyle>
                  <FilledButtonStyle type="submit" className="btn2" style={{}}>
                    {setLoading ? <LoadingOutlined /> : <PlusIcon />} Add
                  </FilledButtonStyle>
              </div>
            </Form>
          )}
        </Formik>
      </EditToPlayListModalStyle>
    </EditToPlayListStyle>
  );
};

export default EditToFuturePlayListModal;
