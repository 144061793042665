import { Dropdown, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { PlayListNew } from "../../assets";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import {
  PlaylistStyle
} from "../../pages/Playlist/Playlist.style";
import {
  isEmptyOrNil
} from "../../utils";
import AddToPlayListModalSearch from "../AddToPlayListModal/AddToPlayListModalSearch";
import EditAddToPlayListModal from "../AddToPlayListModal/EditAddToPlayListModal";
import { SearchBar } from "../SearchBar";
import { SideBarAccount } from "../SideBar/SideBarAccount.style";
import SongsTable from "../SongsTable/SearchSongTable";
import DeleteModal from "../Subscriptions/CancelSubscription";
interface SearchSongsTableProps { }

const SearchSongsTable: React.FC<SearchSongsTableProps> = () => {
  const searchPlaylist = window.location.search.includes('playlist') ? window.location.search : '';

  const [isAddModalVisible, setAddModalVisible] = useState(false);
  const [songURI, setSongURI] = useState("");
  const { state: playlistState } =
    usePlaylistContext();
  const { state, dispatch } = usePlaylistContext();
  const { state: loginState } = useLoginContext();

  const [songtext, setsongtext] = useState("");
  let theme = "dark";
  const [page, setPage] = useState(1);
  const clickHandle = (
    url: any,
    id: any,
    image: any,
    name: any,
    artist: any,
    external: any
  ) => {
    if (url !== null) {
      dispatch({
        type: "UPDATE_SONGS_SEARCH_LIST",
        payload: id,
      });
      if (url === "" || url !== null) {
        dispatch({
          type: "SET_PREVIEW_SONG",
          payload: {
            url: url,
            id: id,
            name: name,
            artist: artist,
            image: image,
            external: external,
          },
        });
      }
    } else {

      window.open(`${external}`, "_blank");
    }
  };
  // useEffect(() => { console.log(searchPlaylist, 'searchPlaylist') }, [searchPlaylist])
  const clickHandle1 = (
    url: any,
    id: any,
    image: any,
    name: any,
    artist: any,
    external: any
  ) => {
    if (state.previewSong.url === url) {
      dispatch({
        type: "SET_PREVIEW_SONG",
        payload: {
          url: "",
          id: "",
          name: "",
          artist: "",
          image: "",
          external: "",
        },
      });
    }
  };


  const fetchData = (searchSong, loginState, pagination) => {
    if (isEmptyOrNil(searchSong)) return;
    if (searchSong.includes("https://open.spotify.com/track/")) {
      /**
       * if song is searched by url then it will split "https://open.spotify.com/track/" part
       *  and then get extract the id from the string and then it will search by id"
       */
      searchSong.includes("https://open.spotify.com/track/");
      const slashSplits = searchSong.split("/");
      if (slashSplits?.length) {
        const songId = slashSplits[4].split("?");
        axios(
          `https://api.spotify.com/v1/tracks/${songId[0]}?type=track%2Cartist`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + loginState.token,
            },
          }
        ).then((genreResponse) => {
          //   setSongs([genreResponse.data]);
          //   setDisplaySongCover(false);
          const responseData = genreResponse.data; 
          let data = [
            {
              key: 1,
              id: responseData?.id,
              title: responseData?.name,
              image: responseData?.album.images[0].url,
              artist: responseData?.artists[0].name,
              externalUrl: responseData.external_urls.spotify,
              previewUrl: responseData?.preview_url,
              clickHandle: clickHandle,
              clickHandle1: clickHandle1,
              songUri: responseData?.uri,
              Edit: () => { },
              popularity: responseData?.popularity
            },
          ];
          dispatch({
            type: "SET_SONGS_SEARCH_LIST",
            payload: data,
          });
        });
      }
    } else {
      axios(
        `https://api.spotify.com/v1/search?q=${searchSong}&type=track%2Cartist&limit=${10}&offset=${pagination ? state.searchedSongs.length : 0
        }`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + loginState.token,
          },
        }
      )
        .then((genreResponse) => {
          let data = genreResponse?.data?.tracks?.items.map((data, index) => {
            const url = data?.album?.images?.length
              ? data?.album?.images[0].url
              : null;

            const artist = data?.artists?.length ? data?.artists[0].name : null;
            setPage(page + 1);
            return {
              key: 0,
              id: data.id,
              title: data.name,
              image: url,
              artist: artist,
              previewUrl: data.preview_url,
              clickHandle: clickHandle,
              clickHandle1: clickHandle1,
              songUri: data.uri,
              isPlaying: false,
              externalUrl: data.external_urls.spotify,
              popularity: data.popularity,
              rowNumber: { indexes: (page - 1) * 10 + index + 1 },
              Edit: () => { },
            };
          });
          if (pagination) {
            dispatch({
              type: "SET_SONGS_SEARCH_LIST",
              payload: [...state.searchedSongs, ...data],
            });
          } else {
            dispatch({
              type: "SET_SONGS_SEARCH_LIST",
              payload: data,
            });
          }
        })

        .catch(async (error) => {
          if (
            error.response?.data?.error?.status === 401 ||
            error.response?.status === 400
          ) {
          }
        });
    }
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (state.searchSong !== songtext) {
      setsongtext(state.searchSong);
      fetchData(state.searchSong, loginState, false);
    } else {
      fetchData(state.searchSong, loginState, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchSong, loginState]);

  const [editType, setEditType] = useState("");
  const [editAddToPlaylistModal, setEditAddToPlaylistModal] = useState(false);
  const [selectedSong, setSelectedSong] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

  const showModalDelete = () => {
    setIsModalOpenDelete(true);
  };

  const handleOkDelete = () => {
    setIsModalOpenDelete(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpenDelete(false);
  };
  const menu = (a) => {
    setSelectedSong(a);
    setAddModalVisible(true);
    setSongURI(a.songUri);
  };
  const platForms = [
    {
      name: "Spotify",
      image: './static/images/spotifyLogo.png',
      id: 1,
    },
    {
      name: "Apple Music",
      image: './static/images/appleMusicLogo.png',
      id: 2,
    },
  ];
  const [openPlatForm, setOpenPlatForm] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const [activePlatForms, setActivePlatForms] = useState("Spotify");
  const menuPlatform = (
    <SideBarAccount>
      <div className="menuLinks menuLinksPlatforms">
        {platForms.map((a, i) => (
          <div
            className={`menuDiv ${a.name === activePlatForms ? "active" : ""}`}
            onClick={() => {
              setActivePlatForms(a.name);
            }}
          >
            <div className="imageName">
              <img alt={a.name} src={a.image} />
              {a.name}
            </div>
            <svg
              className="ticIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75 8.75L6.25 12.25L13.25 4.75"
                stroke="#56BA32"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        ))}
      </div>
    </SideBarAccount>
  );
  return (
    <div style={{ marginTop: "0%" }}>
      <div className="searchBarBrowse">
        <SearchBar />
        <Dropdown
          overlay={menuPlatform}
          trigger={["click"]}
          className="switchAccount"
          onOpenChange={() => {
            setOpenPlatForm(!openPlatForm);
            setBlurBackground(!blurBackground);
          }}
        >
          <span onClick={(e) => e.preventDefault()} style={{ cursor: "pointer" }}>
            <Space>
              <div className="platformSelect">
                <p>{activePlatForms}</p>
                <svg
                  style={
                    openPlatForm
                      ? { transform: "rotate(0deg)" }
                      : { transform: "rotate(180deg)" }
                  }
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3331 7.33337L6.66646 0.666708L-0.000202656 7.33338L13.3331 7.33337Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Space>
          </span>
        </Dropdown>
      </div>
      {isEmptyOrNil(state.searchedSongs) ? (
        null
      ) : (
        <PlaylistStyle>
          <div className="searchContent">
            <div className="searchDetails">
              <div className="innerSearchDetails">
                <div className="platform">
                  <img alt="Spotify" src={'./static/images/spotifyLogo.png'} /> Spotify
                </div>
                <div className="row1">
                  {theme === "dark" ? (
                    <PlayListNew color={"white"} />
                  ) : (
                    <PlayListNew color={"#1D1D1D"} />
                  )}
                  <p className="p1">
                    Total songs:{" "}
                    <span>
                      {state.searchedSongs.length < 10
                        ? "0" + state.searchedSongs.length
                        : state.searchedSongs.length}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <InfiniteScroll
              pageStart={1}
              loadMore={() => {
                fetchData(state.searchSong, loginState, true);
              }}
              hasMore={state.searchedSongs.length < 9 ? false : true}
              loader={
                <div className="load-wrapp">
                  <div className="load-1">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              }
            >
              <SongsTable
                state={state.searchedSongs}
                playlistState={playlistState}
                menu={menu}
                HideAddNote={true}
                HideDates={true}
                hideMenu={searchPlaylist}
                spotifyTrue={true}
                fromBrowse={true}
              />
            </InfiniteScroll>
          </div>
        </PlaylistStyle>
      )}
      <AddToPlayListModalSearch
        isModalVisible={isAddModalVisible}
        setIsModalVisible={setAddModalVisible}
        songURI={songURI}
        setSongURI={setSongURI}
        hideRadioButtons={false}
        hideUriInput={true}
        dataSearched={selectedSong}
      />
      <EditAddToPlayListModal
        isModalVisible={editAddToPlaylistModal}
        setIsModalVisible={setEditAddToPlaylistModal}
        editType={editType}
        setEditType={setEditType}
      />
      <DeleteModal
        handleCancel={handleCancelDelete}
        showModal={showModalDelete}
        isModalOpen={isModalOpenDelete}
        handleOk={handleOkDelete}
        heading={"Delete from playlist"}
        headingText={"Are you sure you want to delete this song?"}
        button1={"Delete"}
        button2={"Cancel"}
      />
    </div>
  );
};

export default SearchSongsTable;
