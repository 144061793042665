import styled from "styled-components";
import { SlimScrollStyle } from "../../styles/common.style";

const SideBarStyle = styled.div`
  max-height: calc(100vh - 170px);
  min-height: calc(100vh - 170px);
  overflow-y:auto;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: 0.11fr 1fr 0fr;
  grid-gap: 20px;
  padding: 0px 10px;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  .playlist-items {
    overflow: auto;
    min-height: 140px;
    max-height: calc(100vh - 600px);

    ${SlimScrollStyle};
  }
  .avater1 {
    background: #2dce6e;
    margin-right: 8px;
  }
  .spanner3 {
    color: white;
    text-transform: uppercase;
  }
  .playlist-items::-webkit-scrollbar {
    display: none;
  }
  .profilePicImage{
    border-radius: 100%;
  }
.div5{
  padding-bottom:0px;
}
  .rows {
    padding: 0px 20px;
    justify-content: space-between;
  }
  .padder {
    padding: 0px 20px 5px;
  }
  .rowing {
    display: flex;
    justify-content: center;
  }
  .hr-90 {
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .hr-100 {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .padder {
    padding: 0px 20px 0px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    opacity: 0.5;
  }
  .plusPlaylist{
    cursor:pointer;
  }
  .pre-release {
    padding-bottom: 8px;
    margin-left: 10px;
    color: ${(props) => props.theme.colors.primary1};
  }
  .actions {
    color: ${(props) => props.theme.colors.primary};
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    padding-left: 5px;
    // margin-bottom: 10px;
  }
  @media only screen and (max-width: 1500px) {
    .playlist-items {
      overflow: auto;
      // max-height: calc(100vh - 550px);
      ${SlimScrollStyle};
    }
    
  } 
  @media only screen and (min-width: 1500px) {
    .playlist-items {
      overflow: auto;
      // max-height: calc(100vh - 670px);
      ${SlimScrollStyle};
    }
    
  }
  @media only screen and (min-width: 1440px) {
    .playlist-items {
      overflow: auto;
      // max-height: calc(100vh - 670px);
      ${SlimScrollStyle};
    }
   
  }
  @media only screen and (min-width: 1536px) {
    .playlist-items {
      overflow: auto;
      // max-height: calc(100vh - 670px);
      ${SlimScrollStyle};
    }
  
  }
  @media only screen and (min-width: 1920px) {
    .playlist-items {
      overflow: auto;
      // max-height: calc(100vh - 670px);
      ${SlimScrollStyle};
    }
 
  }
  
  @media only screen and (max-width: 3000px) {
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  }
  @media screen and (max-width: 768px) {
    max-height: 100vh;
    display:block;
  }
  .playlist-items1 {
    width: 210px;
    
  }

  .logo-image {
    padding: 15px 15px 10px;
    max-width: 150px;
  }
  #logo-image1 {
    padding: 10px 15px;
    max-width: 160px;
  }


  .tab-wrapper {
    display: flex;
    flex-direction: column;
    position:relative;
    gap: 5px;
    margin-bottom:5px;
  }

  .playlist-wrapper {
    display: flex;
    flex-direction: column;
  }

  .paragraphing {
    color: ${(props) => props.theme.colors.primary1};
    padding: 9px 20px 2px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    border-left: 3px solid transparent;
    &.active{
      border-radius: 8px;
      background: #007aff;
      cursor: pointer;
      color: ${(props) => props.theme.colors.secondary} !important;
    }
  }

  .paragraphing:hover {
    border-radius: 8px;
    background: #007aff;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
  }
  .paragraphing.active .pre-release {
    color: ${(props) => props.theme.colors.secondary};
  }
  .paragraphing:hover .pre-release {
    color: ${(props) => props.theme.colors.secondary};
  }

  .skeleton-button {
    height: 20px;
    width: 150px;
  }
  .skeleton-button1 {
    height: 20px;
    width: 180px;
    margin-top: 15px;
  }
  .divs {
    padding: 0px 10px;
  }
  .skeleton-button2 {
    height: 20px;
    width: 165px;
    margin-top: 20px;
  }
  .avatar-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0px 14px 0px;
  }
  .avatar-row p {
    margin-left: -20px;
  }
  .create-playlist {
       width: 238px;
    height: 32px;
    padding: 0px 32px;
    border-radius: 6px;
    background: rgb(0, 122, 255);
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0px 0px 24px;
    position: relative;
  }
  .view-all {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #007aff;
    text-align: left;
    margin-left: 22px;
    cursor: pointer;
  }
`;
const SideBarTabStyle = styled.div`
  display: flex;
  gap: 10px;
  height: 32px;
  align-items: center;
  padding-left: 20px;
  border-left: 3px solid transparent;

  &.active {
    font-weight: bold;
    border-left: 3px solid #045ed8;
    background: rgb(0, 122, 255, 0.2);
    border-radius: 8px;
    .title {
      color: ${(props) => props.theme.colors.secondary} !important;

      background: transparent;
    }
  }

  .title {
    font-size: 12px;
    // background: ${(props) => props.theme.background.secondary};
    color: ${(props) => props.theme.colors.primary1};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
  }
  :hover .title {
    color: ${(props) => props.theme.colors.secondary} !important;
  }
  :hover {
    border-radius: 8px;
    cursor: pointer;
    background: #007aff;
    color: ${(props) => props.theme.colors.secondary};
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.background.secondary1};
    flex-direction:column;
    justify-content:center;
  height:auto;
  gap:initial;
  padding-left: 0px;
  padding: 5px 20px;
  &.active {
      font-weight: bold;
      border-left: 3px solid #045ed8;
      background: rgb(0, 122, 255, 0.2);
      border-radius: 8px;
      .title {
        color: ${(props) => props.theme.colors.secondary} !important;
  
        background: transparent;
      }
    }
  svg{
    width: 20px;
    height: 20px;
  }
    .title {
      font-size: 11px;
      // background: ${(props) => props.theme.background.secondary};
      color: ${(props) => props.theme.colors.primary1};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 5px;
      margin-left: 0px;
    }
    :hover .title {
      color: ${(props) => props.theme.colors.secondary} !important;
    }
    :hover {
      border-radius: 8px;
      cursor: pointer;
      background: #303036;
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;
export { SideBarStyle, SideBarTabStyle };
