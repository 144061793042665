import { createGlobalStyle } from "styled-components";

const BaseStyle = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;1,500&family=Poppins:wght@100&display=swap');
    html {
        * {
            margin: 0;
            padding: 0;
            font-family: 'Poppins', sans-serif !important;
        }
        body{
            font-family: 'Poppins', sans-serif !important;

        
            #root{
    background: black;
min-height:100%
            }
            
           .App{
min-height:100%

           }
            .ant-popover {
            }
            .ant-table-tbody>tr.ant-table-placeholder:hover>td{
              // background:#222222 !important;
              color:white !important;
            }
            .ant-empty-description{
                color:white !important;
            }
            .ant-modal-close{
                color:white !important;
            }
            
            
              ::-webkit-scrollbar {
                width: 5px !important;
              }
            
              /* Track */
              ::-webkit-scrollbar-track {
                background: transparent !important;
              }
            
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background: #007aff !important;
                border-radius: 8px !important;
              }
            
              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #007aff !important;
              }
              .ant-modal-mask {
                backdrop-filter: blur(5px) !important;
                z-index:10005 !important;
              }
              .ant-modal-wrap{
                z-index:10007 !important;
              }
              .ant-select-dropdown{
                z-index:10009 !important;

              }
              .filterDropDownMenuSelect {
                z-index: 100000 !important
            }
              .ant-picker-dropdown{
                z-index:10009 !important;

              }
              .datePickerFilterPopup{
                z-index: 100000 !important
            }
              .ant-message-notice-content{
                background: rgba(0, 122, 255, 0.12) !important;
                /* Blue */
                border: 2px solid #007AFF !important;
                border-radius: 4px !important;
                color:white !important;
                margin-top:20px !important;
                z-index:1000000000 !important;
                position: relative;
              }
              .ant-popover-inner-content {
    width: 100%;
    background: #202020 !important;
                color: white !important;
               
              }
              .hanker2 {
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .ant-popover-title {
                background: #202020 !important;
                color: white !important;
              }
              .ant-popover-arrow-content{
                --antd-arrow-background-color: #202020 !important;
              }
              #tidio-chat-iframe{
                inset: auto 9px 55px auto !important;
              }
              .ant-picker{
                height:48px;
              }
              .ant-switch{
                border:1px solid white !important;
              }
              .diver{
                // display: grid;
                grid-template-columns: auto 1fr;
                background: linear-gradient(
                    180deg,
                    #404040 -96.96%,
                    #1b1a1a 11.24%,
                    #171717 65%
                  ),
                  #ffffff;
                height: 100%;
                #page-content1 {
                  background: linear-gradient(180deg, #515d83 -63.64%, #000000 89.7%);
                }
                #page-content2 {
                  background: linear-gradient(180deg, #90aea7 -63.64%, #000000 89.7%);
                }
                .page-content {
                }
                // background: linear-gradient(180deg, #020202 0%, rgba(45, 45, 45, 0.95) 100%);
              
                // @media only screen and (min-width: 1366px) {
                //   .page-content{
                //     padding-bottom: 8%;
                //   }
                // }
                // @media only screen and (min-width: 1440px) {
                //   .page-content {
                //     padding-bottom: 8%;
                //   }
                // }
                // @media only screen and (min-width: 1536px) {
                //   .page-content {
                //     padding-bottom: 8%;
                //   }
                // }
                // @media only screen and (min-width: 1920px) {
                //   .page-content {
                //     padding-bottom: 8%;
                //   }
                // }
                @media (max-width: 600px) {
                  display: flex;
                  flex-direction: column;
                  flex-grow: 1;
                }
                .ant-picker-input{
                  font-size:17px !important;
                }
                .ant-select-selector{
                  font-size:17px !important;
                }
              
                .page-content {
                  max-height: 100vh;
                  overflow: auto;
                  flex: 1;
                  width: calc(100vw - 300px);    
@media (max-width: 1500px) {
width: calc(100vw - 270px);  
}
                  @media (max-width: 768px) {
                    padding: 20px 0px;
                  width:100vw;
                    
                  }
                  @media (max-width: 600px) {
                    padding: 20px 0;
                    width:100vw;
                  }
                }
              }
        }
        
        ::-webkit-scrollbar {
            width: 5px !important;
        height:5px !important;

          }
        
          /* Track */
          ::-webkit-scrollbar-track {
            background: transparent !important;
          }
        
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #007aff !important;
            border-radius: 8px !important;

          }
        
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #007aff !important;
          }
    }

    ::-webkit-scrollbar {
        width: 5px !important;
        height:5px !important;

      }
    
      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent !important;
      }
    
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #007aff !important;
        border-radius: 8px !important;
        // max-height: 100% !important;

      }
    
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #007aff !important;
      }

      .ant-picker {
        width: 100% !important;
      }
    
      .ant-picker-panel-container {
        width: 100%;
        margin-left: 0%;
      }

      .DatePickerDatePicker {
        padding-right: 0px;
      }

      .indexes-status-notes-playId {
        display: block;
      }

      .setArtistrow2 {
        display: flex;
        flex-direction: column;
      }

      .ant-alert-success {
        width: auto;
      }

      .ant-alert-info {
        width: auto;
      }

      .ant-alert-warning {
        width: auto;
      }

      .ant-alert-error {
        width: auto;
      }

      .ant-alert-action {
        width: auto;
      }

      .ant-alert-close-icon {
        width: auto;
      } 

      .SideBarStyleclassName1 {
        display: block;
      }
      .SideBarStyleclassName2 {
        display: none;
      }

      .setPlayingMusicCardStyle1 {
        display: block;
      }

      .setPlayingMusicCardStyle2 {
        display: none;
      }

      @media only screen and (max-width: 600px) {

  
        .SideBarStyleclassName2 {
          display: block;
        }
    
        .ant-picker-input>input{
          font-size:17px !important;
        }
        .ant-select .ant-select-selection-item{
          font-size:17px !important;
        }
        .ant-select-item {
          font-size: 17px !important;
        }
        .ant-input{
          font-size:17px !important;
        }
        .ant-picker {
          width: 100% !important;
        }
       
        .DatePickerDatePicker {
          padding-right: 10px;
        }

        .ant-picker-panel-container {
          width: 100%;
          margin-left: 5%;
        }

        .btn1 {
          border: 1px solid white;
          padding: 12px 24px;
          height: 40px;
        }
    
        .btn2 {
          padding: 20px;
          height: 40px;
          font-size: 14px;
        }
        
        .heading {
          font-size: 16px !important;
        }

        .date {
          grid-template-columns: 1fr !important;
        }

        .setArtistrow2 {
          display: flex;
          flex-direction: column-reverse;
        }

        .indexes-status-notes-playId {
          display: none;
        }

        .ant-alert-success {
          width: 100%;
        }

        .ant-alert-info {
          width: 100%;
        }

        .ant-alert-warning {
          width: 100%;
        }

        .ant-alert-error {
          width: 100%;
        }

        .ant-alert-action {
          width: 100%;
        }

        .ant-alert-close-icon {
          width: 100%;
        }

        .title-p2-className {
          font-size: 14px;
        }

        .page-content {
          padding: 0px 0 !important;
        }

        .setPlayingMusicCardStyle1 {
          display: none;
        }

        .setPlayingMusicCardStyle2 {
          display: block;
        }

      }

`;

export default BaseStyle;
