import { head, pathOr, propOr } from "ramda";
import { useEffect, useState } from "react";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { CardsListStyle1 } from "./CardsList.1style";
import { CardsListStyle } from "./NewCardList.style";
import { useNavigate } from "react-router";

type Props = {
  heading: string;
  subheading: string;
  playlistId: string;
  number: any;
};

function NewCardList(props: Props) {
  const { playlistId, number } = props;
  const { state: playlistState } = usePlaylistContext();
  const { state } = useLoginContext();
  const [currentPlaylistData, setCurrentPlaylistData] = useState<any>();
  const [currentPlaylistNo, setCurrentPlaylist] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPlaylistContent = playlistState.playlists.find(
      (item) => item.id === playlistId
    );
    // console.log(currentPlaylistContent);
    setCurrentPlaylistData(currentPlaylistContent);
    setCurrentPlaylist(currentPlaylistContent?.tracks?.total);
  }, [playlistId, state, playlistState]);

  return (
    <CardsListStyle1>
      <CardsListStyle>
        <>
          {currentPlaylistNo !== 0 ? (
            <div
              className="image"
              style={{
                backgroundImage: `url('${propOr(
                  "",
                  "url",
                  head(pathOr("", ["images"], currentPlaylistData))
                )}')`,
                cursor: "grab",
              }}
            >
              <div className="overlay" >
                <div>
                  <h3 className="playlistName" style={{ cursor: "pointer", }} onClick={() => {
                    navigate(`/playlist/${currentPlaylistData.id}`)
                  }}>{currentPlaylistData?.name}</h3>
                  <div className="songName">
                    {`${currentPlaylistNo} songs > 1 albums`}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="image"
              style={{
                backgroundImage: `url('./static/abstract.png')`,
                cursor: "grab",
              }}
            >
              <div className="overlay">
                <div>
                  <h3 className="playlistName" style={{ cursor: "pointer", }} onClick={() => {
                    navigate(`/playlist/${currentPlaylistData.id}`)
                  }}>{currentPlaylistData?.name}</h3>
                  <div className="songName">
                    {`${currentPlaylistNo} songs > 1 albums`}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </CardsListStyle>
    </CardsListStyle1>
  );
}

export default NewCardList;
