import styled from "styled-components";

export const InnerPageLayoutStyle1 = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  background: #000;
  height: 100%;
  overflow: hidden;
  #page-content1 {
    background: ${(props) => props.theme.background.page1};
  }
  #page-content2 {
    background: ${(props) => props.theme.background.page2};
  }
  .userNameSidebar{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    
  width: calc(100% - 100px);
  
}
  .page-content {
    position:relative;
    background: black;
  }
  // background: linear-gradient(180deg, #020202 0%, rgba(45, 45, 45, 0.95) 100%);

  // @media only screen and (min-width: 1366px) {
  //   .page-content{
  //     padding-bottom: 8%;
  //   }
  // }
  // @media only screen and (min-width: 1440px) {
  //   .page-content {
  //     padding-bottom: 8%;
  //   }
  // }
  // @media only screen and (min-width: 1536px) {
  //   .page-content {
  //     padding-bottom: 8%;
  //   }
  // }
  // @media only screen and (min-width: 1920px) {
  //   .page-content {
  //     padding-bottom: 8%;
  //   }
  // }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .page-content {
    flex: 1;
    width: calc(100vw - 300px);    
@media (max-width: 1500px) {
width: calc(100vw - 275px);  
}

    @media (max-width: 768px) {
      padding: 20px 0px;
    width:100vw;
      
    }
  }
`;
