import styled from "styled-components";

export const PlaylistStyle1 = styled.div`
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.primary};
  padding: 15px 15px 15px 15px;
  border: 0.8px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  .ant-dropdown {
    z-index: 100 !important;
  }
  .playListMenu{
    padding: 8px 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    opacity: 0.5;
    cursor: pointer;
  }
  .playListMenu:hover{
  opacity: 1;
  }
  .playListMenuText{
    font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-left: 14px;
  color: white;
  }
  .row4 {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
  .row4:hover {
    background: rgb(196, 196, 196, 0.3);
    border-radius: 8px;
  }
  .ant-pagination-item-active {
    background: #007aff !important;
  }
`;
