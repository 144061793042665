import { useEffect, useState } from "react";
import { PlayListNew } from "../../assets";
import { usePlaylistContext } from "../../context/PlaylistContext";
import PlanToast from "../PlanToast/PlanToast";
import { PlayListBannerStyle } from "./PlayFututreListBanner.style";

interface PlayListBannerProps {
  title?: string;
  totalSongs?: string;
  desc?: string;
  image?: string;
  openNotification?: any;
  setAddToPlaylistModal?: any;
}

const PlayFutureListBanner = (props: PlayListBannerProps) => {
  const { title, totalSongs, desc, setAddToPlaylistModal } = props;
  const { state: playlistState } =
    usePlaylistContext();
  const [theme, setTheme] = useState("dark");

  useEffect(() => {
    setTheme(playlistState?.isWholeTheme === false ? "dark" : "light");
  }, [playlistState]);



  return (
    <PlayListBannerStyle>
      <div className="container">
        <div className="text-container">
          <div className="platform">
            <img alt="spotify" src={'./static/images/spotifyLogo.png'} /> Spotify
          </div>
          <div className="row1">
            {theme === "dark" ? (
              <PlayListNew color={"white"} />
            ) : (
              <PlayListNew color={"#1D1D1D"} />
            )}
            <p className="p1">Playlist</p>
          </div>
          <div className="row-space" style={{}}>
            <div>
              <h1 className="title">{title}</h1>
              <p className="desc">{desc}</p>
            </div>
       
          </div>
          <div className="p2">
            <p>Spotify</p>
            <span className="platForm">-</span>
            <p className="platForm">{totalSongs}</p>
          </div>

          <div className="songPlayListButtons">
            <button className="addSong" onClick={() => {
              setAddToPlaylistModal(true)
            }}>
              <PlanToast  available={['free', 'pro', 'unlimited']} titleHeading={'Add song'}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1955_58822)">
                    <path
                      d="M8.5801 6.46961H13.6001V8.52961H8.5801H8.2801V8.82961V13.8496H6.2201V8.82961V8.52961H5.9201H0.900098V6.46961H5.9201H6.2201V6.16961V1.14961H8.2801V6.16961V6.46961H8.5801Z"
                      fill="white"
                      stroke="#007AFF"
                      stroke-width="0.6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1955_58822">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0.25 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Add song
              </PlanToast>
            </button>

          </div>
        </div>
        <div className="playListPic">
          <img
            className="image imagecontainerdirection"
            src={'./static/images/playListPic.png'}
            alt="playlist thumbnail"
          />
          <div className="upcoming">Upcoming Releases</div>
        </div>
      </div>
    </PlayListBannerStyle>
  );
};

export default PlayFutureListBanner;
