import axios from "axios";
import moment from "moment";
import querystring from "querystring";
import { anyPass, isEmpty, isNil } from "ramda";
import { date, string } from "yup";
import { BASE_URL } from "./constants/api-urls";
import { rearrangeArrayWithLock } from "./utils/utils";
import { message } from "antd";

export const isEmptyOrNil = anyPass([isEmpty, isNil]);

export const deleteAllRefresh = async (playlistId: string) => {
  const response = await axios.delete(
    `${BASE_URL}/deleterefresh/${playlistId}`
  );
  return response;
};

export const addSongToPlaylist = async (
  playlistId: string[],
  songUri: string,
  token: string,
  refreshToken: string,
  userId = "",
  songPosition = 0,
  startTime = null,
  endTime = null,
  timing: string,
  notes: string,
  type?: string,
  artist_name?: string,
  song_name?: string,

) => {
  try {


    if (startTime || endTime) {
      const renderedStartTime =
        startTime && startTime !== "null"
          ? new Date(startTime)?.toISOString()
          : null;
      const renderedEndTime =
        endTime && endTime !== "null" ? new Date(endTime)?.toISOString() : null;
      const config = {
        headers: {
          "Content-Type": "application-json",
        },
        data: { notes, artist_name, song_name },
      };

      const addSongToPlaylistsRequests = playlistId.map((singlePlaylist) => {
        axios.post(
          `${BASE_URL}/createRequest/${startTime ? "wait" : "started"
          }/${userId}/${refreshToken}/${singlePlaylist}/${songUri}/${songPosition === 0 ? songPosition : songPosition - 1
          }/${renderedStartTime}/${renderedEndTime}/${timing}/${moment()}`,
          config
        );
      });

      const responseData = await Promise.all(addSongToPlaylistsRequests);
      if (startTime) {
        return responseData.map((response) => response);
      }
    }

    const addSongs = playlistId.map((singlePlaylist) => {
      axios(
        `https://api.spotify.com/v1/playlists/${singlePlaylist}/tracks?position=${songPosition === 0 ? songPosition : songPosition - 1
        }&uris=${songUri}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(async (repsosive) => {
        const renderedStartTime = new Date();
        const renderedEndTime = endTime ? new Date(endTime)?.toISOString() : null;
        const config = {
          headers: {
            "Content-Type": "application-json",
          },
          data: { notes },
        };
        if (!endTime) {
          const responsees = axios.post(
            `${BASE_URL}/createRequest/${startTime ? "wait" : "started"
            }/${userId}/${refreshToken}/${singlePlaylist}/${songUri}/${songPosition === 0 ? songPosition : songPosition - 1
            }/${renderedStartTime}/${renderedEndTime}/${timing}/${moment()}`,
            config
          );
          return responsees;
        }
      });
    });
    const responseData = await Promise.all(addSongs);
    return responseData;
  } catch (err) {
    //console.log(err)
  }
};

export const addRestoredSongToPlaylist = async (
  playlistId: string[],
  songUri: string,
  token: string,
  refreshToken: string,
  userId = "",
  songPosition = 0,
  startTime = null,
  endTime = null,
  timing: string,
  notes: string,
  type?: string
) => {
  if (startTime || endTime) {
    const renderedStartTime =
      startTime && startTime !== "null"
        ? new Date(startTime)?.toISOString()
        : null;
    const renderedEndTime =
      endTime && endTime !== "null" ? new Date(endTime)?.toISOString() : null;
    const config = {
      headers: {
        "Content-Type": "application-json",
      },
      data: { notes },
    };

    // const addSongToPlaylistsRequests = playlistId.map((singlePlaylist) => {

    //   axios.post(
    //     `${BASE_URL}/createRequest/${startTime ? "wait" : "started"
    //     }/${userId}/${refreshToken}/${singlePlaylist}/${songUri}/${songPosition
    //     }/${renderedStartTime}/${renderedEndTime}/${timing}/${moment()}`,

    //     config
    //   );
    // });

    // const responseData = await Promise.all(addSongToPlaylistsRequests);
    // if (startTime) {
    //   return responseData.map((response) => response);
    // }
  }

  const addSongs = playlistId.map((singlePlaylist) => {
    axios(
      `https://api.spotify.com/v1/playlists/${singlePlaylist}/tracks?position=${songPosition}&uris=${songUri}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (repsosive) => {
      const renderedStartTime = new Date();
      const renderedEndTime = endTime ? new Date(endTime)?.toISOString() : null;
      const config = {
        headers: {
          "Content-Type": "application-json",
        },
        data: { notes },
      };
      if (!endTime) {
        const responsees = axios.post(
          `${BASE_URL}/createRequest/started/${userId}/${refreshToken}/${singlePlaylist}/${songUri}/${songPosition}/${renderedStartTime}/${renderedEndTime}/${timing}/${moment()}`,
          config
        );
        return responsees;
      }
    });
  });
  const responseData = await Promise.all(addSongs);
  return responseData;
};
// https://api.spotify.com/v1/playlists/1uXsyBrEMAigZsV84q5Cnr/tracks?position=1&uris=spotify:track:5TXDeTFVRVY7Cvt0Dw4vWW
// https://api.spotify.com/v1/playlists/1uXsyBrEMAigZsV84q5Cnr/tracks?position=0&uris=spotify:track:5TXDeTFVRVY7Cvt0Dw4vWW

export const getPlaylistItemsSpotify = async (playlistId, token) => {
  const response = await axios(
    `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(async (response) => {
    return response.data;
  });
  return response;
};

export const removeSongToPlaylist = async (
  playlistId: string[],
  songUri: string,
  token: string,
  refreshToken: string,
  userId = "",
  songPosition = 0,
  startTime = null,
  endTime = null,
  timing = string
) => {
  const addSongs = playlistId.map((singlePlaylist) => {
    axios(
      `https://api.spotify.com/v1/playlists/${singlePlaylist}/tracks?position=${songPosition === 0 ? songPosition : songPosition - 1
      }&uris=${songUri}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (repsosive) => { });
  });
  const responseData = await Promise.all(addSongs);
  return responseData;
};
export const editSongToPlaylist = async (
  oldPlaylistId: string,
  playlistId: string,
  songUri: string,
  token: string,
  refreshToken: string,
  userId = "",
  songPosition = 0,
  startTime = null,
  endTime = null,
  timing: string,
  playId = null,
  indexes = null,
  notes = null,
  currentposition = 0
) => {
  const renderedStartTime =
    startTime && startTime !== "null" ? moment(startTime) : null;
  const renderedEndTime =
    endTime && endTime !== "null" ? new Date(endTime)?.toISOString() : null;

  // const renderedStartTime = startTime && startTime !== "null" ? typeof startTime === 'object' ?
  //   new Date(moment(startTime).format("Y YYY-MM-DD"))?.toISOString() :
  //   typeof startTime === 'string' ? new Date(startTime)?.toISOString()
  //     : null : null;

  // const renderedEndTime =
  //   endTime && endTime !== "null" ?
  //     typeof endTime === 'object' ?
  //       new Date(moment(endTime).format("YYYY-MM-DD"))?.toISOString()
  //       :
  //       new Date(endTime)?.toISOString()
  //     :
  //     null;
  const config = {
    indexes: indexes === 0 ? indexes : indexes - 1,
    notes: notes,
    headers: {
      "Content-Type": "application-json",
    },
  };

  const response = await axios
    .post(
      `${BASE_URL}/editRequest/${startTime ? "wait" : "started"
      }/${userId}/${refreshToken}/${playlistId}/${songUri}/${!songPosition ? 0 : songPosition === 0 ? songPosition : songPosition - 1
      }/${renderedStartTime}/${renderedEndTime}/${timing}/${playId}/${oldPlaylistId}/${currentposition}`,
      config
    )
    .then()
    .catch((err) => { });

  // `https://songplace-backend-vercel.vercel.app
  // /editRequest
  // /started
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQCS9mCIESu7VigqAzWxHdk_GoUheI7IfyNCHNJJz8K3UEcvAH7pXuEBvJaft-20uvj8NOlYFSTE2jmvoTRJ6tErBfrduHjA6MxOSJUlR7SDr1sfCF139v6flhxccTEuITc
  // /1VeYB4u7YoGO5Ma4U29b0E
  // /spotify:track:3u947zydbp5jVgVNlaRgv4
  // /0
  // /null
  // /null
  // /future
  // /nothing
  // /1VeYB4u7YoGO5Ma4U29b0E`

  // `https://api.songplace.io/editRequest
  // /wait
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQChWHgr9nw5fdft0kSfyk2zBSujkp-XuLqXCwuPCJRSkO3G8k0hohxz9xj9mcichn6wEhn0nVFpZWXr83W2_z7pRZAzpxpHmkyqjOMY1azte8UWHdyO7t_ODntgzDhXVUY
  // /621H2zKMyOYLrXt3bhocc5
  // /spotify:track:74tLlkN3rgVzRqQJgPfink
  // /0
  // /Mon%20Nov%2013%202023%2018:33:55%20GMT+0500
  // /null
  // /past
  // /nothing
  // /621H2zKMyOYLrXt3bhocc5`

  // nahi chl rha h

  // `https://songplace-backend-vercel.vercel.app
  // /editRequest
  // /wait
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQCS9mCIESu7VigqAzWxHdk_GoUheI7IfyNCHNJJz8K3UEcvAH7pXuEBvJaft-20uvj8NOlYFSTE2jmvoTRJ6tErBfrduHjA6MxOSJUlR7SDr1sfCF139v6flhxccTEuITc
  // /1Np92ssUvNBNj3Jle3ff8U // new playlist
  // /spotify:track:72DQsnQrsEwfgTgaqRzahu
  // /0
  // /Sat%20Nov%2018%202023%2017:42:59%20GMT+0500
  // /null
  // /past
  // /nothing
  // /0CNxdua3k7G1fbWz0S7V7I` // old playlist

  //   `https://songplace-backend-vercel.vercel.app
  // /editRequest
  // /wait
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQCS9mCIESu7VigqAzWxHdk_GoUheI7IfyNCHNJJz8K3UEcvAH7pXuEBvJaft-20uvj8NOlYFSTE2jmvoTRJ6tErBfrduHjA6MxOSJUlR7SDr1sfCF139v6flhxccTEuITc
  // /621H2zKMyOYLrXt3bhocc5 // new playlist
  // /spotify:track:72DQsnQrsEwfgTgaqRzahu
  // /0
  // /Sat%20Nov%2018%202023%2017:42:59%20GMT+0500
  // /null
  // /past
  // /nothing
  // /0CNxdua3k7G1fbWz0S7V7I` // old playlist

  // chal rha h
  // `https://songplace-backend-vercel.vercel.app
  // /editRequest
  // /wait
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQCS9mCIESu7VigqAzWxHdk_GoUheI7IfyNCHNJJz8K3UEcvAH7pXuEBvJaft-20uvj8NOlYFSTE2jmvoTRJ6tErBfrduHjA6MxOSJUlR7SDr1sfCF139v6flhxccTEuITc
  // /2W5OMXq0XlXelGh7RklYxV // new playlist
  // /spotify:track:72DQsnQrsEwfgTgaqRzahu
  // /0
  // /Sat%20Nov%2018%202023%2017:54:21%20GMT+0500
  // /null
  // /past
  // /6558b3c11b478afd1e31e3a1
  // /621H2zKMyOYLrXt3bhocc5` //old playlist

  // `https://songplace-backend-vercel.vercel.app
  // /editRequest
  // /wait
  // /31gvlyb5qcq4ujp27fb6rpp3lwxq
  // /AQCS9mCIESu7VigqAzWxHdk_GoUheI7IfyNCHNJJz8K3UEcvAH7pXuEBvJaft-20uvj8NOlYFSTE2jmvoTRJ6tErBfrduHjA6MxOSJUlR7SDr1sfCF139v6flhxccTEuITc
  // /6BWD8Mh3fHxc3BZt6pD21A // new playlist
  // /spotify:track:72DQsnQrsEwfgTgaqRzahu
  // /0
  // /Sat%20Nov%2018%202023%2017:54:21%20GMT+0500
  // /null
  // /past
  // /6558b3c11b478afd1e31e3a1
  // /621H2zKMyOYLrXt3bhocc5` //old playlist

  return response;
};
export const editSongEndDateAndNotes = async (
  oldPlaylistId: string,
  playlistId: string,
  songUri: string,
  token: string,
  refreshToken: string,
  userId = "",
  songPosition = 0,
  startTime = null,
  endTime = null,
  timing: string,
  playId = null,
  indexes = null,
  notes = null,
  currentposition = 0
) => {
  const renderedStartTime =
    startTime && startTime !== "null" ? moment(startTime) : null;

  const renderedEndTime =
    endTime && endTime !== "null" ? new Date(endTime)?.toISOString() : null;

  const config = {
    indexes: indexes === 0 ? indexes : indexes - 1,
    notes: notes,
    headers: {
      "Content-Type": "application-json",
    },
  };
  const response = await axios
    .post(
      `${BASE_URL}/editSongEndDateAndNotes/${startTime ? "wait" : "started"
      }/${userId}/${refreshToken}/${playlistId}/${songUri}/${!songPosition ? 0 : songPosition === 0 ? songPosition : songPosition - 1
      }/${renderedStartTime}/${renderedEndTime}/${timing}/${playId}/${oldPlaylistId}/${currentposition}`,
      config
    )
    .then()
    .catch((err) => { });

  // http://192.168.0.108:5001
  // '/editSongEndDateAndNotes'
  // '/started'
  // '/31gvlyb5qcq4ujp27fb6rpp3lwxq'
  // '/AQDZaB_IWC8K0XYHHbbBhwIRPHyx8fpnPDanIlWCmn3Mmv9azA1u6MLnHSoul2kOVpwjFSuchqERWbY786b-WWdY8ASoI1LliqOTxhhb7G4ExgLYLU0BY6rHYCywUUiHgNo'
  // '/undefined'
  // '/spotify:track:39wFBiVP0psXx8NoN00pP3'
  // '/1'
  // '/null'
  // '/null'
  // '/past'
  // '/nothing'
  // '/undefined'
  // '/1'
  return response;
};
export const getstripePrices = async () => {
  const config = {
    headers: {
      "Content-Type": "application-json",
    },
  };
  const response = await axios.get(`${BASE_URL}/getPrices`, config);
  return response;
};
export const LockSongPosition = async ({
  lock,
  position,
  playlist_id,
  user_id,
  song_uri,
  access_token,
  play_id,
}) => {
  const config = {
    lock,
    position,
    playlist_id,
    user_id,
    song_uri,
    access_token,
    play_id,
    headers: {
      "Content-Type": "application-json",
    },
  };
  const response = await axios
    .post(`${BASE_URL}/LockSongPosition`, config)
    .then()
    .catch((err) => { });

  return response;
};
export const AddOrEditNotes = async (notes: string, id: string) => {
  const config = {
    _id: id,
    notes: notes,
    headers: {
      "Content-Type": "application-json",
    },
  };
  const response = await axios.post(
    `${BASE_URL}/add-notes-pre-release/`,
    config
  );

  return response;
};
export const AddOrEditEndDate = async (endTime: string, id: string) => {
  const renderedEndTime =
    endTime && endTime !== "null" ? new Date(endTime)?.toISOString() : null;
  const config = {
    _id: id,
    end_time: renderedEndTime,
    headers: {
      "Content-Type": "application-json",
    },
  };
  const response = await axios.post(
    `${BASE_URL}/edit-enddate-pre-release/`,
    config
  );

  return response;
};
//Add User Profile
export const addUserProfileAction = async (
  name: string,
  spotifyID: string,
  image: string,
  code: string,
  token: string,
  refresh: string,
  userId: string
) => {
  try {
    const config = {
      username: name,
      image: image,
      code: code,
      token: token,
      userId: userId,
      refresh: refresh,
      spotifyID,
      headers: {
        "Content-Type": "application-json",
      },
    };
    const response = await axios.post(`${BASE_URL}/user/add`, config);

    if (response) {
      setTimeout(() => {
        window.location.replace("/home");
      }, 2000);
    }
  } catch (error) { }
};

export async function getRefreshedToken(refresh_token) {
  const refrechedToken = await axios("https://accounts.spotify.com/api/token", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Basic " +
        new Buffer(
          process.env.REACT_APP_CLIENT_ID +
          ":" +
          process.env.REACT_APP_CLIENT_SECRET
        ).toString("base64"),
    },
    data: querystring.stringify({
      grant_type: "refresh_token",
      refresh_token: `${refresh_token}`,
      client_id: process.env.REACT_APP_CLIENT_ID,
    }),
    method: "POST",
  });
  return refrechedToken;
}

export async function Auth0Logout() {
  const domain = "dev-2z4p074m.us.auth0.com";
  const client_id = process.env.REACT_APP_AUTH0;
  const returnTo = window.location.origin;
  localStorage.removeItem("userInfo");
  window.location.replace(
    `https://${domain}/v2/logout?returnTo=${returnTo}&client_id=${client_id}`
  );
}

export const addItemToStorage = (key: string, item: string) => {
  // if (isRemembered === "true") {
  localStorage.setItem(key, item);
  // } else {
  //   sessionStorage.setItem(key, item);
  // }
};

export const addItemToProfileStorage = (
  key: string,
  itemName: string,
  profile: string,
  code: string
) => {
  // const isRemembered = localStorage.getItem(REMEMBER_ME);
  // const data = {
  //   itemName: any(itemName),
  //   profile: String(profile),
  //   code: String(code),
  // };
  // // //console.log( item?.display_name );
  // if (isRemembered === "true") {
  //   localStorage.setItem(key, data);
  // } else {
  //   sessionStorage.setItem(key, data);
  // }
};

export const getItemFromStorage = (key: string) => {
  // const isRemembered = localStorage.getItem(REMEMBER_ME);

  // if (isRemembered === "true") {
  return localStorage.getItem(key);
  // } else {
  //   return sessionStorage.getItem(key);
  // }
};

// export const getAuth0ItemFromStorage = (key: string) => {
//   const isRemembered = JSON.parse(localStorage.getItem("userInfo"));
// };

export const deleteItemFromStorage = (key: string) => {
  // const isRemembered = localStorage.getItem(REMEMBER_ME);

  // if (isRemembered === "true") {
  return localStorage.removeItem(key);
  // } else {
  //   return sessionStorage.removeItem(key);
  // }
};
// export async function getSinglePageTracks(token, playlistId, pagesize, offset) {
//   let resp = await axios(
//     `https://api.spotify.com/v1/playlists/${playlistId}/tracks?fields=items(added_at%2Cadded_by.id%2Ctrack)&limit=${pagesize}&offset=${
//       offset * 50
//     }`,
//     {
//       method: "GET",
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     }
//   );
//   return resp?.data;
// }
// export async function getAllTracks(playlistId, token, currentPage, playlist) {
//   let offset = 0;
//   let pagesize = 50;
//   let continueloop = true;
//   let tracks = [];

//   while (continueloop) {
//     let next = await getSinglePageTracks(token, playlistId, pagesize, offset);
//     offset++;
//     //console.log(next , tracks)
//     if (next && next?.items.length) {
//       tracks = [...tracks, ...next?.items];
//       const songUris = next?.items.map((song) => {
//         return `${song?.track?.uri}`;
//       });
//       const songsToBeDeleted = next?.items.map((song, index) => {
//         return `{\"uri\":\"${song?.track.uri}\"}`;
//       });
//       deleteAndAddSong(
//         playlistId,
//         token,
//         songsToBeDeleted,
//         currentPage,
//         songUris,
//         playlist
//       );
//     } else {
//       // window.location.reload();

//       continueloop = false;
//     }
//   }
//   return tracks;
// }
// const deleteAndAddSong = (
//   playlistId,
//   token,
//   songsToBeDeleted,
//   currentPage,
//   songUris,
//   playlist
// ) => {
//   axios
//     .delete(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       data: `{\"tracks\":[${songsToBeDeleted}]}`,
//     })
//     .then((delatedSongDetails) => {
//       axios(
//         `https://api.spotify.com/v1/playlists/${playlistId}/tracks?position=${
//           (currentPage - 1) * 50
//         }&uris=${songUris}`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//         .then((res) => {
//           // window.location.reload();
//         })
//         .catch((error) => {
//           //console.log("Bulk Song add Error:", error);
//           deleteAndAddSong(
//             playlistId,
//             token,
//             songsToBeDeleted,
//             currentPage,
//             songUris,
//             playlist
//           );
//         });
//     })
//     .catch((b) => {
//       //console.log(b);
//     });
// };
// export const refreshPlaylist = async ({
//   token,
//   setPlaylist: playlistId,
//   playlist,
//   currentPage,
//   refreshSongs,
//   recall,
// }) => {
//   const allSongs = await getAllTracks(playlistId, token, currentPage, playlist);

//   // const songUris = playlist?.items.map((song) => {
//   //   return `${song?.track?.uri}`;
//   // });
//   // const songsToBeDeleted = playlist?.items.map((song, index) => {
//   //   return `{\"uri\":\"${song?.track.uri}\"}`;
//   // });
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   const formData = {
//     playlist_id: playlistId,
//     date: moment(),
//   };
//   const { data } = await axios.post(
//     `${BASE_URL}/PlaylistRefreshRecord/add`,
//     formData,
//     config
//   );
// };
export async function syncDataFromSpotify({
  token,
  setPlaylist: playlistId,
  scheduledata,
  alldata,
  syncModal,
  setSyncModal,
}) {
  //console.log('syncDataFromSpotify')
  const allSongs = alldata.length
    ? alldata
    : await getAllTracks(token, playlistId);
  let latestdata = allSongs.map((song, index) => {
    let schedule = scheduledata.filter(
      (dataSong) => song?.track?.uri === dataSong?.song_uri
    );
    let scheduleObj = schedule.length
      ? schedule.filter((val) => val.lockPosition)[0]
      : false;
    return scheduleObj && scheduleObj?.lockPosition
      ? {
        ...song,
        lockPositionNumber: scheduleObj?.position,
        lockPosition: index === Number(scheduleObj?.position) ? false : true,
        position: index,
      }
      : { ...song, position: index, lockPosition: false };
  });
  // const songsToBeDeleted = allSongs?.map((song, index) => {
  //   return `{\"uri\":\"${song?.track.uri}\"}`;
  // });
  // await deleteAllTracks(token, playlistId, songsToBeDeleted);
  await rearrange(token, playlistId, latestdata, scheduledata, setSyncModal);
}

export async function rearrange(
  token,
  playlistId,
  allSongs,
  scheduledata,
  setSyncModal
) {
  //console.log('rearrange')
  // const songSubArrays = splitArray(allSongs);

  // //console.log("here:", a[0][0], a[1][0], a[2][0]);
  // const reversedArray = [...songSubArrays].reverse();
  // let arrangesongs = allSongs;
  let arrangesongs = await rearrangeArrayWithLock(allSongs).filter(
    (obj) => obj?.lockPosition
  );
  //console.log(arrangesongs, 'arrangesongsarrangesongs')
  //console.log(arrangesongs, 'arrangesongsarrangesongs')

  arrangesongs?.map((a, i) => {
    let match = allSongs?.filter(obj => obj?.track?.uri === a?.track?.uri)
    if (match.length > 1) {
      message.error("Unable to fix the playlist because it has duplicate songs");
      setTimeout(() => {
        setSyncModal(false)
        window.location.reload()
      }, 2000)
      return
    }
  })

  if (arrangesongs.length) {
    for (let i = 0; i < arrangesongs?.length; i++) {
      if (arrangesongs[i].lockPosition) {
        let smallindexes = arrangesongs.filter(
          (obj) =>
            Number(obj?.lockPositionNumber) > Number(arrangesongs[i].position) &&
            Number(obj?.position) < Number(arrangesongs[i].position)
        ).length;
        // //console.log(
        //   {
        //     range_start:
        //       arrangesongs[i].position >
        //       Number(arrangesongs[i].lockPositionNumber) + 1
        //         ? arrangesongs[i].position
        //         : arrangesongs[i].position ===
        //           Number(arrangesongs[i].lockPositionNumber) + 1
        //         ? arrangesongs[i].position
        //         : arrangesongs[i].position + smallindexes,
        //     insert_before:
        //       arrangesongs[i].position ===
        //       Number(arrangesongs[i].lockPositionNumber) + 1
        //         ? Number(arrangesongs[i].lockPositionNumber)
        //         : Number(arrangesongs[i].lockPositionNumber) + 1,
        //     range_length: 1,
        //     smallindexes,
        //   },
        //   "arange data"
        // );
        axios(
          `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
          // ?&uris=${arrangesongs[i]?.track.uri}`,
          {
            method: "put",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              range_start:
                arrangesongs[i].position >
                  Number(arrangesongs[i].lockPositionNumber) + 1
                  ? arrangesongs[i].position
                  : arrangesongs[i].position ===
                    Number(arrangesongs[i].lockPositionNumber) + 1
                    ? arrangesongs[i].position
                    : arrangesongs[i].position + smallindexes,
              insert_before:
                arrangesongs[i].position ===
                  Number(arrangesongs[i].lockPositionNumber) + 1
                  ? Number(arrangesongs[i].lockPositionNumber)
                  : Number(arrangesongs[i].lockPositionNumber) + 1,
              range_length: 1,
              smallindexes,
            },
          }
        )
          .then(() => {
            // syncDataFromSpotify({
            //   token,
            //   setPlaylist: playlistId,
            //   scheduledata,
            //   alldata: allSongs,
            // });
          })
          .catch((err) => { });
        // return;
      }
      if (arrangesongs.length - 1 === i) {
        await timeout(1000);
        // window.location.reload();
        // window.location.reload();
        // setSyncModal(false);
        syncDataFromSpotify({
          token,
          setPlaylist: playlistId,
          scheduledata,
          alldata: [],
          syncModal: false,
          setSyncModal,
        });
      }
    }
  } else {
    window.location.reload();

    setSyncModal(false);
  }

  // try {
  //   let arrangesongs = rearrangeArrayWithLock(allSongs);
  //   for (let i = 0; i < arrangesongs?.length; i++) {
  //     //console.log(arrangesongs[i]);

  //     await axios(
  //       `https://api.spotify.com/v1/playlists/${playlistId}/tracks?position=${
  //         arrangesongs[i].lockPosition
  //           ? Number(arrangesongs[i].lockPositionNumber)
  //           : i
  //       }&uris=${arrangesongs[i]?.track.uri}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (arrangesongs.length - 1 === i) {
  //       await timeout(1000);
  //       window.location.reload();
  //     }
  //   }
  //   return [];
  // } catch (err) { 
  //   syncDataFromSpotify({ token, setPlaylist: playlistId, scheduledata,alldata:allSongs });
  // }
}
const dateFormationDDMMYY = (date) => {
  const pad = (int) => {
    return String(int).length > 1 ? int : `0${int}`
  }
  let dd = new Date(date).getDate()
  let MM = new Date(date).getMonth() + 1
  let yy = new Date(date).getFullYear()
  return `${pad(dd)}/${pad(MM)}/${pad(yy)}`
}
export const getOriginalDate = (verisonsRecord, viewDetailData) => {
  if (verisonsRecord?.length) {
    let data = verisonsRecord[0]
    let uris = JSON.parse(data.uris)
    //console.log(uris)
    // let arr = []
    // let dates = uris.map((versionObj, i) => {
    //  return versionobj?.uris.map((song, index) => {
    //     if (song?.uri === viewDetailData?.track?.uri) {
    //       return song?.date
    //     }
    //   })
    // })
    for (let i = 0; i < uris.length; i++) {
      for (let j = 0; j < uris[i]?.uris?.length; j++) {
        if (uris[i]?.uris[j]?.uri === viewDetailData?.track?.uri) {
          //console.log(uris[i]?.uris[j].date)
          let orgDate = uris[i]?.uris[j].date
          return dateFormationDDMMYY(orgDate)
        }
      }
    }
    // //console.log(dates, 'asdasda')
    // return 'asda'
  }
}
export async function refreshPlaylist({
  token,
  setPlaylist: playlistId,
  playlist,
  currentPage,
  refreshSongs,
  recall,
  PlaylistRefreshRecord,
  saveRestore,
}) {
  //console.log(saveRestore, "saveRestore")
  try {
    //console.log(PlaylistRefreshRecord, 'PlaylistRefreshRecord')


    let refreshRecordedUris = []
    if (PlaylistRefreshRecord && PlaylistRefreshRecord.length && PlaylistRefreshRecord[0]?.uris) {
      let versions = []
      JSON.parse(PlaylistRefreshRecord[0].uris).map((a, i) => {
        if (a.version) {
          //console.log('testingggg if')
          versions.push(a)
          if (i === JSON.parse(PlaylistRefreshRecord[0].uris).length - 1) {
            refreshRecordedUris.push({
              playlist_id: playlistId,
              date: moment(),
              uris: versions,
            });
          }
        } else {
          //console.log('testingggg else')
          if (i === JSON.parse(PlaylistRefreshRecord[0].uris).length - 1) {
            refreshRecordedUris.push({
              playlist_id: playlistId,
              date: moment(),
              uris: [
                { version: 1, uris: JSON.parse(PlaylistRefreshRecord[0].uris) },
              ],
            });
          }
        }
      });
    }

    // //console.log(refreshRecordedUris, 'refreshRecordedUris')
    // let recordedUri = refreshRecordedUris?.map((obj) => obj?.uri) || [];
    const allSongs = await getAllTracks(token, playlistId);
    let uriWithoutlocal = allSongs.filter((song) =>
      song?.track?.uri.includes("spotify:track:")
    );
    const songUris = uriWithoutlocal.map((song) => {
      return `${song?.track?.uri}`;
    });
    const songUri = allSongs.map((obj, i) => {
      return {
        ...obj,
        position: i,
      };
    });
    //console.log(songUri, 'formDataformData')
    let refreshRecordedaddeddUri = PlaylistRefreshRecord[0]?.addeddUri
      ? JSON.parse(PlaylistRefreshRecord[0].addeddUri)
      : [];
    let recordedaddeddUri = refreshRecordedaddeddUri?.map((obj) => obj?.uri) || [];

    let addeddsongUri = songUri.filter((obj) => !recordedaddeddUri.includes(obj?.track?.uri))
      .map((song) => {
        return { uri: `${song?.track?.uri}`, date: song?.added_at, position: song?.position };
      });
    //console.log("songUri", addeddsongUri);
    let updatedVersionArr = [];
    if (refreshRecordedUris.length && refreshRecordedUris[0].uris) {
      //console.log(refreshRecordedUris[0].uris[0], songUri, 'testingggg if 2')
      updatedVersionArr = [...refreshRecordedUris[0].uris]
      let versionURIS = []
      songUri
        .map((song, i) => {
          // refreshRecordedUris[0]?.uris[refreshRecordedUris[0]?.uris?.length - 1]?.uris.map((lastVersionSong, index) => {
          for (let i = 0; i < refreshRecordedUris[0]?.uris[refreshRecordedUris[0]?.uris?.length - 1]?.uris?.length; i++) {
            let lastVersionSong = refreshRecordedUris[0]?.uris[refreshRecordedUris[0]?.uris?.length - 1]?.uris[i]
            if (lastVersionSong?.uri === song?.track?.uri) {
              // if (!versionURIS.filter(obj => obj?.uri === song?.track?.uri).length) {
              versionURIS.push({ uri: `${song?.track?.uri}`, date: song?.added_at, orginalDate: lastVersionSong?.orginalDate, position: song?.position });
              break;
            } else {
              versionURIS.push({ uri: `${song?.track?.uri}`, date: song?.added_at, orginalDate: song?.added_at, position: song?.position });
              break;
            }
          }
          // }
          // })
        })
      let obj = {
        version: refreshRecordedUris[0]?.uris[refreshRecordedUris[0]?.uris?.length - 1]?.version + 1,
        date: new Date(),
        uris: versionURIS
      }

      updatedVersionArr.push(obj)

    } else {
      //console.log('testingggg else 2')
      updatedVersionArr = [{
        version: 1,
        date: new Date(),
        uris: songUri
          .map((song, i) => {
            return { uri: `${song?.track?.uri}`, date: song?.added_at, orginalDate: song?.added_at, position: song?.position };
          })
      }]
    }
    // const consizedArr = updatedVersionArr.map((song,i)=>{
    //   return { uri: `${song?.track?.uri}`, date: song?.added_at, position: song?.position };
    // })
    if (updatedVersionArr.length > 3) {
      updatedVersionArr.shift()
    }
    if (saveRestore) {
      //console.log("kdjskad")
      const formData = {
        playlist_id: playlistId,
        date: moment(),
        uris: JSON.stringify(updatedVersionArr),
        addeddUri: JSON.stringify(refreshRecordedaddeddUri.concat(addeddsongUri)),
        // uris: updatedVersionArr,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BASE_URL}/PlaylistRefreshRecord/add`,
        formData,
        config
      );
      //console.log(formData, "deleteduri")
    } else {
      const formData = {
        playlist_id: playlistId,
        date: moment(),
        uris: PlaylistRefreshRecord[0]?.uris,
        addeddUri: JSON.stringify(refreshRecordedaddeddUri.concat(addeddsongUri)),
        // uris: updatedVersionArr,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BASE_URL}/PlaylistRefreshRecord/add`,
        formData,
        config
      );
      //console.log(formData, "deleteduri")
    }

    const songsToBeDeleted = songUris.map((song, index) => {
      return `{\"uri\":\"${song}\"}`;
    });
    //console.log({ songsToBeDeleted }, "deleteduri");

    await deleteAllTracks(token, playlistId, songsToBeDeleted);
    await addAllTracks(token, playlistId, songUris);

    await timeout(1000);
    window.location.reload();
  } catch (err) {
    //console.log(err, "errrrr");
    await timeout(1000);
    // window.location.reload();
    // refreshPlaylist({
    //   token,
    //   setPlaylist: playlistId,
    //   playlist,
    //   currentPage,
    //   refreshSongs,
    //   recall,
    //   PlaylistRefreshRecord,
    // });
  }
}

export async function getSinglePageTracks(token, playlistId, pagesize, offset) {
  let resp = await axios(
    `https://api.spotify.com/v1/playlists/${playlistId}/tracks?fields=items(added_at%2Cadded_by.id%2Ctrack)&limit=50&offset=${offset * 50
    }`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return resp?.data;
}

export async function getAllTracks(token, playlistId) {
  let offset = 0;
  let pagesize = 50;
  let continueloop = true;
  let tracks = [];
  //console.log('getAllTracks')
  while (continueloop) {
    let next = await getSinglePageTracks(token, playlistId, pagesize, offset);
    offset++;
    if (next && next?.items.length) {
      tracks = [...tracks, ...next?.items];
    } else {
      continueloop = false;
    }
  }
  return tracks;
}

function splitArray(arr) {
  if (arr.length > 100) {
    const subArrays = [];
    for (let i = 0; i < arr.length; i += 100) {
      subArrays.push(arr.slice(i, i + 100));
    }
    return subArrays;
  } else {
    return [arr];
  }
}

export async function deleteAllTracks(token, playlistId, allSongs) {
  const songSubArrays = splitArray(allSongs);
  const promises = songSubArrays.map(async (songs) => {
    return await axios.delete(
      `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: `{"tracks":[${songs}]}`,
      }
    );
  });

  const results = await Promise.all(promises);
  return results;
}

export async function addAllTracks(token, playlistId, allSongs) {
  const songSubArrays = splitArray(allSongs);

  // //console.log("here:", a[0][0], a[1][0], a[2][0]);
  const reversedArray = [...songSubArrays].reverse();
  for (let i = 0; i < reversedArray?.length; i++) {
    await axios(
      `https://api.spotify.com/v1/playlists/${playlistId}/tracks?position=${0}&uris=${reversedArray[i]
      }`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  return [];
}

export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}
export const getDevices = (access_token: any) => {
  // axios("https://api.spotify.com/v1/me/player/devices", {
  //   method: "GET",
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${access_token}`,
  //   },
  // }).then((res) => {
  //   //console.log(res, "devices");
  // });
};

export const getRecents = (access_token: any, limit: any, before, after) => {
  axios(
    `https://api.spotify.com/v1/me/player/recently-played?limit=${limit}&before=${before}&after=${after}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    }
  ).then((res) => { });
};

export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);
  // //console.log(d, h, m, s)
  var dDisplay = d > 0 ? d + (d === 1 ? " days" : "") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hours" : "") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minutes" : "") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " seconds" : "") : "";
  return {
    dDisplay,
    hDisplay,
    mDisplay,
    sDisplay,
  };
}

export function timeDifference(startDate, nowDate) {
  // Calculate the difference in milliseconds
  let endDate =
    new Date(startDate).getMonth() === 11
      ? new Date(startDate).setMonth(0)
      : new Date(startDate).setMonth(new Date(startDate).getMonth() + 1);
  let difference = endDate - startDate - (nowDate - startDate);

  // Calculate days, hours, and minutes
  let days = Math.floor(difference / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  // Format the result
  let result = days
    ? days + " days"
    : hours
      ? hours + " hours"
      : minutes
        ? minutes + " minutes"
        : "";

  // `${days}:${hours}:${minutes}`;

  return result;
}

export function clearSiteStorage() {
  // Clear local storage
  localStorage.clear();
  const alreadyVisited = localStorage.getItem("alreadyVisited");
  if (!alreadyVisited) {
    localStorage.setItem("alreadyVisited", "true");
  }

  // Clear session storage
  sessionStorage.clear();

  // Clear cache
  if (caches) {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
  }

  // Clear cookies
  document.cookie.split(";").forEach(function (cookie) {
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  });
}

export const isSpotifyTrackURI = (input) => {
  const spotifyTrackPattern = /^spotify:track:[a-zA-Z0-9]+$/;
  return spotifyTrackPattern.test(input);
};

export const isSelected = (a, selectedArr, currentPage, i) => {

  let data = {
    uri: a?.rowNumber?.e?.track?.uri || a?.song_uri,
    positions: [
      a.lockPosition
        ? Number(a.lockPositionNumber)
        : i + (currentPage - 1) * 50,
    ],
  }
  return selectedArr.filter(obj => obj?.uri === data.uri && obj?.positions[0] === data.positions[0]).length ? true : false
}

export function deepEqual(a, b) {
  if (a === b) return true;

  if (a == null || typeof a != "object" ||
    b == null || typeof b != "object")
    return false;

  let keysA = Object.keys(a), keysB = Object.keys(b);

  if (keysA.length != keysB.length)
    return false;

  for (let key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key]))
      return false;
  }

  return true;
}

export const KeyCode = (button) => {
  if (navigator.platform === 'MacIntel') {
    if (button === "delete") {
      return 8
    } else if (button === "ctrl") {
      return 91
    } else if (button === "shift") {
      return 16
    }
  } else {
    if (button === "delete") {
      return 46
    } else if (button === 'ctrl') {
      return 17
    } else if (button === "shift") {
      return 16
    }
  }
}
