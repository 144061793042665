import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import "./header.css";
interface HeaderProps {
  onOpen: (boolean) => void;
  onOpenSignUp:(boolean) =>void;
}

const Header: React.FC<HeaderProps> = ({ onOpen, onOpenSignUp }) => {
  const [open, setOpen] = useState(false);
  const [activeNav, setActiveNav] = useState('Home')
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const activeNavFn = () => {
    let active = window.location.hash.split('#')[1]
    setActiveNav(active)
  }
  useEffect(() => {
    activeNavFn()
  }, [])

  const imgstyle={
marginLeft: "0px",
width: "235",
height: "54"

  }
  return (
    <div className="row SideStyle-row">
      <img
      alt="songplace.png"
        className="SideStyleImage"
        src="./static/songplace.png"
       width="235"
       height="54"
        style={imgstyle}
       
      />
      <div className="navbar">
        <a className={`navbar-li ${activeNav === 'Home' ? 'active' : ''}`} href="#Home" onClick={() => setActiveNav('Home')}>Home</a>
        <a className={`navbar-li ${activeNav === 'About' ? 'active' : ''}`} href="#About" onClick={() => setActiveNav('About')}>About</a>
        <a className={`navbar-li ${activeNav === 'Features' ? 'active' : ''}`} href="#Features" onClick={() => setActiveNav('Features')}>Features</a>
        <a className={`navbar-li ${activeNav === 'Pricing' ? 'active' : ''}`} href="#Pricing" onClick={() => setActiveNav('Pricing')}>Pricing</a>
        <a className={`navbar-li ${activeNav === 'Contact' ? 'active' : ''}`} href="#Contact" onClick={() => setActiveNav('Contact')}>Contact</a>
      </div>
      <div className="row4">
        <button
          // onClick={handleFormSubmit}
          onClick={() => onOpen(true)}
          className="loginButton"
        >
          Login
        </button>
        <button
          // onClick={handleFormSubmit}
          onClick={() => onOpenSignUp(true)}
          className="SideStyleButton"
        >
          Signup
        </button>
        <img alt="burgerMenu.svg" src="./static/burgerMenu.svg" onClick={showDrawer} width="30" height="30"/>
      </div>
      <Drawer title="" placement="left" onClose={onClose} open={open}>
        <span className="drawer-li" style={{cursor:"pointer"}}>Home</span>
        <span className="drawer-li" style={{cursor:"pointer"}}>About</span>
        <span className="drawer-li" style={{cursor:"pointer"}}>Features</span>
        <span className="drawer-li" style={{cursor:"pointer"}}>Pricing</span>
        <span className="drawer-li" style={{cursor:"pointer"}}>Contact</span>
        <div className="row4">
          <button
            onClick={(ev)=>{
              onOpen(ev);
              onClose();
            }}
            // onClick={() => onOpen(true)}
            className="drawer-SideStyleButton"
          >
            Login/ Signup
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
