import { Switch } from "antd";
import styled from "styled-components";

export const BulkModeCTAStyle = styled(Switch)``;

export const BulkModeWrapperStyle = styled.span`
  color: ${(props) => props.theme.colors.primary};
  display: inline-flex;
  align-items: center;
  grid-gap: 20px;
  white-space: nowrap;
  .ant-switch-handle {
    margin-top: 1.3px;
  }
  @media only screen and (max-width: 600px) {
    grid-gap: 10px;
    margin-left: -50px;
    margin-top: 5px;
  }
  .ant-switch {
    height: 26px;
  }
`;
