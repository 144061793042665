import styled from "styled-components";

const SideBarStyle1 = styled.div`
background: #242429;
position: relative;
width: 270px;
position:relative;
  .sidebarForPc {
    display: block;
    position: fixed;
    width: 270px;
  }

  .sidebarForMob {
    display: none;
  }
  .SongSideBar{
    padding:24px 10px 24px 35px;
    display: flex;
    align-items: center;


  }
  .SongSideBarImage img{
    width:62.49px;
    height:122px;
    border-radius:5px;
  }
  .SongSideBar .songDetail{
    margin-left:30px;
  }
  .SongSideBar .songDetail h2{
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.03em;
    text-align: left;
    color:white;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  .SongSideBar .songDetail p{
    font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 21px;
letter-spacing: -0.03em;
text-align: left;
color:#9CA3AF;
  }
  .SongSideBar .songDetail .rhap_progress-section{
    display:none;
  }
  .SongSideBar .songDetail .rhap_container{
    background: transparent;
    border: none;
    box-shadow: unset;
  }
  @media only screen and (max-width: 768px) {
    .SongSideBar{
    display: none;

    }
  .sidebarForPc {
    display: none;
  }
    .sidebarForMob {
      display: block;
    }
  }
  @media only screen and (max-width: 3000px) {
    .SongSideBar{
      position:fixed;
      bottom: 0;
      padding: 14px 10px 14px 35px;
      width: 270px;
      background: #242429;
      overflow: hidden;
    }
    .SongSideBarImage img{
      height:71px;
    }
    .SongSideBar .songDetail .rhap_container{
      padding: 0px;
    
    }
    .rhap_additional-controls,.rhap_volume-container{
      display: none !important; 
    }
  }
`;
export { SideBarStyle1 };
