import { Popover } from "antd";
import { useEffect, useState } from "react";

type Props = {
  children?: any;
  available?: any;
  titleHeading?: string;
  showAccount?: any;
  showPopup?: any;
};

function PlanToast({ children, available, titleHeading, showAccount, showPopup }: Props) {
  let proPlanMonthly = process.env.REACT_APP_STRIPE_PRO_MONTHLY;
  let proPlan = process.env.REACT_APP_STRIPE_PRO;
  let unlimitedPlanMonthly = process.env.REACT_APP_STRIPE_UNLIMITED_MONTHLY;
  let unlimitedPlan = process.env.REACT_APP_STRIPE_UNLIMITED;
  const [plan, setplan] = useState("");
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user) {
      if (user?.free) {
        setplan("free");
      } else {
        if (user?.planId === proPlan || user?.planId === proPlanMonthly) {
          // console.log("pro", 'plan')
          setplan("pro");
        } else if (
          user?.planId === unlimitedPlan ||
          user?.planId === unlimitedPlanMonthly
        ) {
          // console.log("unlimited", 'plan')
          setplan("unlimited");
        } else {
          // console.log('free', 'plan')
          setplan("free");
        }
      }
    }
  }, [proPlanMonthly,
    proPlan,
    unlimitedPlanMonthly,
    unlimitedPlan]);

  return available.includes(plan) ? (
    // return !false ? (
    <>
      {showPopup ? (
        <Popover content={titleHeading} placement="topLeft">
        {children}
        </Popover>
      ):
        <>{children}</>
      }
    </>
  ) : (
    <>
      <Popover
        // style={{zIndex:1000}}
        content={
          <p
            className="hanker2 upgradePlanToast"
            onClick={() => {
              window.open(`/settings?tab=upgradeplan`, "_blank");
            }}
          >
            Upgrade plan
          </p>
        }
        placement="top"
        title={titleHeading}
      >
        <div className="disabledLayer"></div>
        {children}
      </Popover>
    </>
  );
}

export default PlanToast;
