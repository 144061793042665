import styled from "styled-components";
import { SlimScrollStyle } from "../../styles/common.style";

const SearchStyle = styled.div`
padding: 81px 0px 0px;
color: white;
text-align: center;
  .ant-table {
    ${SlimScrollStyle};

    max-height: 80vh;
    overflow: auto;

    @media (max-width: 600px) {
      max-height: calc(100vh - 260px);
    }
  }

  .song-title {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .song-artist {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-pagination {
    justify-content: center;
  }
  .platformSelect{
    display:flex;
    justify-content:space-between;
    align-items:center;

    height: 43px;
    width: 167.33px !important;
    border-radius: 8px;
    background: ${(props) => props.theme.background.primary5};
    border: 0.6px solid #FFFFFF33;
    color: ${(props) => props.theme.colors.primary} !important;
    width: 100%;
    font-size: 14px;
    padding: 9.5px 16px
  }
  .searchBarBrowse{
      display:none;
      align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }
  @media screen and (max-width: 768px){
    .searchBarBrowse{
      display:flex;
    }
  }
`;
export { SearchStyle };
