import styled from "styled-components";

export const SearchBarStyle = styled.div`
  max-width: 550px;
  width: 100%;
  margin-right:16px;

  .searchBar {
    height: 36px;
    border-radius: 8px;
    background: ${(props) => props.theme.background.primary5};
    border: 0.6px solid #FFFFFF33;
    color: ${(props) => props.theme.colors.primary} !important;
    width: 100%;
    font-size: 12px;
  }
  .searchBar input::placeholder {
    opacity:0.5;
  }
  @media only screen and (max-width: 600px) {
    .searchBar {
      font-size: 17px;
      background: ${(props) => props.theme.colors.main1};
    }
  }

  .ant-input {
    background: transparent !important;
    padding: 25px 0px;
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${(props) => props.theme.colors.primary} !important;
    /* Firefox */
  }

  .ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .ant-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .ant-input-prefix {
    color: ${(props) => props.theme.colors.primary} !important;
    opacity:0.5;
    padding: 0px 8px 0px 5px;
    font-size: 14px;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color: #FFFFFF33;
  }
`;
