import { Drawer } from "antd";
import styled from "styled-components";

export const ToggledMenuStyle = styled.div`
  padding: 20px 28px;
  background-image: url("./static/hexagon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  top: -20px;

  .icon {
    svg {
      fill: #fff;
      font-size: 25px;
    }
  }
`;

export const DrawerStyle = styled(Drawer)`
  .playlist-items {
    .playlist-item {
      background: rgba(0, 0, 0, 0.1);
      margin-bottom: 5px;
      color: #fff;
      text-align: center;

      &:last-child {
        margin-top: 30px;
      }

      &.active {
        background: rgba(0, 0, 0, 0.5);
      }

      a {
        padding: 5px 15px;
        color: #fff;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .titles1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 27ch;
  }

  .ant-drawer-header {
    background: #2d3748;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .ant-drawer-content {
    background: #2d3748;
  }

  .ant-drawer-close {
    color: #fff;
    margin-right: 0;
  }
`;
