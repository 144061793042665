import { Switch } from "antd";
import styled from "styled-components";

export const BulkModeCTAStyle = styled(Switch)`
  border: 1px solid white;
  padding-top: 2px;
  margin-top: 4px;
`;

export const BulkModeWrapperStyle = styled.span`
  .ant-switch-handle {
    margin-top: 1.3px;
  }
  .ant-switch {
    padding-top: 4.3px;
    height: 26px;
  }
  color: ${(props) => props.theme.colors.primary};
  margin-top: 5px;
  margin-bottom: 20px;
  .row {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 10px;
    font-size: 16px;
    margin-top: 5px;
  }
  .spanner {
    color: ${(props) => props.theme.colors.primary};
    font-size: 17px;
  }

  .songplace_only {
    color: ${(props) => props.theme.colors.primary};
    font-size: 17px;
  }

  @media only screen and (max-width: 600px) {
    .songplace_only {
      font-size: 14px;
    }
  }
`;
