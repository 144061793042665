import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../constants/api-urls";
import {
  SPOTIFY_CODE,
  SPOTIFY_REFRESH_TOKEN,
  SPOTIFY_TOKEN
} from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import { getItemFromStorage } from "../../utils";
import { AudioPlayer1 } from "../AudioPlayer1";

type AuthenticatedRouteProps = {
  children: JSX.Element;
  redirectTo: string;
};

const AuthenticatedRoute = ({
  children,
  redirectTo,
}: AuthenticatedRouteProps) => {
  const { dispatch } = useLoginContext();

  const token = getItemFromStorage(SPOTIFY_TOKEN);
  const refreshToken = getItemFromStorage(SPOTIFY_REFRESH_TOKEN);
  const code = getItemFromStorage(SPOTIFY_CODE);
  const userInfo = localStorage.getItem('userInfo')
  const spotifyUser = localStorage.getItem('spotifyUser')
  const navigate = useNavigate();
  const location = useLocation();
  const local1 = JSON.parse(localStorage.getItem("userInfo"));
  const { state: playlist } = usePlaylistContext();
  const [isPlaying, setPlaying] = useState(false);
  const [showCurrent, setCurrent] = useState(false);
  useEffect(() => {
    setPlaying(true);
  }, [playlist.previewSong.url]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const local = JSON.parse(localStorage.getItem("userInfo"));
    if (location) {
      if (location?.pathname === "/") {
        setCurrent(true);
      } else {
        setCurrent(false);
      }
    } else {
      setCurrent(false);
    }
    if (local) {
      // console.log('/packages')
      fetch(`${BASE_URL}/packages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: `${local?.email}`,
          subId: `${local?.subId}`,
          custId: `${local?.custId}`,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          data?.data &&
            data?.data?.length > 0 &&
            data?.data
              .reverse()
              .slice(-1)
              .map((_c, index) => {
                if (
                  _c?.email === local.email &&
                  (_c?.payment_status === "active" || _c?.payment_status === "trialing")
                ) {
                  if (!userInfo &&
                    !spotifyUser) {
                    navigate(redirectTo);
                  } else {

                    dispatch({
                      type: "ADD_APP_CODE",
                      payload: code,
                    });

                    dispatch({
                      type: "ADD_APP_TOKEN",
                      payload: {
                        token: token,
                        refreshToken: refreshToken,
                      },
                    });
                    dispatch({
                      type: "SET_LOGIN",
                      payload: true,
                    });

                    if (location.pathname === "/" && token) {
                      navigate("/home");
                    }
                  }
                } else {
                  window.location.replace(
                    `/shift#access_token=${local?.token}`
                  );
                }
              });
        })
        .catch((error) => {
          window.location.replace(`/shift#access_token=${local?.token}`);
        });
    } else {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refreshToken, code, redirectTo, dispatch, navigate]);

  return (
    <>
      {children} {!showCurrent && local1 && isPlaying ? <AudioPlayer1 /> : ""}
    </>
  );
};

export default AuthenticatedRoute;
