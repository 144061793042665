import { useEffect } from "react";
import { SPOTIFY_CODE, SPOTIFY_USER_INFO } from "../../constants/storage";
import { addItemToStorage } from "../../utils";
import { LoaderPageStyle } from "../Login/ShiftPage.style";

export default function Callback() {
  
  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const userState = url.searchParams.get("state");

    addItemToStorage(SPOTIFY_CODE, code);
    addItemToStorage(SPOTIFY_USER_INFO, userState);

  }, []);

  return (
    <LoaderPageStyle>
    <div className="loadingPage">
      <h1>Taking you to Songplace</h1>
      <div className="loadingDiv">
        <div className="loadingCircle"></div>
      </div>
    </div>
  </LoaderPageStyle>
  );
}
