import ErrorIcon from "@mui/icons-material/Error";
import { Dropdown, Popover, Space, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CalenderIcon, VerticalDots } from "../../assets";
import { FuturePlaylistSongTableStyle } from "./FuturePlaylistSongTable.style";

import { PopoverStyle } from "./Playlist.style";
import { KeyCode, addItemToStorage, getRefreshedToken, isSelected } from "../../utils";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import axios from "axios";
import { TablesStyle } from "../Playlist/Playlist.style";
type Props = {
  menu: any;
  scheduleData: any;
  playlistState: any;
  bulkAction?: any;
  selectSongFunction?: any;
  currentPage?: any;
  selectedSongs?: any;
  setBulkAction?: any;
  selectMultpleSongFunction?: any;
  selectedIndex?: any;
  setSelectedIndex?: any;
  selectSingleSong?:any;
};
function FuturePlaylistSongTable({ menu, scheduleData, playlistState, bulkAction,
  selectSongFunction,
  currentPage,
  selectedSongs,
  setBulkAction,
  selectMultpleSongFunction,
  selectedIndex,
  setSelectedIndex,
  selectSingleSong
}: Props) {
  const [sortedData, setSortedData] = useState([]);
  const { state, dispatch: loginDispatch } = useLoginContext();
  const [startSort, setStartSort] = useState(false);
  const [endSort, setEndSort] = useState(false);
  useEffect(() => {
    if (scheduleData) {
      setSortedData(scheduleData);
    }
  }, [scheduleData]);


  const [totalPlaylist, setTotalPlaylist] = useState([]);
  const [totalPlaylistNumber, setTotalPlaylistNumber] = useState(0);
  const getPlaylists = (offset) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=50&offset=${offset}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + state.token },
    })
      .then((genreResponse) => {
        setTotalPlaylist([...totalPlaylist, ...genreResponse?.data.items]);
        if (totalPlaylistNumber < genreResponse?.data.total) {
          setTotalPlaylistNumber(genreResponse?.data.total);
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  };
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (

      (totalPlaylist.length < totalPlaylistNumber || totalPlaylist.length === 0)
    ) {
      getPlaylists(totalPlaylist.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPlaylistNumber, totalPlaylist]);

  // useEffect(() => {
  //   console.log(totalPlaylist, 'totalPlaylisttotalPlaylist')
  // }, [totalPlaylist])

  const sortData = (sortby) => {
    let arr = [...sortedData];
    if (sortby === "playlist") {
      arr.sort((a, b) => {
        if (a.selected_playlist_id < b.selected_playlist_id) return -1;
        if (a.selected_playlist_id > b.selected_playlist_id) return 1;
        return 0;
      });
      setSortedData(arr);
    } else if (sortby === "date") {
      if (startSort) {
        arr.sort(
          (a, b) =>
            new Date(
              a.start_time && a.start_time === "null"
                ? new Date().setFullYear(2000)
                : a.start_time
            ).getTime() -
            new Date(
              b.start_time && b.start_time === "null"
                ? new Date().setFullYear(2000)
                : b.start_time
            ).getTime()
        );
        setStartSort(false);
      } else {
        arr.sort(
          (a, b) =>
            new Date(
              b.start_time && b.start_time === "null"
                ? new Date().setFullYear(2000)
                : b.start_time
            ).getTime() -
            new Date(
              a.start_time && a.start_time === "null"
                ? new Date().setFullYear(2000)
                : a.start_time
            ).getTime()
        );
        setStartSort(true);
      }
      setSortedData(arr);
    } else if (sortby === "endDate") {
      if (endSort) {
        arr.sort(
          (a, b) =>
            new Date(
              a.end_time && a.end_time === "null"
                ? new Date().setFullYear(2000)
                : a.end_time
            ).getTime() -
            new Date(
              b.end_time && b.end_time === "null"
                ? new Date().setFullYear(2000)
                : b.end_time
            ).getTime()
        );
        setEndSort(false);
      } else {
        arr.sort(
          (a, b) =>
            new Date(
              b.end_time && b.end_time === "null"
                ? new Date().setFullYear(2000)
                : b.end_time
            ).getTime() -
            new Date(
              a.end_time && a.end_time === "null"
                ? new Date().setFullYear(2000)
                : a.end_time
            ).getTime()
        );
        setEndSort(true);
      }
      setSortedData(arr);
    } else if (sortby === "uri") {
      arr.sort((a, b) => {
        if (a.song_uri < b.song_uri) return -1;
        if (a.song_uri > b.song_uri) return 1;
        return 0;
      });
      setSortedData(arr);
    }
  };
  const [ctrlHold, setCtrlHold] = useState(false)
  const [shiftHold, setShiftHold] = useState(false)
  useEffect(() => {
    window.addEventListener('keydown', (ev) => {
      // console.log(ev.keyCode)
      // KeyCode()
      // console.log(ev.keyCode)
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(true)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(true)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })

    window.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KeyCode('ctrl')) {
        setCtrlHold(false)
      }
      if (ev.keyCode === KeyCode('shift')) {
        setShiftHold(false)
      }
      // if (ev.keyCode === 46) {
      //   showModalDeleteSelected()
      // }
    })
  }, [])

  const selectSongFn = (a, i) => {
    selectSongFunction({
      uri: a?.song_uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id)
  }

  const rangeSongSelect = (a, toSelect) => {
    let idArr = []
    let arr = []
    sortedData?.map((b, eachSong) => {
      if ((eachSong >= selectedIndex && toSelect >= eachSong) || eachSong <= selectedIndex && toSelect <= eachSong) {

        idArr.push(b._id)
        arr.push({
          uri: b?.song_uri,
          positions: [
            b.lockPosition
              ? Number(b.lockPositionNumber)
              : eachSong + (currentPage - 1) * 50,
          ]
        })
      }
    })
    selectMultpleSongFunction(arr, idArr)
  }
  const selectSingleSongFn = (a, i) => {
    selectSingleSong({
      uri: a?.rowNumber?.e?.track.uri,
      positions: [
        a.lockPosition
          ? Number(a.lockPositionNumber)
          : i + (currentPage - 1) * 50,
      ],
    }, a._id, i)
  }
  const PcTable = () => {
    return (
      <div className="tablePlayListDiv">
        <table className="tablePlayList">
          <thead>
            <tr>
              <th className="seriesNumber">#</th>
              <th>
                <span
                  className="sortSpan"
                  onClick={() => {
                    sortData("uri");
                  }}
                >
                  URI
                </span>
                /
                <span
                  className="sortSpan"
                  onClick={() => {
                    sortData("playlist");
                  }}
                >
                  Playlist
                </span>
              </th>
              <th>Artist</th>
              <th style={{padding:'0px 10px'}}>Position</th>
              <th>
                Start date
                <span
                  className="sortArrow"
                  onClick={() => {
                    sortData("date");
                  }}
                >
                  {startSort ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path>
                    </svg>
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                    </svg>
                  )}
                </span>
              </th>
              <th>
                End date
                <span
                  className="sortArrow"
                  onClick={() => {
                    sortData("endDate");
                  }}
                >
                  {endSort ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path>
                    </svg>
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                    </svg>
                  )}
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((a, i) => {
              const currentPlaylistContent = totalPlaylist?.find(
                (item) => item.id === a.selected_playlist_id
              );
              return (
                <tr className={(isSelected(a, selectedSongs, currentPage, i) ? 'selected' : null) + ' rowTable'}
                  onClick={() => {
                    if (!bulkAction) {
                      setBulkAction(true)
                    }
                    if (ctrlHold) {
                      selectSongFn(a, i)
                      // setSelectedIndex(i)
                    }
                    if (shiftHold) {
                      rangeSongSelect(a, i)
                      // setSelectedIndex(i)
                    }
                    if (!ctrlHold && !shiftHold) {
                      selectSingleSongFn(a, i)
                    }
                  }}
                >
                  <td>
                    <PopoverStyle>
                      <p style={{ marginLeft: "10px" }}>
                        {a.working ? (
                          <Popover
                            placement="right"
                            content={a.working ? <p>{a.working}</p> : ""}
                          >
                            <ErrorIcon
                              style={{ color: "orange", marginLeft: "-5px" }}
                            />
                          </Popover>
                        ) : (
                          i + 1
                        )}
                      </p>
                    </PopoverStyle>
                  </td>
                  <td style={{ width: "40%" }}>
                    <div onClick={()=>{
                              console.log(a,'asdasdasdas')
                            }}>
                      {a?.song_name ?
                        <Tooltip
                          title={`URI: ${a.song_uri}`}
                        >
                          <h3>
                            <span className="title" >Song:</span>
                            {a?.song_name || ""}
                          </h3>
                        </Tooltip>
                        :
                        <h3>
                          <span className="title">URI:</span>
                          {a.song_uri || ""}
                        </h3>}
                      <h3>
                        <span className="title">Playlist:</span>
                        {currentPlaylistContent?.name || ""}
                      </h3>
                      <p>{currentPlaylistContent?.description || ""}</p>
                    </div>
                  </td>
                  <td>
                    <h3>{a?.artist_name || "Me"}</h3>
                  </td>
                  <td>
                    <h3 style={{textAlign:'center'}}>{Number(a?.position) + 1}</h3>
                  </td>
                  <td>
                    <h3>
                      {moment(a.start_time).format("DD-MM-YYYY hh:mm A") !==
                        "Invalid date"
                        ? moment(a.start_time).format("llll")
                        : "-"}
                    </h3>
                  </td>
                  <td>
                    <h3>
                      {moment(a.end_time).format("DD-MM-YYYY hh:mm A") !==
                        "Invalid date"
                        ? moment(a.end_time).format("llll")
                        : "-"}
                    </h3>
                    {/* <h3>Sunday, January 30, 2022</h3> */}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "0px 20px",
                      }}
                    >
                      {a.notes ? (
                        <Popover
                          content={<p className="hanker2">{a.notes}</p>}
                          placement="topLeft"
                          title="Notes"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <CalenderIcon />
                          </div>
                        </Popover>
                      ) : (
                        ""
                      )}
                      <TablesStyle>
                        <div className="clay" style={{ display: "flex" }}>
                          <Dropdown
                            overlay={() => menu(a._id, a)}
                            placement={
                              i > sortedData?.length - 5
                                ? "topLeft"
                                : "bottomLeft"
                            }
                          // open
                          >
                            <span
                              onClick={(e) => e.preventDefault()}
                              style={{ cursor: "pointer" }}
                            >
                              <Space>
                                <VerticalDots />
                              </Space>
                            </span>
                          </Dropdown>
                        </div>
                      </TablesStyle>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const MobTable = () => {
    return (
      <div className="mobTablePlaylist">
        <div className="innerMobTablePlaylist">
          {sortedData.map((a, i) => {
            const currentPlaylistContent = totalPlaylist?.find(
              (item) => item.id === a.selected_playlist_id
            );
            return (
              <div className="rowMob">
                <div className="numberAndIcon">
                  {a.working ? (
                    <Popover
                      placement="right"
                      content={a.working ? <p>{a.working}</p> : ""}
                    >
                      <ErrorIcon
                        style={{ color: "orange", marginLeft: "-5px" }}
                      />
                    </Popover>
                  ) : (
                    i + 1
                  )}
                </div>

                <div className="songDetail">
                  <div>
                    <h3>
                      <span className="title">URI:</span> {a.song_uri || ""}
                    </h3>
                    <h3>
                      <span className="title">Playlist:</span>{" "}
                      {currentPlaylistContent?.name || ""}
                    </h3>
                    <p>{currentPlaylistContent?.description || ""}</p>
                  </div>
                  <div className="songTimeDetail">
                    <table className="startEndDate">
                      <tbody>
                        <tr>
                          <td>
                            <p>Start date:</p>
                          </td>
                          <td>
                            <h3 className="startEndTime">
                              {moment(a.start_time).format(
                                "DD-MM-YYYY hh:mm A"
                              ) !== "Invalid date"
                                ? moment(a.start_time).format("llll")
                                : "-"}
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>End date:</p>
                          </td>
                          <td>
                            <h3 className="startEndTime">
                              {moment(a.end_time).format(
                                "DD-MM-YYYY hh:mm A"
                              ) !== "Invalid date"
                                ? moment(a.end_time).format("llll")
                                : "-"}
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="actionTools">
                  {a.notes ? (
                    <Popover
                      content={<p className="hanker2">{a.notes}</p>}
                      placement="topLeft"
                      title="Notes"
                    >
                      <div style={{ cursor: "pointer" }}>
                        <CalenderIcon />
                      </div>
                    </Popover>
                  ) : (
                    ""
                  )}
                  <Dropdown
                    overlay={() => menu(a._id, a)}
                    trigger={["click"]}
                    // open
                    placement="bottomRight"
                  >
                    <span onClick={(e) => e.preventDefault()}>
                      <Space>
                        <VerticalDots />
                      </Space>
                    </span>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <FuturePlaylistSongTableStyle>
      {sortedData && sortedData.length ?
        <>
          <PcTable />
          <MobTable />
        </>
        :
        <p className="noRelData">No relavant data</p>
      }
    </FuturePlaylistSongTableStyle>
  );
}

export default FuturePlaylistSongTable;

