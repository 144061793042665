import { Modal } from "antd";
import styled from "styled-components";

export const AddToPlayListModalStyle = styled(Modal)`
  display:flex !important;
  align-items:center !important;
  justify-content:center !important;
  .heading {
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
margin-bottom:8px;

  }
  .headingCenter{
    text-align:center;
  }
  .btn2 {
    padding: 20px;
  }
  .btn4 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    padding: 14px 18px;
    font-weight: 600;
  }

  .description {
    color: ${(props) => props.theme.colors.primary};
    font-family: Public Sans;
font-size: 16px;
font-weight: 300;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
opacity:0.5;

  }
  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  border: 1px solid #FFFFFF4D;
    max-width: 618px;
    border-radius: 20px;
    margin-left: -60px;
  width:100%;
}
  &.refresh .ant-modal-content{
    width:400px
  }
  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
    isplay: flex !important;
    align-items: center !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }
  .footer.refreshModalFooter {
    display: flex;
    justify-content: space-between;
    align-items:center;
    align-self: unset;
  }
  .footer.refreshModalFooter .yesNoButtons{
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

  .rowForm{
    display:flex;
    justify-content:space-between;
  }

  .ant-select,.ant-input,.ant-picker, .playlistInput{
    background-color:transparent;
    color:white;
    width:100% !important;
    // height: 40px !important;
    display:flex;
    align-items:center;

    position:relative;
  }
  .playlistInputText{
    padding:5px 10px;

    min-height: 30px;
    width: calc(100%);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .playlistInputText .playlistInputTextSearch{
    outline: none;
    background: transparent;
    background-color: transparent;
    border: none;
    padding:2.5px;
  }
 
  .playlistInputText span{
    background: #ffffff2e;
    border-radius: 4px;
    padding: 2px 10px;
    margin-right: 10px;
    white-space:nowrap;
  }
  .ant-input,.ant-picker, .playlistInput{
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
  }
  .playlistInputText .ant-input{
    border:none !important; 
    padding:2.5px;
  }
  .ant-picker input{
    color:${(props) => props.theme.colors.primary} !important;
  }
  .ant-select-selector{
    background-color:transparent !important;
    color:${(props) => props.theme.colors.primary};
    border: 0.6px solid #FFFFFF33 !important;
    border-radius:8px !important;
    height: 40px !important;
  }
  .addNoteText{
    height: 133px !important;
  }
  .ant-select-selection-search{
    display:flex;
    align-items:center;
  }
  .addToPlaylistHead{
    border-bottom: 1px solid #ffffff4d;
    padding-bottom: 24px;
  }
  .formAddToPlaylist{
    border-bottom: 1px solid #ffffff4d;
    padding: 24px 0px 24px;
  }
  .playlistPosition{
    display:flex;
    justify-content:space-between;
    width:100%;
    margin-bottom:20px;
  }
  .release{
    display:flex;
    // justify-content:space-between;
    // width:50%
    margin-bottom:24px;
    margin-top: 15px;
    position:relative;
  }
  .release span{
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
  }
   label{
    width: calc(50% - 10px) !important;
  }

  .fullWidth{
    width:100% !important;
  }
   label p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:8px;
    
  }
  .addNoteTextLabel p{
    margin-bottom:8px;
  }
  .EditNoteText{
    background: #303036;
    padding:24px;

  }
  .editNote{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom:20px !important;    
  }
  .addNoteTextLabel{
    margin-bottom:24px;
    position:relative;
  }
  .release input{
    display:none;
  }
  .release label{
    display:flex;
    align-items:center;
    margin-right: 24px;
    cursor:pointer;
  }
  .release label .radioCheck{
    width: 16px;
    height: 16px;
    border: 1px solid #007AFF;
    border-radius: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right: 10px;
  }
  .release label .radioCheck div{
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background:#007AFF;
  }

  .addSong{
    width: 150px;
height: 48px;
padding: 12px, 32px, 12px, 32px;
border-radius: 8px;
background: #007AFF;
    border:none;
    cursor:pointer
    &:disabled{
      opacity:0.5;
    }
  }
  .loadingDiv{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .limitText{
    color: red;
    font-size: 10px;
    margin: 0px;
  }
  .loadingCircle{
    width: 105px;
    height: 105px;
    border: 5px solid #007AFF;
    border-radius: 90px;
    border-left: 5px solid transparent;
    animation: refreshRound 1s infinite;
  }
  .refreshText{
    text-align:center;
    opacity:0.7;
  }
  .editNoteButton{
    display:flex;
    align-items:center;
    justify-content: flex-end;
  }
  .editNoteButton .deleteButton{
background:#ea43351f;
height:48px;
width: 150px;
color:#EA4335;
border: 1.5px solid #EA4335;
border-radius: 8px;
margin-right:12px;

font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

  }
  .editNoteButton .editButton{
    background:#007AFF1f;
    height:48px;
    width: 150px;
    color:#007AFF;
    border: 1.5px solid #007AFF;
    border-radius: 8px;

    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
cursor:pointer;

      }

      &.viewDetail .ant-modal-content{
        width:649px;
        max-width: 649px;

      }

      &.viewDetail .ant-modal-body{
        width: 649px
      }
      .viewDetailInner{
        width:100%;
      }
      .viewDetailInner .albumPic{
        width:102px;
        height:102px;
      }
      .viewDetailInner .heading{
        margin-bottom:24px;
      }
      .viewDetailInner .albumDiv{
        display:flex;
        align-items:flex-end;
      }
      .viewDetailInner .albumDetail{
        margin-left:24px;
      }
      .viewDetailInner .albumDetail h3{
        font-size: 27px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0.04em;
        text-align: left;
        margin-bottom:4px;
        color:white;
      }
      
      .viewDetailInner .albumDetail p{
      font-family: Poppins;
font-size: 15px;
font-weight: 400;
line-height: 23px;
letter-spacing: -0.02em;
text-align: left;
opacity:0.70;
      }
      .viewDetailInner .detailBoxes1{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:32px;
      }
      .viewDetailInner .detailBoxes1 .box{
        padding:27.5px 10px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes2{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:20px;
      }
      .viewDetailInner .detailBoxes2 .box{
        padding:8px 0px;
        background:#303036;
        border: 0.6px solid #FFFFFF33;
        border-radius:16px;
        width:187px;
        display:flex;
        flex-direction:column;
        justify-align:center;
        align-items:center;
      }
      .viewDetailInner .detailBoxes1 .box p{
        color:white;
        font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.04em;
text-align: center;
margin-bottom:8px;

      }
      .viewDetailInner .detailBoxes1 .box h4{
        color:white;
        font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
letter-spacing: 0.04em;
text-align: center;

      }
      
      .viewDetailInner .detailBoxes2 .box p{
        color:white;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.04em;
        text-align: center;
        

      }
      .viewDetailInner .detailBoxes2 .box h4{
        color:white;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        

      }
      .viewDetailInner .note{
        padding:20px;
        margin-top:20px;
        background:#303036;
        border: 1px solid #FFFFFF33;
        border-radius:20px;
      }
      .viewDetailInner .note h3{
        color:white;
      }
      .viewDetailInner .note p{
        opacity:0.8;
      }
      .playlistNameViewDetail{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        overflow:hidden;
        width:100%;
      }
@keyframes refreshRound {
  0%{
    transform: rotate(0deg);
  }
  0%{
    transform: rotate(359deg);
  }
}
  .buttonDiv{
    width:100%;
    justify-content: flex-end !important;
    display: flex;
    padding-top:24px;
  }
  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 100%;
      margin-left: 0%;
    }
    .ant-select .ant-select-selector,
    .ant-select-selection,
    .ant-select-item {
      font-size: 17px !important;
    }
    .btn2 {
      padding: 24px !important;
    }
  }

  .verisonsUi{
    .verisons{
      display: flex;
      align-items: center;
      margin:10px 0px;
      cursor:pointer;
      .checkVersion{
        border: 2px solid #ffffff59;
        aspect-ratio: 1;
        width: 25px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 10px;
        color: #ffff;
      }
      span{
        color: #ffffff5e;
        margin-left:10px;
      }
    }
  }
  .checkBoxSaveVersion{
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    .note{
      width:100%;
      margin-bottom:10px;
    }
    .checkVersion{
      border: 2px solid #ffffff59;
      aspect-ratio: 1;
      width: 25px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 10px;
      color: #ffff;
    }
    span{
      color: #ffffff5e;
      margin-left:10px;
    }
  }
  }
  .filterSelect{

    .playlistInputText .playlistInputTextSearch{
      outline: none;
      background: transparent;
      background-color: transparent;
      border: none;
    }
  }
`;


