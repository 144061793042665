import { useEffect, useState } from "react";
// import { DatePicker } from "../DatePicker";
import { Popover, message } from "antd";

import { Input } from "antd";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/api-urls";
import { SPOTIFY_TOKEN } from "../../constants/storage";
import { useLoginContext } from "../../context";
import { usePlaylistContext } from "../../context/PlaylistContext";
import {
  addItemToStorage,
  addSongToPlaylist,
  getPlaylistItemsSpotify,
  getRefreshedToken,
  isSpotifyTrackURI
} from "../../utils";
import DatePicker from "../DatePicker/DatePickerWithoutFormik";
import PlanToast from "../PlanToast/PlanToast";
import Spinner from "../Spinner/Spinner";
import { AddToPlayListModalStyle } from "./AddToPlayListModal.style";

interface AddToPlayListModalProps {
  isModalVisible?: boolean;
  hideUriInput?: boolean;
  preRelease?: boolean;
  hideRadioButtons?: boolean;
  setIsModalVisible?: (_: boolean) => void;
  songURI?: string;
  setSongURI?: (_: string) => void;
  existSongUri?: string;
  getFuturelist?: any;
  editObj?: any;
  hideArtistAndSongName?: any;
}

const AddToPlayListModal = (props: AddToPlayListModalProps) => {
  const {
    isModalVisible,
    setIsModalVisible,
    hideRadioButtons,
    hideUriInput,
    preRelease,
    existSongUri,
    editObj,
    hideArtistAndSongName
  } = props;
  const { playlistId } = useParams();
  const [setLoading, showLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [limitText, setLimitText] = useState("");
  const [enableDatePicker, setEnableDatePicker] = useState(true);

  const { state } = usePlaylistContext();
  const { state: loginState, dispatch: loginDispatch } = useLoginContext();


  const [setButtom, showButtom] = useState("");
  const [setDate, showDate] = useState(new Date());
  const [release, setRelease] = useState("Immediately");
  const [selectPlayListOpen, setSelectPlayListOpen] = useState(false);
  const [selectedPlayList, setSelectedPlayList] = useState([]);
  const [totalPlaylist, setTotalPlaylist] = useState([]);
  const [totalPlaylistNumber, setTotalPlaylistNumber] = useState(0);
  const [note, setNote] = useState("");
  const [searchPlaylistText, setSearchPlaylistText] = useState('')
  const [arrayPositions, setArrayPositions] = useState([])
  const [positionText, setPositionText] = useState(0)

  const [data, setData] = useState({
    playlistId: [],
    startTime: "",
    endTime: "",
    position: 1,
    status: "past",
    searchedSongData: "",
    artist_name: '',
    song_name: ''
  });
  const handleOk = () => {
    setIsModalVisible(false);
    setData({
      playlistId: [],
      startTime: "",
      endTime: "",
      position: 1,
      status: "past",
      searchedSongData: "",
      artist_name: '',
      song_name: ''
    });
    setNote("");
    setSelectedPlayList(playlistId ? [playlistId] : []);
    setTotalPlaylist([])
    setTotalPlaylistNumber(0)
    showDate(new Date());
    setSearchPlaylistText('')
    setSelectPlayListOpen(false);
  };
  const handleSwitch = (value) => {
    setEnableDatePicker(!enableDatePicker);
    // //console.log(value);
    if (value === true) {
      showButtom("disabled");
    } else {
      showButtom("");
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setData({
      ...data,
      searchedSongData: existSongUri,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existSongUri]);

  const handleCancel = (e: any) => {
    //console.log('cancelllllll')
    e.stopPropagation();
    setData({
      playlistId: playlistId ? [playlistId] : [],
      startTime: "",
      endTime: "",
      position: 1,
      status: "future",
      searchedSongData: "",
      artist_name: '',
      song_name: ''
    });
    setNote("");
    setSelectedPlayList(playlistId ? [playlistId] : []);
    setIsModalVisible(false);
    setTotalPlaylist([])
    setTotalPlaylistNumber(0)
    showDate(new Date());
    setSearchPlaylistText('')
    setSelectPlayListOpen(false);

    // setData({
    //   playlistId: "",
    //   startTime: "",
    //   endTime: "",
    //   position: 1,
    //   status: "future",
    //   searchedSongData: "",
    // });
  };

  const getPlaylists = (offset) => {
    axios(`https://api.spotify.com/v1/me/playlists?limit=50&offset=${offset}`, {
      method: "GET",
      headers: { Authorization: "Bearer " + loginState.token },
    })
      .then((genreResponse) => {
        setTotalPlaylist([...totalPlaylist, ...genreResponse?.data.items]);
        if (totalPlaylistNumber < genreResponse?.data.total) {
          setTotalPlaylistNumber(genreResponse?.data.total)
        }
      })
      .catch(async (error) => {
        if (
          error.response?.data?.error?.status === 401 ||
          error.response?.status === 400
        ) {
          getRefreshedToken(state.refreshToken)
            .then((response) => {
              addItemToStorage(SPOTIFY_TOKEN, response?.data?.access_token);

              loginDispatch({
                type: "ADD_TOKEN",
                payload: response?.data?.access_token,
              });
            })
            .catch(() => { });
        }
      });
  }
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (isModalVisible && (totalPlaylist.length < totalPlaylistNumber || totalPlaylist.length === 0)) {
      getPlaylists(totalPlaylist.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, totalPlaylistNumber, totalPlaylist]);

  const onHandle = (date, dateString) => {
    showDate(date);
  };
  const addToPlaylistLoop = (data) => {
    if (isSpotifyTrackURI(data.searchedSongData)) {
      if (data.playlistId.length > 1) {
        //console.log(data, 'asdasddas')

        if (data.position > 0) {
          data.playlistId.map((a, i) => {
            getPlaylistItemsSpotify(a, loginState.token).then((res) => {
              if (res.total === 0) {
                handleFormSubmit({
                  ...data,
                  position: 0,
                  playlistId: a,
                }, data.playlistId.length - 1 === i ? true : false);
              } else if (data.position > res.total) {
                handleFormSubmit({
                  ...data,
                  position: res.total + 1,
                  playlistId: a,
                }, data.playlistId.length - 1 === i ? true : false);
                // 1               1
              } else if (data.position === res.total) {
                handleFormSubmit({
                  ...data,
                  playlistId: a,
                }, data.playlistId.length - 1 === i ? true : false);
              } else if (data.position < res.total) {
                handleFormSubmit({
                  ...data,
                  playlistId: a,
                }, data.playlistId.length - 1 === i ? true : false);
              }

            });
          });
        } else {
          toast.error("Position is required");
          showLoading(false);
        }
      } else {
        //console.log('asdasddas')
        if (arrayPositions.length && Number(arrayPositions[0]) > 0) {
          arrayPositions.map((a, i) => {
            getPlaylistItemsSpotify(data.playlistId[0], loginState.token).then((res) => {
              //console.log(arrayPositions, res.total)
              if (res.total === 0) {
                handleFormSubmit({
                  ...data,
                  position: i + 1,
                  playlistId: data.playlistId[0],
                }, data.playlistId.length - 1 === i ? true : false);
              } else if (Number(a) > res.total) {
                handleFormSubmit({
                  ...data,
                  position: res.total + 1,
                  playlistId: data.playlistId[0],
                }, data.playlistId.length - 1 === i ? true : false);
                // 1               1
              } else if (Number(a) === res.total) {
                handleFormSubmit({
                  ...data,
                  playlistId: data.playlistId[0],
                  position: a
                }, data.playlistId.length - 1 === i ? true : false);
              } else if (Number(a) < res.total) {
                handleFormSubmit({
                  ...data,
                  playlistId: data.playlistId[0],
                  position: a,
                }, data.playlistId.length - 1 === i ? true : false);
              }

            });
          });
        } else {
          toast.error("Position is required");
          showLoading(false);
        }


      }
    } else {
      toast.error("URI is incorrect");
    }

  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //console.log(editObj, 'editObjeditObj')
    if (editObj && editObj?.selected_playlist_id && totalPlaylist.length) {
      let playlsitName = getPlaylistItems(totalPlaylist).filter(
        (obj) => editObj?.selected_playlist_id === obj.key
      )[0];
      setSelectedPlayList([playlsitName?.value]);
      setData({
        playlistId:
          typeof editObj?.selected_playlist_id === "string"
            ? [editObj?.selected_playlist_id]
            : editObj?.selected_playlist_id,
        startTime: editObj?.start_time === 'null' ? null : editObj?.start_time,
        endTime: editObj?.end_time === 'null' ? null : editObj?.end_time,
        position: Number(editObj?.position) + 1,
        status: editObj?.status,
        searchedSongData: editObj?.song_uri,
        artist_name: editObj?.artist_name,
        song_name: editObj?.song_name
      });
      setNote(editObj?.notes);
    } else if (totalPlaylist.length && playlistId) {
      let playlsitName = getPlaylistItems(totalPlaylist).filter(
        (obj) => playlistId === obj.key
      )[0];
      setSelectedPlayList([playlsitName?.value]);

      setData({
        ...data,
        playlistId:
          typeof playlistId === "string"
            ? [playlistId]
            : playlistId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editObj, totalPlaylist]);

  const pre_Release = window.location.pathname === "/future_playlist";

  const handleFormSubmit = (values: any, lastValue: boolean) => {
    const currentPlaylist = state.playlists.find(
      (item) => item.id === values.playlistId
    );
    //console.log('dsadsadsadas')
    if (values.playlistId !== "" || values.endTime !== "") {
      if (window.location.pathname === "/future_playlist") {
        if (values.searchedSongData.includes("spotify:track:")) {
          if (setDate) {
            addSongToPlaylist(
              Array.isArray(values.playlistId)
                ? values.playlistId
                : [values.playlistId],
              values.searchedSongData,
              loginState.token,
              loginState.refreshToken,
              loginState.userId,
              values.position,
              setDate ? setDate : null,
              values.endTime,
              "future",
              note,
              '',
              values.artist_name,
              values.song_name
            )
              .then((response) => {
                message.info(
                  values.startTime
                    ? "Song scheduled successfully"
                    : "Song added to playlist"
                );
                if (lastValue) {
                  handleOk();
                  setTimeout(() => {
                    window.location.reload();
                    showLoading(false);
                  }, 2000)
                }
              })
              .catch(() => {
                message.error("Error while adding song");
                if (lastValue) {
                  showLoading(false);
                }
              });


          } else {
            toast.error("Start Time is required");
          }
        } else {
          toast.error("Song URI is not correct");
          showLoading(false);
        }
      } else {
        axios
          .get(
            `https://api.spotify.com/v1/tracks/${values?.searchedSongData?.split(":")[2]
            }`,
            {
              headers: {
                Authorization: `Bearer ${loginState.token}`,
              },
            }
          )
          .then(async (res) => {
            await addSongToPlaylist(
              Array.isArray(values.playlistId)
                ? values.playlistId
                : [values.playlistId],
              values.searchedSongData,
              loginState.token,
              loginState.refreshToken,
              loginState.userId,
              values.position,
              values.startTime,
              values.endTime,
              release === "Later" ? "future" : "past",
              note,
            )
              .then((response) => {
                //console.log(response, 'sdasdasdsa')
                message.info(
                  values.startTime
                    ? "Song scheduled successfully"
                    : "Song added to playlist"
                );
                if (lastValue) {
                  //console.log('sdasdasdsa')
                  handleOk();
                  setTimeout(() => {
                    showLoading(false);
                    //console.log('sdasdasdsa')
                    window.location.reload();
                  }, 3000)
                }
              })
              .catch((e) => {
                message.error("Error while adding song");
                showLoading(false);
              });
          })
          .catch((e) => {
            toast.error("Song URI is not correct");
            showLoading(false);
          });
      }
    }
    //console.log('dsadsad')
  };



  /**
   * This function is filtering the playlists for dropdown based on owner
   * because we want the dropdown to show only those playlists scheduled by
   * songplace
   *
   * @returns {playlist[]} filtered playlists
   */
  const getPlaylistItems = (playlists) => {
    const playlist = [];
    playlists.map((data) => {
      const spotifyUser = JSON.parse(localStorage.getItem("spotifyUser"));
      // if (data?.owner?.display_name === spotifyUser?.username) {
      if (data?.owner?.display_name === spotifyUser?.username) {
        playlist.push({
          key: data.id,
          value: data.name,
        });
      }
      // }
    });

    return playlist;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (preRelease) {
      setRelease("Later");
      handleSwitch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preRelease]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setData({
      ...data,
      searchedSongData: props.songURI,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.songURI]);

  const [showAccount, setAccount] = useState([]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetch(`${BASE_URL}/get/packages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: `${user?.email ? user?.email : user?.email}`,
        userId: user?.userId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // //console.log({ data });
        if (data) {
          setAccount(data?.data);
        }
      })
      .catch((error) => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <AddToPlayListModalStyle
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
    >
      <div
        onClick={() => {
          setSelectPlayListOpen(false);
        }}
      >
        <div className="addToPlaylistHead">
          <h1 className="heading">
            {pre_Release ? "Schedule your songs" : "Add to playlist"}
          </h1>
          <p className="description">
            To schedule a song in your playlist, please select the playlist
            below.
          </p>
        </div>
        <div className="formAddToPlaylist">
          <div className="formPlaylist">
            <div className="playlistPosition">
              <label className="label">
                <p>Playlist *</p>
                <p className="limitText">{limitText}</p>
                <div
                  className="playlistInput"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectPlayListOpen(true);
                  }}
                // onBlur={() => {
                //   setSelectPlayListOpen(false);
                // }}
                >
                  <div className="playlistInputText">
                    {selectedPlayList.map((a, i) => (
                      <span>{a}</span>
                    ))}
                    <input className="playlistInputTextSearch" value={searchPlaylistText} placeholder="Search Playlist" onChange={(ev) => {
                      setSearchPlaylistText(ev.target.value)
                    }} />
                  </div>
                  <div
                    className={`optionsList ${selectPlayListOpen ? "active" : ""
                      }`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {getPlaylistItems(totalPlaylist).filter(option => option?.value?.toLowerCase().includes(searchPlaylistText?.toLowerCase()))?.length ?
                      getPlaylistItems(totalPlaylist).filter(option => option?.value?.toLowerCase().includes(searchPlaylistText?.toLowerCase())).map(
                        (option: {
                          key: string;
                          value: string;
                          disabled?: boolean;
                        }) => {

                          return (
                            <div
                              className="optionName"
                              id="add-songs-mode-options"
                              onClick={(value) => {
                                //console.log(value, 'asdasdas')
                                value.stopPropagation();
                                if (data.playlistId.includes(option.key)) {
                                  setLimitText("");
                                  let obj = { ...data };
                                  obj.playlistId.splice(
                                    obj.playlistId.indexOf(option.key),
                                    1
                                  );
                                  // @ts-ignore
                                  setData(obj);
                                  let playlist = [...selectedPlayList];
                                  playlist.splice(
                                    playlist.indexOf(option.value),
                                    1
                                  );
                                  setSelectedPlayList([...playlist]);
                                } else {


                                  //  let number = Number(
                                  //   showAccount[0]?.payment_status.amount_subtotal /
                                  //   100
                                  // );
                                  if (user?.free && data?.playlistId.length >= 1) {
                                    setLimitText(
                                      "Upgrade your plan to select multiple playlists"
                                    );
                                  } else {
                                    setLimitText("");
                                    let obj = { ...data };
                                    // @ts-ignore
                                    obj.playlistId = [
                                      ...obj.playlistId,
                                      option.key,
                                    ];
                                    setData(obj);
                                    let playlist = [...selectedPlayList];
                                    playlist = [...playlist, option.value];
                                    setSelectedPlayList([...playlist]);
                                  }
                                }
                              }}
                            >
                              <div className="optionText">
                                <p>{option.value}</p>
                                <div
                                  className={`optionMark ${data.playlistId.includes(option.key)
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <svg
                                    width="13"
                                    height="10"
                                    viewBox="0 0 13 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.5 5.5L5 9L12 1.5"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      ) :
                      <div className="noResultText">
                        No results found
                      </div>
                    }
                  </div>
                </div>

              </label>
              <label className="label">
                <p>Position of the song in playlist *</p>

                {selectedPlayList.length > 1 ?
                  <Input
                    type="number"
                    style={{ border: 'none', padding: '7.5px 10px' }}
                    min={1}
                    bordered={false}
                    value={data.position}
                    defaultValue={data.position}
                    placeholder="Position of the song in playlist"
                    onChange={(value) => {
                      let obj = { ...data };
                      obj.position = Number(value.target.value);
                      setData(obj);
                    }}
                  />
                  :
                  <>
                    <Popover content={<p>Use space or enter to add multiple positions</p>} placement="bottomLeft">
                      <div
                        className="playlistInput">
                        <div className="playlistInputText">
                          {arrayPositions.map((a, i) => (
                            <span>{a}</span>
                          ))}
                          <Input
                            type="number"
                            min={1}
                            bordered={false}
                            value={positionText === 0 ? 'Please enter position' : positionText}
                            placeholder="Position of the song in playlist"
                            onChange={(value) => {
                              setPositionText(Number(value.target.value))
                            }}
                            onBlur={(ev) => {
                              if (ev.currentTarget.value) {

                                let arr = [...arrayPositions, ev.currentTarget.value]
                                //console.log(arr)
                                setArrayPositions(arr)
                                setPositionText(0)
                              }
                            }}
                            onKeyDown={(ev) => {
                              if (ev.keyCode === 13 || ev.keyCode === 32) {
                                if (ev.currentTarget.value) {
                                  let arr = [...arrayPositions, ev.currentTarget.value]
                                  //console.log(arr)
                                  setArrayPositions(arr)
                                  setPositionText(0)
                                }
                              } else if (ev.keyCode === 8) {
                                if (!ev.currentTarget.value) {

                                  let arr = [...arrayPositions]
                                  arr.pop()
                                  setArrayPositions(arr)
                                  setPositionText(0)
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Popover>
                  </>



                }

              </label>
            </div>
            {hideUriInput ? null : (
              <label className="label">
                <p>Song URI *</p>
                <Input
                  // type="text"
                  placeholder="Song URI"
                  value={data.searchedSongData}
                  onChange={(value: any) => {
                    let obj = { ...data };
                    obj.searchedSongData = value.target.value;
                    setData(obj);
                  }}
                />
              </label>
            )}
            {!hideArtistAndSongName ?
              <div className="playlistPosition" style={{ marginTop: '20px' }}>
                <label className="label">
                  <p>Artist name</p>
                  <Input
                    // type="text"
                    placeholder="Artist name"
                    value={data.artist_name}
                    onChange={(value: any) => {
                      let obj = { ...data };
                      obj.artist_name = value.target.value;
                      setData(obj);
                    }}
                  />
                </label>
                <label className="label">
                  <p>Song name</p>
                  <Input
                    // type="text"
                    placeholder="Song name"
                    value={data.song_name}
                    onChange={(value: any) => {
                      let obj = { ...data };
                      obj.song_name = value.target.value;
                      setData(obj);
                    }}
                  />
                </label>
              </div>
              : null}
            {hideRadioButtons ? null : (
              <div className="release">
                <PlanToast
                  available={["pro", "unlimited"]}
                  titleHeading={"Schedule Start date"}
                >
                  <label
                    onClick={() => {
                      setRelease("Immediately");
                      handleSwitch(false);
                      let obj = { ...data };
                      obj.startTime = "";
                      obj.endTime = ""
                      setData(obj);
                    }}
                  >
                    <div className="radioCheck">
                      {release === "Immediately" ? <div></div> : null}
                    </div>
                    Immediately
                  </label>
                  <label
                    onClick={() => {
                      setRelease("Later");
                      handleSwitch(true);
                      let obj = { ...data };
                      obj.startTime = "";
                      obj.endTime = ""
                      setData(obj);
                    }}
                  >
                    <div className="radioCheck">
                      {release === "Later" ? <div></div> : null}
                    </div>
                    Later
                  </label>
                </PlanToast>
              </div>
            )}
            <div
              className="playlistPosition"
              style={{ marginTop: preRelease ? "20px" : "auto" }}
            >
              {setButtom ? (
                <label className="label">
                  <p>Start date</p>
                  <DatePicker
                    type="datetime-local"
                    value={data.startTime ? moment(data.startTime) : null}
                    format={"YYYY-MM-DD hh:mm"}
                    onChange={(value, valueDate) => {
                      onHandle(value, valueDate);
                      let obj = { ...data };
                      obj.startTime = value ? value.toISOString() : undefined;
                      setData(obj);
                    }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD HH:mm");
                      return (
                        current &&
                        current < moment(customDate, "YYYY-MM-DD HH:mm")
                      );
                    }}
                  />
                </label>
              ) : null}
              <label className="label">
                <p>End date</p>
                <DatePicker
                  type="datetime-local"
                  format={"YYYY-MM-DD hh:mm"}
                  value={data.endTime ? moment(data.endTime) : null}
                  onChange={(value) => {
                    let obj = { ...data };
                    obj.endTime = value ? value.toISOString() : undefined;
                    setData(obj);
                  }}
                  defaultValue={
                    data.endTime === "null" ||
                      data.endTime === ""
                      ? ""
                      : moment(`${data.endTime}`)
                  }
                  disabledDate={(current) => {
                    if (setDate) {
                      let customDate =
                        moment(setDate).format("YYYY-MM-DD hh:mm");
                      return (
                        current &&
                        moment(customDate, "YYYY-MM-DD hh:mm") >
                        moment(current, "YYYY-MM-DD hh:mm")
                      );
                    } else {
                      let customDate = moment(new Date()).format(
                        "YYYY-MM-DD hh:mm"
                      );
                      return (
                        current &&
                        moment(customDate, "YYYY-MM-DD hh:mm") >
                        moment(current, "YYYY-MM-DD hh:mm")
                      );
                    }
                  }}
                />
              </label>
            </div>
          </div>

          <label className="label addNoteTextLabel">
            <PlanToast
              available={["pro", "unlimited"]}
              titleHeading={"Add note"}
            >
              <p style={{ marginTop: preRelease ? "20px" : "auto" }}>
                Add note
              </p>
              <Input.TextArea
                className="addNoteText"
                placeholder="Write your notes here"
                rows={4}
                value={note}
                onChange={(ev) => {
                  setNote(ev.target.value);
                }}
              />
            </PlanToast>
          </label>
        </div>
        <div className="buttonDiv">
          <button
            className="addSong"
            disabled={(setLoading || !selectedPlayList?.length) ? true : false}
            onClick={() => addToPlaylistLoop({ ...data, position: data.position })}
          >
            {setLoading ? <Spinner /> : "Add"}
          </button>
        </div>
      </div>
    </AddToPlayListModalStyle>
  );
};

export default AddToPlayListModal;

