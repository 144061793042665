import styled from "styled-components";

import { TableStyle } from "../../styles/common.style";

export const FuturePlaylistSongTableStyle = styled.main`
 ng-bottom: 300px;
  }
  #hancker {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #hancker1 {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  .ant-table-measure-row td {
    border-bottom: 1px solid transparent !important;
  }
  .ant-table-cell-row-hover {
    background: ${(props) => props.theme.background.hoverTable} !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-empty-description {
    color: ${(props) => props.theme.colors.primary4} !important;
  }
  .ant-modal-close {
    color: ${(props) => props.theme.colors.primary4} !important;
  }

  .ant-table-cell-row-hover #hancker1,
  .ant-table-cell-row-hover #hancker,
  .ant-table-cell-row-hover .moreOutline {
    color: white;
  }
  .ant-table-row {
    &:hover {
      .spotify-redirect {
        opacity: 1;
        transition: all 300ms linear;
      }
    }
  }

  .ant-table .ant-table-thead {
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    top: 0 !important;
    z-index: 1000;
    padding: 20px;
  }

  .song-title {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tablePlayList tr:hover .icons1{
    visibility: visible;
  }
  .tablePlayList tr:hover .icons2{
    visibility: hidden;
  }
  
  .song-artist {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icons {
    align-items: center;
    grid-gap: 20px;

    & > span {
      cursor: pointer;
    }
  }

  .icons1 {
    visibility: hidden;
    cursor: pointer;
  }

  .ant-pagination-item-active {
    background: #007aff !important;
  }

  .ant-table-cell-row-hover .icons1 {
    visibility: visible;
  }

  .ant-table-cell-row-hover .icons2 {
    visibility: hidden;
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }
  .icons45 {
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-table-cell-row-hover .icons45 {
    color: white;
  }

  .ant-popover {
    z-index: 100 !important;
  }

  .ant-table .ant-table-tbody .ant-table-cell {
    padding: 6px 10px !important;
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }
  .ant-table-cell {
    border-bottom: 1px solid ${(props) => props.theme.background.hrBorder} !important;
  }

  ${TableStyle} {
    .ant-table-cell {
      width: 100%;
      &.ant-table-cell-row-hover {
        background: #ffffff;
      }

      &:nth-of-type(2) {
        .title {
          max-width: 40px;
        }
      }
    }
  }
  .ant-table-wrapper {
    background: ${(props) => props.theme.background.transparent1};
  }

  .ant-spin-container {
    padding: 20px;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  .ant-table-body::-webkit-scrollbar-track {
    background: transparent !important;
  }

  /* Handle */
  .ant-table-body::-webkit-scrollbar-thumb {
    background: #007aff !important;
    border-radius: 8px !important;
  }

  /* Handle on hover */
  .ant-table-body::-webkit-scrollbar-thumb:hover {
    background: #007aff !important;
  }

  .ant-table-tbody tr:nth-child(even) {
    background: ${(props) => props.theme.background.evenTable};
  }
  .ant-table-tbody tr .ant-table-row-hover {
    border-radius: 8px !important;
    background: #ffffff;
  }
  @media (max-width: 600px) {
    .ant-table .ant-table-thead {
      z-index: 0;
    }
    .ant-table-cell,
    .ant-table-cell ant-table-cell-row-hover {
      background: ${(props) => props.theme.background.transparent1} !important;
    }
  }
  .table-list-image {
        height: 32px;
    aspect-ratio: 1;
    width: 32px;
    object-fit: contain;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding-bottom: 15px !important;
  }
  .rows4 {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
  .rows4:hover {
    background: rgb(196, 196, 196, 0.3);
    border-radius: 8px;
  }
  .tablePlayListDiv{
    padding: 0px 60px;
  }
  .tablePlayList{
    width:100%;
  }
  .tablePlayList th{
  opacity:0.5;
  }
  .tablePlayList td,.tablePlayList th{
    color: ${(props) => props.theme.colors.primary};

    font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;

  }
  .tablePlayList .seriesNumber{
    width:30px;
    text-align: center;
  }
  .tablePlayList tr.selected td{
    background: #242429;
  }
  .tablePlayList tr:hover td{
    background: #242429;
  }
  .tablePlayList tr td:first-child{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .tablePlayList tr td:last-child{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .tablePlayList td{
    padding: 11px 11px;
  }
  .tablePlayList td h3{
    color: ${(props) => props.theme.colors.primary};
    font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 4px;

  }

  .mobTablePlaylist{
    display:none;
  }
  @media screen and (max-width: 900px){
    *{
      color:white;
    }
    .tablePlayListDiv{
      display:none;
    }
    .mobTablePlaylist{
      display:block;
    }
    .rowMob{
      display:flex;
      align-items:flex-start;
      padding: 10px;
    }
  .namePlaylist #hancker:first-child{
  width:auto;
  }
  .songDetail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    width: calc(100% - 24px - 54px);
    @media screen and (max-width:768px){
      flex-direction:column;
      border-bottom: 1px solid #ffffff63;
    }
  }
  .songDetail h3{
    color:white;
  }
  .startEndDate{
    margin: 10px 0px;
  }
  .playButtonPlayList{
    align-items:flex-start;
  }
  .table-list-image{
    margin-right:0px;
  }
  .namePlaylist{
    width: calc(100% - 58px);
    padding-left: 10px;
  }
  
  .songTimeDetail{
    display:flex;
    justify-content:space-between;
    align-items:center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: auto;
  
  
  &::-webkit-scrollbar {
    width: 3px;
    height: 2px !important;
  }
  
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;  
  }
   
  
  &::-webkit-scrollbar-thumb {
    background: #888 !important; 
  }
  
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  }
  .songDetail p{

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.11em;
    text-align: left;
    color: #9CA3AF;
    font-family: Poppins;
    margin-right: 16px;
  }
  .songDetail .startEndTime{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    -webkit-letter-spacing: 0.11em;
    -moz-letter-spacing: 0.11em;
    -ms-letter-spacing: 0.11em;
    letter-spacing: 0.11em;
    text-align: left;
    color: white;
    margin-right: 16px;
  }
  .actionTools{
    display:flex;
  }
  .numberAndIcon{
    width:24px;
    text-align:center;
  }
  
  }
  .bottom-dropdown-td{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .title{
    color: #ffffff80;
    margin-right: 8px;
  }
  .sortSpan{
    cursor:pointer;
  }
  .sortArrow{
    margin-left:10px;
    cursor:pointer;
  }
  .noRelData{
    color: #ffffff69;
    text-align: center;
    font-size: 17px;
    padding: 30px 0px;
  }
`;
