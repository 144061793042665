import { Modal } from "antd";
import styled from "styled-components";

export const AddToPlayListModalStyle = styled(Modal)`
display:flex !important;
align-items:center !important;
justify-content:center !important;
.heading {
  color: ${(props) => props.theme.colors.primary};
  font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0em;
text-align: left;
margin-bottom:8px;
}
  .heading {
    font-size: 26px;
    color: ${(props) => props.theme.colors.primary};
  }

  .description {
    font-size: 16px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-modal-mask {
    backdrop-filter: blur(5px) !important;
  }
  .ant-modal-content {
    backdrop-filter: blur(5px);
    color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.background.secondary1};
  border: 1px solid #FFFFFF4D;
    max-width: 618px;
    border-radius: 20px;
    margin-left: -60px;
  width:100%;
}
  .ant-picker.ant-picker-disabled {
    background-color: transparent;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .ant-select-selector {
    height: 49px !important;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btn2 {
    border: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    padding: 10px 50px;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .toggle-wrapper {
    display: flex;
    gap: 10px;

    & > label {
      font-size: 16px;
    }
  }

  .footer {
    display: flex;
    gap: 10px;
    align-self: flex-end;
    margin-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    .ant-modal-content {
      width: 90%;
      margin-left: 5%;
    }
    .ant-select-selection-item {
      font-size: 17px !important;
    }
  }
`;

export const AddToPlayListStyle = styled.span`

.editNameModal{
  position: absolute;
    right: 0px;
}
  .images1 {
    background: ${(props) => props.theme.colors.icon};
    border-radius: 100px;
    padding: 2px;
    margin-top: -4px;
    margin-left: 5px;
  }
`;
