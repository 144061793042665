import styled from "styled-components";

export const SongsTableStyle = styled.div`
&.stickyTheadDiv{
  position: inherit;
  z-index: 9999;
  width: calc(100% - 270px);
  padding: 0px 30px;
  background:black;
  .tablePlayList{
    background: black;
    height: 40px;
  }

}

.tablePlayList{
  width:100%;
}
.tablePlayList thead{
  position:sticky;
  &.sticky{
    top:88px;
    height: 40px;
    background:black;
    z-index: 156;
    
  }
}
.tablePlayList th{
opacity:0.5;
}
.tablePlayList td,.tablePlayList th{
  color: ${(props) => props.theme.colors.primary};

  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;

}
.actionsColumn{
  text-align: right !important;
}
.imageName .ant-skeleton.ant-skeleton-element .ant-skeleton-image{
  width: 58px;
  height: 58px;
}
.imageName .ant-skeleton.ant-skeleton-element .ant-skeleton-image svg{
  width: 30px;
  height: 30px;
}
.tablePlayList .seriesNumber{
  width:63px;
  text-align: center;
}
.tablePlayList tr.rowTable{
  border-bottom:3px solid black;
}
.tablePlayList tr.selected td{
  background: #242429;
}
.tablePlayList tr:hover td{
  background: #242429;
}
.tablePlayList tr td:first-child{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.tablePlayList tr td:last-child{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.tablePlayList td{
  padding: 11px 11px;
}
.tablePlayList td h3{
  color: ${(props) => props.theme.colors.primary};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  

}

.blurrScreen{
  position:absolute;
  top:0px;
  left:0px;
  background: linear-gradient(157.78deg, rgba(6, 5, 5, 0.32) -0.75%, rgba(0, 0, 0, 0) 100%),
linear-gradient(169.15deg, rgba(255, 255, 255, 0.1) 0%, rgba(238, 237, 237, 0.05) 96.79%);
width:100%;
height:100%;

backdrop-filter: blur(13px);

}


.tablePlayList tr:hover .icons1{
  visibility: visible;
}
.tablePlayList tr:hover .icons2{
  visibility: hidden;
}

.namePlaylist #hancker:first-child{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
max-width: 200px;
width:100%;


}
.namePlaylist #hancker:last-child{
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 120px;
  

}
.startEndDate td{
  padding:0px;
}
.startEndDate .startEndTime{
  display:flex;
  align-items:center;

  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: left;

}
.startEndDate  p{
  font-family: Poppins;
font-size: 12px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0.11em;
text-align: left;
color: #9CA3AF;
margin-right: 16px;

}
.playSpot{
  display:flex;
  align-items:center;
}
.playSpot img{
width: 25px;
    height: 24px;
    margin-right: 10px;
}
.playSpot p{
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.03em;
text-align: left;
color:white;

}
.mobTablePlaylist{
  display:none;
}
.tablePlayListDiv{
  padding: 0px 20px !important;
 
}
.checkbox{
  cursor:pointer;
}
.starPosition{
  font-size: 17px;
  margin-right: 10px;
}
@media screen and (max-width: 900px){
  .tablePlayListDiv{
    display:none;
  }
  .mobTablePlaylist{
    display:block;
  }
  .rowMob{
    display:flex;
    align-items:center;
    padding: 10px;
  }
.namePlaylist #hancker:first-child{
width:auto;
}
.songDetail{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  width: 90%;
}
.startEndDate{
  margin: 10px 0px;
}
.playButtonPlayList{
  align-items:flex-start;
}
.table-list-image{
  margin-right:0px;
}
.namePlaylist{
  width: calc(100% - 58px);
  padding-left: 10px;
}

.songTimeDetail{
  display:flex;
  justify-content:space-between;
  align-items:center;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;


&::-webkit-scrollbar {
  width: 3px;
  height: 2px !important;
}


&::-webkit-scrollbar-track {
  background: #f1f1f1;  
}
 

&::-webkit-scrollbar-thumb {
  background: #888 !important; 
}


&::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}
}


`;
