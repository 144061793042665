import styled from "styled-components";

const LeftLoginSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow-y: auto;
  margin-bottom: 5%;
  // background-image: ${(props) => props.theme.background.primary1};
  // background-image: url("circle.png");
  // background-size: 50%;
  // background-repeat: no-repeat;
  // background-position: center;
  // height: 100vh;

  .images-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .first-image {
    width: 490px;
  }

  .screen-image {
    width: 408px;
    height: 251px;
    position: absolute;
    top: 23px;
  }

  .heading {
    font-size: 55px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 600;
    // color: ${(props) => props.theme.colors.primary};
    color: white;
    margin-top: 15%;
  }

  .sub-heading {
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    margin-top: 5%;
    // color: ${(props) => props.theme.colors.primary};
    color: white;
  }

  .btn1 {
    color: black;
    padding: 10px 15px;
    border-radius: 5px;
    border: 0px;
    font-weight: 700;
    display: flex;
    align-items: center;
    border: 2px solid white;
    margin-left: -15px;
    cursor: pointer;
  }
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0px 7% 0px 7%;
    margin-top: 2%;
    position: fixed;
    width: 100%;
  }
  .btn2 {
    color: white;
    padding: 10px 45px;
    border-radius: 5px;
    border: 2px solid white;
    background: rgb(0, 0, 0, 0.2);
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .paragraph {
    margin-top: 3%;
    text-align: left;
    font-size: 16px;
    width: 1;
  }

  .description {
    font-size: 14px;
    padding: 1rem 2rem;
  }

  .text-below-wrapper {
    margin-top: 40px;
  }

  * {
    margin: 0;
    overflow-x: initial;
    overflow-y: initial;
  }
  .div-background-image {
    width: 100%;
  }

  .margin-left-right {
    margin-left: 0px;
    margin-right: 0px;
  }

  .padding {
    padding: 0px;
  }

  .image-one {
    margin-left: -31%;
    margin-top: 10%;
    width: 4%;
    z-index: 9999999;
  }

  .div-image-one {
    position: relative;
    animation: mobile 7s infinite ease-in-out;
  }

  @keyframes mobile {
    0%,
    100% {
      transform: translateY(20px);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  .image-two {
    margin-left: 16%;
    margin-top: 5%;
    width: 16%;
  }

  .div-image-two {
    position: relative;
    animation: tabels 7s infinite ease-in-out;
  }

  @keyframes tabels {
    0%,
    100% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
  }

  .SideStyleImage {
    margin-left: 0px;
    width: auto;
  }

  .SideStyle-row {
    padding: 0px 7% 0px 7%;
    align-items: unset;
  }

  .SideStyleButton {
    background: #007aff;
    border-radius: 5px;
    border: 0px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }

  .SideStyleOr {
    font-size: 18px;
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .SideStyleDivFooterOne {
    margin-top: 10%;
    width: 100%;
    position: fixed;
    bottom: 20px;
    left: 0%;
    margin-left: 0%;
  }

  .SideStyleDiv {
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .SideStyleDivFooterTwo {
    display: flex;
    justify-content: center;
    width: auto;
    flex-wrap: nowrap;
  }

  .SideStyleBackgroundColor {
    background-image: linear-gradient(
      to right,
      transparent 75%,
      transparent 25%
    );
  }

  .firstImage {
    background-size: auto;
    overflow: initial !important;
  }

  .SideStyleDivFooterMore1 {
    display: block;
  }

  .SideStyleDivFooterMore2 {
    display: none;
  }
  background-image: ${(props) => props.theme.background.primary1};

  .first-col {
    width: 47%;
    height: 90vh;
    background: transparent;
    overflow: initial;
    padding-bottom: 50px;
    padding-top: 5%;
  }

  .sc-bBABsx {
    overflow-y: scroll !important;
  }

  .new-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 5%;
  }

  @media only screen and (max-width: 600px) {
    background-image: ${(props) => props.theme.background.primary2};
    margin-bottom: 0%;
    .SideStyleImage {
      margin-left: 0px;
      width: 40%;
      height: auto;
    }

    .SideStyleOr,
    .btn2 {
      display: none;
    }

    #button-body {
      width: 50px !important;
      height: 50px !important;
    }

    #button button i {
      height: 20px !important;
      width: 20px !important;
    }

    .first-col {
      width: 100% !important;
      height: 80vh;
    }

    .SideStyle-row {
      padding: 0px 4% 0px 4%;
      align-items: center;
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
    }

    .sc-bBABsx {
      overflow-y: scroll !important;
    }

    .SideStyleButton {
      background: #3d99fd;
      border-radius: 5px;
      border: 0px;
      padding: 10px 15px;
      font-size: 14px;
      cursor: pointer;
    }

    .SideStyleDiv {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }

    .making {
      margin-left: 6% !important;
      margin-top: 15% !important;
    }

    .heading {
      font-size: 30px;
    }

    .sub-heading {
      font-size: 14px;
    }

    .paragraph {
      font-size: 12px;
      width: 80%;
    }

    .new-button {
      margin-top: 10% !important;
      display: flex !important;
      flex-direction: column !important;
      align-content: center !important;
      justify-content: center !important;
      align-items: flex-start !important;
    }

    .btn1 {
      margin-left: 0px;
      margin-top: 10px;
    }

    .btn1 {
      padding: 10px 25px;
    }
    .first-col {
      padding-bottom: 0px;
    }
    .btn2 {
      padding: 7px 56px;
    }

    .SideStyleOr {
      font-size: 18px;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    .SideStyleDivFooterOne {
      margin-top: 0%;
      width: 98%;
      position: inherit;
      margin-left: 0%;
    }

    .SideStyleDivFooterTwo {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
    }

    .SideStyleBackgroundColor {
      background-image: ${(props) => props.theme.background.primary2};
    }

    .firstImage {
      background-image: url("./static/firstImage1.png") !important;
      height: 60% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      width: 100% !important;
      background-size: cover;
      padding-bottom: 30px;
      overflow: initial !important;
    }

    .secondImage {
      // background-image: url("secondImage1.png") !important;
      height: 90% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 380px 250px !important;
    }

    .SideStyleDivFooterMore1 {
      display: none;
    }

    .SideStyleDivFooterMore2 {
      display: block;
    }
  }
  @media only screen and (max-width: 500px) {
    .SideStyle-row {
      margin-top: 0px;
      padding: 10px 10px;
    }
    .working {
      display: none !important;
    }
    .firstImage {
      margin-top: 30px;
      padding-bottom: 70px;
      height: 80% !important;
    }
    .secondImage {
      height: 100% !important;
      padding: 10px 0px;
    }
  }
  @media only screen and (max-width: 400px) {
    #container {
      margin-top: 10%;
    }
    .making {
      height: 50%;
    }
    .working {
      display: none !important;
    }
    .SideStyleButton {
      font-size: 11px;
    }
    .firstImage {
      margin-top: 30px;
      padding-bottom: 70px;
      height: 80% !important;
    }
    .secondImage {
      height: 75% !important;
      padding: 10px 0px;
    }
    .SideStyle-row {
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
    }
    @keyframes tabels {
      0%,
      100% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(10px);
      }
    }
    @keyframes mobile {
      0%,
      100% {
        transform: translateY(10px);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .secondImage {
      height: 100% !important;
      padding: 90px 0px;
    }
    .SideStyleDiv {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 320px) {
    background-image: ${(props) => props.theme.background.primary2};
    .firstImage {
      height: 100% !important;
      padding-bottom: 70px;
      margin-top: 30px;
    }
    .working {
      display: none !important;
    }
    .SideStyle-row {
      -webkit-backdrop-filter: blur(9px);
      backdrop-filter: blur(9px);
    }
  }

  @media only screen and (max-width: 100px) {
    #container {
      margin-top: 10%;
    }
    @keyframes tabels {
      0%,
      100% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(10px);
      }
    }

    @keyframes mobile {
      0%,
      100% {
        transform: translateY(20px);
      }
      50% {
        transform: translateY(-20px);
      }
    }
  }
  .thememode {
    width: 100px;
    margin-top: 5px;
  }
  .row4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .kmuAuz * {
    margin-left: 45px !important;
  }

  .image-three {
    width: 35%;
    margin-top: 0%;
    margin-left: 5%;
  }
  .ant-modal-wrap {
    z-index: 10000000 !important;
  }

  .ppp {
    cursor: pointer;
  }

  .firstImage {
    background-image: url("${(props) => props.theme.background.firstImages}");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    width: 62%;
    overflow: initial !important;
  }

  .secondImage {
    background-image: url("./static/thirdImage.png");
    height: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 680px 580px;
  }

  .making {
    margin-left: 18%;
    margin-top: 12%;
  }
  @media only screen and (min-width: 1356px) {
    background-image: ${(props) => props.theme.background.primary1};
    .firstImage {
      background-image: url("${(props) => props.theme.background.firstImages}");
      background-repeat: no-repeat;
      background-position: right;
      // background-size: cover;
      height: 100vh;
      width: 52%;
      display: flex;
      align-items: center;
      overflow: initial !important;
    }
    .secondImage {
      background-image: url("./static/thirdImage.png");
      height: 100%;
      margin-top: 0%;
      background-repeat: no-repeat;
      background-position: right;
      // background-size: 680px 580px;
      width: 100%;
    }
    .first-col {
      width: 48%;
      height: 100vh;
      background: transparent;
    }
    .new-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80%;
      margin-top: 5%;
    }
    .making {
      margin-left: 12%;
      margin-top: 11%;
    }
    .row {
      display: flex;
      justify-content: space-between;
      padding: 0px 7% 0px 5%;
      margin-top: 2%;
      position: fixed;
      width: 100%;
    }
  }
  @media only screen and (min-width: 1440px) {
    background-image: ${(props) => props.theme.background.primary1};
    .firstImage {
      background-image: url("${(props) => props.theme.background.firstImages}");
      background-repeat: no-repeat;
      background-position: right;
      // background-size: cover;
      // height: 100vh;
      width: 62%;
      overflow: auto !important;
    }
    .secondImage {
      background-image: url("./static/thirdImage.png");
      height: 100%;
      background-repeat: no-repeat;
      background-position: right;
      margin-top: 0%;
      // background-size: 680px 580px;
      width: 100%;
      overflow: auto !important;
    }
    .first-col {
      width: 47%;
      height: 100%;
      background: transparent;
    }
    .new-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80%;
      margin-top: 5%;
    }
    .making {
      margin-left: 18%;
      margin-top: 12%;
    }
    .row {
      display: flex;
      justify-content: space-between;
      padding: 0px 7% 0px 7%;
      margin-top: 2%;
      position: fixed;
      width: 100%;
    }
  }
  @media only screen and (min-width: 1920px) {
    background-image: ${(props) => props.theme.background.primary1};
    .firstImage {
      background-image: url("${(props) => props.theme.background.firstImages}");
      background-repeat: no-repeat;
      background-position: right;
      // background-size: cover;
      // height: 100vh;
      width: 62%;
      overflow: auto !important;
    }
    .secondImage {
      background-image: url("./static/thirdImage.png");
      height: 100%;
      background-repeat: no-repeat;
      background-position: right;
      margin-top: 0%;
      // background-size: 680px 580px;
      width: 100%;
      overflow: auto !important;
    }
    .first-col {
      width: 47%;
      height: 100%;
      background: transparent;
    }
    .new-button {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80%;
      margin-top: 5%;
    }
    .making {
      margin-left: 18%;
      margin-top: 12%;
    }
    .row {
      display: flex;
      justify-content: space-between;
      padding: 0px 7% 0px 7%;
      margin-top: 2%;
      position: fixed;
      width: 100%;
    }
  }
`;
export default LeftLoginSideStyle;
