import { useState } from "react";
import { useLocation } from "react-router-dom";

import { usePlaylistContext } from "../../context/PlaylistContext";
import { BulkModeCTAStyle, BulkModeWrapperStyle } from "./BulkModeCTA.style1";

const BulkModeCTA = () => {
  const { state, dispatch } = usePlaylistContext();
  const [useText, setText] = useState("OFF");
  const location = useLocation();

  const handleChange = (e) => {
    if (e === false) {
      setText("OFF");
    } else {
      setText("ON");
    }
    localStorage.setItem("bulk", JSON.stringify(e, null, " "));
    dispatch({
      type: "TOGGLE_BULK_MODE",
      payload: e,
    });
  };

  const isDisabled = location.pathname.includes("search") && !state.isBulkMode;
  return (
    <BulkModeWrapperStyle>

      <div className="row SwitchPlayListSpannerRows">
        <BulkModeCTAStyle
          checked={state.isBulkMode}
          disabled={isDisabled}
          onChange={handleChange}
        />
        <p className="text bulkText">{useText}</p>
      </div>
    </BulkModeWrapperStyle>
  );
};

export default BulkModeCTA;
