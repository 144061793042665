import React, { useEffect, useState } from "react";
import { UserGuideStyle } from "./UserGuide.style";
import UserGuideVideo from "./UserGuideVideo";

type Props = {};

function UserGuid({ }: Props) {
  const [searchText, setSearchText] = useState("");
  const [autoSuggestArr, setAutoSuggestArr] = useState([
    "Search",
    "Song Scheduling",
    "Pre-release",
    "Refresh playlist",
    "Notes",
    "Delete",
    "Move",
    "Playlist create/edit",
    "Link multiple profiles",
    "Song popularity",
    "Priority/Fixed Position",
    "Fix Playlist",
    "Description",
    "Preview play",
    "Bulk mode",
    "Songplace only mode",
  ]);
  let relevantKeyword = [
    ["Browse", "Explore", "Discover", "Search"],
    ["Scheduling", "Timetable", "Calendar", "Song Scheduling"],
    ["Early access", "Preview", "Advance", "Pre-release"],
    ["Update", "Renew", "Revise", "Refresh playlist"],
    ["Annotations", "Comments", "Remarks", "Notes"],
    ["Remove", "Erase", "Eliminate", "Delete"],
    ["Rearrange", "Shift", "Transfer", "Move"],
    ["Compilation", "Customize", "Modify", "Playlist create/edit"],
    ["Connect", "Associate", "Combine", "Link multiple profiles"],
    ["Popularity trends", "Hit songs", "Trending tracks", "Song popularity"],
    [
      "Priority/Fixed Position",
      "Importance ranking",
      "Fixed order",
      "Top position",
    ],
    ["Repair", "Correct", "Amend", "Fix Playlist"],
    ["Details", "Information", "Explanation", "Description"],
    ["Sample", "Trial", "Test", "Preview play"],
    ["Batch", "Mass", "Group", "Bulk mode"],
    ["Exclusive", "Restricted", "Limited access", "Songplace only mode"],
  ];
  const onSearch = (suggestion?: any) => {
    // let key = searchText ? searchText.toLowerCase().split(' ').join('-') : suggestion ? suggestion.toLowerCase().split(' ').join('-') : ''
    let relevantArray = relevantKeyword.filter(
      (arr) =>
        arr.filter((val) => searchText.toLowerCase() === val.toLowerCase())[0]
    );
    // match Relevant From Suggested Array
    let key = relevantArray.length
      ? relevantArray[0].filter(
        (val) =>
          autoSuggestArr.filter(
            (obj) => val.toLowerCase() === obj.toLowerCase()
          )[0]
      )
      : "";

    console.log(key[0], relevantArray[0], "keykeykey");
    if (key.length) {
      if (document.getElementById(key[0].toLowerCase().split(" ").join("-"))) {
        document
          .getElementById(key[0].toLowerCase().split(" ").join("-"))
          .scrollIntoView();
      }
    } else {
      let key = autoSuggestArr.filter(
        (val) =>
          searchText.toLowerCase().split(" ").includes(val.toLowerCase()) ||
          searchText.toLowerCase().includes(val.toLowerCase())
      );
      if (key.length) {
        if (
          document.getElementById(key[0].toLowerCase().split(" ").join("-"))
        ) {
          document
            .getElementById(key[0].toLowerCase().split(" ").join("-"))
            .scrollIntoView();
        }
      }
    }
  };
  useEffect(() => {
    var videoElements = document.getElementsByClassName('video-react-video');

    // Check if there are any video elements with the specified class
    if (videoElements.length) {
      // Convert HTMLCollection to an array and then use forEach
      Array.from(videoElements).forEach(function (element) {
        if (element instanceof HTMLVideoElement) {
          element.controls = true;
        }
      });
    }
  }, []);
  return (
    <UserGuideStyle>
      <div className="userGuideInner">
        <div className="userGuideHead">
          <h2>User Guidelines</h2>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus</p> */}
          <label className="searchLabel">
            <div className="searchIconDiv">
              {searchText ? (
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 1024 1024"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M864 170h-60c-4.4 0-8 3.6-8 8v518H310v-73c0-6.7-7.8-10.5-13-6.3l-141.9 112a8 8 0 0 0 0 12.6l141.9 112c5.3 4.2 13 .4 13-6.3v-75h498c35.3 0 64-28.7 64-64V178c0-4.4-3.6-8-8-8z"></path>
                </svg>
              ) : (
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.0578 11.7133L14.3263 15.5487L13.7594 16.0359L10.482 12.2005C9.35407 13.0567 8.08445 13.4849 6.67309 13.4849C5.81682 13.4849 4.99748 13.318 4.21506 12.9844C3.43264 12.6508 2.75944 12.202 2.19547 11.638C1.6315 11.0741 1.18271 10.4009 0.849091 9.61843C0.515476 8.83598 0.348656 8.01664 0.348633 7.16039C0.34861 6.30415 0.515429 5.48481 0.849091 4.70236C1.18275 3.91992 1.63155 3.24672 2.19547 2.68278C2.7594 2.11883 3.43259 1.67004 4.21506 1.3364C4.99753 1.00276 5.81687 0.835938 6.67309 0.835938C7.52931 0.835937 8.34866 1.00276 9.13112 1.3364C9.91359 1.67004 10.5868 2.11883 11.1507 2.68278C11.7146 3.24672 12.1634 3.91992 12.4971 4.70236C12.8308 5.48481 12.9976 6.30415 12.9975 7.16039C12.9975 8.04026 12.8263 8.8729 12.4838 9.65829C12.1413 10.4437 11.6659 11.1287 11.0577 11.7133L11.0578 11.7133ZM6.67316 12.7408C7.68294 12.7408 8.61596 12.4913 9.47223 11.9923C10.3285 11.4933 11.0061 10.8156 11.5051 9.95939C12.0041 9.10315 12.2536 8.17012 12.2536 7.16033C12.2536 6.15053 12.0041 5.2175 11.5051 4.36126C11.0061 3.50501 10.3284 2.8274 9.47223 2.32842C8.616 1.82944 7.68298 1.57995 6.67316 1.57995C5.66334 1.57995 4.73032 1.82944 3.87409 2.32842C3.01787 2.8274 2.34026 3.50501 1.84125 4.36126C1.34225 5.2175 1.09276 6.15053 1.09278 7.16033C1.0928 8.17012 1.3423 9.10315 1.84125 9.95939C2.34021 10.8156 3.01783 11.4933 3.87409 11.9923C4.73036 12.4913 5.66338 12.7408 6.67316 12.7408Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
            <input
              list="suggestions"
              placeholder="Search"
              value={searchText}
              onChange={(ev) => {
                setSearchText(ev.target.value);
                // onSearch()
              }}
              onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                  onSearch();
                }
              }}
            />

            <datalist id="suggestions">
              {autoSuggestArr.map((suggestion, index) => (
                <option
                  key={index}
                  value={suggestion}
                  onClick={() => {
                    onSearch(suggestion);
                  }}
                />
              ))}
            </datalist>
          </label>
        </div>
        <div className="userGuideContent">
          <h3>Songplace – Take your Playlist Management to the next level.</h3>
          <p>
            Redefine the way you schedule and place the songs in your playlists.
            Our powerful tool allows you to schedule songs for any period of
            time. Be in control of when the song gets placed and when it gets
            removed, schedule unreleased songs, work with multiple profiles at
            the same time, add notes to placements, use both Spotify and Apple
            Music and get analytics on your songs and playlists. Automate your
            playlist placements and focus on what's most important - curation.
            <br />
            <br />
            Songplace is the best platform to professionally manage your Spotify
            and Apple Music playlists. Whether you are a record label, an artist
            that manages playlists or a professional curator, this is the
            must-have tool for you. No longer will you have to waste countless
            hours of checking songs in your playlists, setting reminders to
            place or delete them, be busy on release day, and keep track of your
            playlists. With Songplace as the number one playlist management tool
            this will be all done for you!
          </p>
        </div>
        <div className="userGuideContent">
          <h3>Platform User Guide</h3>
          <h4>Introduction</h4>
          <p>
            Welcome to Songplace – Your Ultimate Playlist Management Platform.
            <br />
            <br />
            This guide will walk you through the diverse features designed to
            enhance your music curation experience.
          </p>
        </div>
        <div className="userGuideContent" id="search">
          <h3>Table of Contents:</h3>
          {/* <h4>Login/Create Account</h4> */}
          <h4>1. Search</h4>
          <p>
            Purpose: Find your favorite songs from the huge library of Spotify right through Songplace.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Click on “Browse” option available in the left menu list.
            <br />
            <br />
            2. Type in any keyword in the search bar and hit Enter.
          </p>
        </div>
        <div className="userGuideContent" id="song-scheduling">
          <h4>2. Song Scheduling</h4>
          <p>
            Purpose: Dynamically manage your playlists by scheduling songs with specific start and end dates.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Go to the “browse” feature available in the left menu list.
            <br />
            <br />
            2. Type any song keyword and click the “+” button on the right to access the song schedule modal.
            <br />
            <br />
            3. Select the desired playlist and position of the song along with a start or end date and a personalized note to schedule the song, organizing your music collection based on your preferred timeline.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/Songplace+Final+Final.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="pre-release">
          <h4>3. Pre-release</h4>
          <p>
            Purpose: Schedule unreleased songs to be added automatically to your playlist upon launch.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Access the “pre-release” feature available in the left menu list, click “Add song” to access the song scheduling modal.
            <br />
            <br />
            2. Select the desired playlist and position of the song and then enter the dedicated URI of the unreleased song along with a start or end date, and a personalized note and watch it seamlessly integrate into your playlist upon release.
            <br />
            <br />
            3. In the meantime, you can check the list of scheduled tracks by simply accessing the pre-release feature.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/Pre+release.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="refresh-playlist">
          <h4>4. Refresh Playlist</h4>
          <p>
            Purpose: Quickly update all the scheduled tracks in your playlist to the most recent date and time.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Go to any playlist and click the “Refresh playlist” button available in the header.
            <br />
            <br />
            2. The page will get reloaded and modify each song's preset start date and time to the current date and time according to your calendar.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/refresh+playlist.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="notes">
          <h4>5. Notes</h4>
          <p>
            Purpose: Personalize your playlist by adding notes or descriptions to each song.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Open any playlist and click the three dots available at the right under the “actions” tab of each song to access the action features.
            <br />
            <br />
            2. Choose “Add note”, a popup will appear, type in a personalized note associated with the selected song and click edit to add it. You can also delete/remove a note by simply clicking the “delete note” button available in the popup.
            <br />
            <br />
            3. The page will get reloaded and your note will be added to the specific song no matter how many copies it has in the playlist.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/add+note.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="delete">
          <h4>6. Delete</h4>
          <p>
            Purpose: Easily manage your playlists by removing songs you no longer want.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Go to the action tab in any playlist to find the action features for any song.
            <br />
            <br />
            2. Choose “delete song from the playlist” and your song along with its copies will permanently be deleted from the particular playlist.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/delete+song.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="move">
          <h4>7. Move</h4>
          <p>
            Purpose: Move songs from one playlist to another to suit your preferred listening order.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. You can easily move any song from any playlist to one or more playlists. Access the action drop down for the song that you want to move.
            <br />
            <br />
            2. Click “Change playlist” option, a popup will appear.
            <br />
            <br />
            3. Choose one more playlist from the playlist dropdown and the desired position for the song. Your song will be removed from the current playlist and added to the desired ones.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/move+song.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="playlist-create/edit">
          <h4>8. Playlist Create/Edit</h4>
          <p>
            Purpose: Craft and refine your playlists according to your evolving musical preferences.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. You can create or edit unlimited playlists within the Songplace platform that is connected to your Spotify or Apple Music account. Click “Create playlist” option available on the left side of the dashboard, this will open the playlist creation modal.
            <br />
            <br />
            2. Type in the name and description for your playlist and click add. Once done, you need to refresh the page manually to align the playlist with both Songplace and Spotify.
            <br />
            <br />
            3. In order to edit the playlist, open the playlist and click “Edit playlist” option available in the playlist header.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/create+playlist.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="link-multiple-profiles">
          <h4>9. Link Multiple Profiles</h4>
          <p>
            Purpose: Sync and access your playlists across multiple profiles seamlessly.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Connect multiple profiles to ensure consistent playlist access and updates. Go to “Account overview” in the left action tab available on left menu list or click 3 dots available right after your account name to access “Account overview”.
            <br />
            <br />
            2. On the account overview screen, go to “Linked accounts”, this will show you all the accounts/profiles connected to your Songplace account.
            <br />
            <br />
            3. To add another profile, click “Add account”, this will take you to the Spotify connection screen.
            <br />
            <br />
            4. Log in to the account that you want to add or select “Agree” if you have already logged in.
            <br />
            <br />
            5. Once done, refresh the page to align both profiles with Songplace. You can easily switch between multiple profiles through account overview screen or the action dropdown available with your account name.
          </p>
        </div>
        <div className="userGuideContent" id="song-popularity">
          <h4>10. Song Popularity</h4>
          <p>
            Purpose: Stay updated on the popularity of your songs.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. To view any song’s popularity index, click the 3 dots available for each song in the action tab.
            <br />
            <br />
            2. Choose “View details” from the action dropdown to view details along with the popularity index of that specific song.
          </p>
        </div>
        <div className="userGuideContent" id="priority/fixed-position">
          <h4>11. Priority/Fixed Position</h4>
          <p>
            Purpose: Highlight your favorite songs by placing them in priority positions.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Elevate songs to priority positions within your playlists, ensuring they get the attention they deserve. When you want to lock a song to a desired position, simply change the song’s position to the desired one through “move song” feature available in the action dropdown. Ignore this step if the song is already on the desired position.
            <br />
            <br />
            2. Once done, go to the action dropdown of that song and click “Fixed position”, this will reload the page and give your song a unique lock identifier to ensure that your song has been locked to this position in both Songplace and Spotify until you unlock it or move to any other position.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/locked+position+on+1.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="fix-playlist">
          <h4>12. Fix Playlist</h4>
          <p>
            Purpose: Sync your playlist with the songs that have a priority position locked.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. This feature work as a side action for priority position. When you lock a song to a desired position, there is a chance that it might get moved to another position through Spotify. But, to revert this action, all you need to do is just clicking the “Sync Playlist” button available in the header of each playlist. This will sync your songs to their original priority positions.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/fixed+Playlist.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="description">
          <h4>13. Description</h4>
          <p>
            Purpose: Add descriptions to songs for a personalized music journey.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Input details or mood descriptions to create a richer connection with each track. You can add a description to the song while adding the song to a playlist through the scheduling modal or using the action dropdown of the particular song and selecting “add notes”.
            <br />
            <br />
            2. You can always read the notes by simply hovering to the notes icon available on the song.
          </p>
        </div>
        <div className="userGuideContent" id="preview-play">
          <h4>14. Preview Play</h4>
          <p>
            Purpose: Test songs before finalizing your playlist.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Use the preview play feature to ensure each song fits seamlessly into your curated collection.
            <br />
            <br />
            2. The preview play button appears to each song (if available publicly) when you hover the song through either the browsing section, playlists or wherever the song is listed.
          </p>
        </div>
        <div className="userGuideContent" id="bulk-mode">
          <h4>15. Bulk Mode</h4>
          <p>
            Purpose: Add as many songs as you want in the playlist with just a single click.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Unlike scheduling, when you just want to add multiple songs to a specific playlist easily and quickly you can do that by enabling the “Add to bulk” button available in each playlist. Go to any playlist and enable “Add to bulk” button then search any keyword through the search bar available in that playlist and click the “+” sign to add the song.
            <br />
            <br />
            2. You will see a popup confirming that your song has been added to the playlist. You can also pick any playlist after enabling the bulk mode but before running any query search. If you pick a different playlist after making the search the bulk mode will automatically be disabled.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/add+in+bulk.mp4"
            }
          />
        </div>
        <div className="userGuideContent" id="songplace-only-mode">
          <h4>16. Songplace Only Mode</h4>
          <p>
            Purpose: Enabling Songplace only mode is the best option when you only want to see the songs with songplace features applied.
            <br />
            <br />
            Function:
            <br />
            <br />
            1. Songplace only button modifies your playlists with the songs that have been modified with Songplace features such as end dates, notes, and priority position. For doing that, go to any playlist from which you want to see the modified songs and enable the “songplace only” button available in the header.
            <br />
            <br />
            2. This will modify the playlist then and there with the songplace modified songs.
          </p>
          <UserGuideVideo
            src={
              "https://songplace-logo.s3.amazonaws.com/songplace-logo/user-guide-videos/songplace+only.mp4"
            }
          />
        </div>
        <div className="userGuideContent">
          <h4>Conclusion</h4>
          <p>
            Explore the full potential of Songplace and make your playlist management experience truly unique. For any additional assistance, refer to our support resources or contact our customer service team.
          </p>
        </div>
      </div>
    </UserGuideStyle>
  );
}

export default UserGuid;
